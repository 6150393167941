// import libraries
import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
// import utils
import { LOCAL_URL } from '../utils/constants';
// import assets
import placeholder from '../assets/images/wshipyard.avif';
// import css
import '../assets/styles/card.scss';

/*
Boat card component
@params :
    - boat: {object} - all informations needed to display

A card displaying information about a boat on the boat page.
Two buttons to display boat details and owner.
*/

export default function BoatCard({ boat }) {
  // let image = boat.image;
  // if (boat?.image.slice(-4) === 'webp') {
  //   image = LOCAL_URL + '/images/' + boat.image;
  // }
  return (
    <Card className="card">
      <Link to={`/boats/${boat.id}`}>
        <Card.Img
          variant="top"
          src={
            boat?.image
              ? boat.image.slice(-4) === 'webp'
                ? LOCAL_URL + '/files/boats/' + boat.id + '/' + boat.image
                : boat.image
              : placeholder
          }
          className="card__image"
        />
      </Link>
      <Card.Body>
        <Card.Title className="card__title">{boat.model}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted card__subtitle">
          <span>{boat.brand}</span>
          <span>{boat.year}</span>
        </Card.Subtitle>
        <Card.Text className="card__text">
          {new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 0,
          }).format(boat.price)}
        </Card.Text>
        {/* <Card.Footer className="card__footer">
          <Button variant="primary">Owner</Button>
        </Card.Footer> */}
      </Card.Body>
    </Card>
  );
}
