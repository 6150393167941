// import libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
// import utils
import { putDataID } from '../../utils/api';
import { API_ROUTES } from '../../utils/constants';
// import css
import '../../assets/styles/settings.scss';

/*
Settings page
WIP
Display nothing for the moment.
*/

function Settings() {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = async (event) => {
    i18n.changeLanguage(event.target.value);

    let newLanguage = {};
    if (event.target.value === 'fr') {
      newLanguage.locale = 'fr-FR';
    } else {
      newLanguage.locale = 'en-US';
    }
    await putDataID(API_ROUTES.USERS.PUT.SETTINGS, newLanguage);
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center dashboard">
      <h1 className="mb-5">{t('pages.settings.title')}</h1>
      <div className="d-flex flex-row align-items-center justify-content-center gap-5">
        <h2>{t('pages.settings.language')}</h2>
        <select onChange={handleLanguageChange} defaultValue={i18n.language}>
          <option value="en">English</option>
          <option value="fr">Français</option>
        </select>
      </div>
    </div>
  );
}
// Export to call it up in app.jsx
export default Settings;
