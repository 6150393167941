// import libraries
import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

/*
Input numbers component
@params :
    - placeholder {string} => placeholder of input field.
    - setValue {function} => change value of this input. Only numbers is stored.
    - text {string} => display unit of this input.

Input group composed of an input field and its unit.
Input field is limited to numbers.
*/

export default function InputNumbers({ placeholder, setValue, value, text }) {
  return (
    <InputGroup>
      <Form.Control
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value.replace(/[^0-9]/g, ''))}
      />
      <InputGroup.Text>{text}</InputGroup.Text>
    </InputGroup>
  );
}
