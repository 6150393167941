// import libraries
import React, { useEffect, useState } from 'react';
import { ToggleButtonGroup, ToggleButton, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// import components
import InputNumbers from './inputNumbers';
import CheckboxDropdown from './CheckboxDropdown';
// import utils
import { API_ROUTES } from '../utils/constants';
import { getData } from '../utils/api';
// import css
import '../assets/styles/search.scss';

/*
Search form component
@params :
    - isActiveZone {string} => determine the button on which to add the active class.
    - setIsActiveZone {function} => change state of isActiveZone in boats page.
    - setModel {function} => change state of model in boats page.
    - setMinSize {function} => change state of minSize in boats page.
    - setMaxSize {function} => change state of maxSize in boats page.
    - setMinPrice {function} => change state of minPrice in boats page.
    - setMaxPrice {function} => change state of maxPrice in boats page.
    - selectedStatus {array of number} => store the ids of selected statuses.
    - setSelectedStatus {function} => change state of selectedStatus in boats page.
    - selectedTypology {array of number} => stock ids of selected typologies.
    - setSelectedTypology {function} => change state of selectedTypology in boats page.

Determines the filters to be applied to the card list.
*/

function Search({
  isActiveZone,
  setIsActiveZone,
  setModel,
  model,
  setMinSize,
  minSize,
  setMaxSize,
  maxSize,
  setMinPrice,
  minPrice,
  setMaxPrice,
  maxPrice,
  selectedStatus,
  setSelectedStatus,
  selectedTypology,
  setSelectedTypology,
  filterData,
  selectedUsers,
  setSelectedUsers,
  selectedAgencies,
  setSelectedAgencies,
}) {
  // data to display for statuses and typologies buttons.
  const [status, setStatus] = useState([]);
  const [typologies, setTypologies] = useState([]);
  // const [filterData, setFilterData] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response0 = await getData(API_ROUTES.BOATS.GET.STATUS);
        setStatus(response0.data);
        const response1 = await getData(API_ROUTES.BOATS.GET.TYPOLOGIES);
        setTypologies(response1.data.sort((a, b) => a.id - b.id));
      } catch (error) {
        console.log('search getdata L58 error ', error);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  // handle that adds or deletes the clicked status id
  const handleStatus = (e) => {
    if (selectedStatus.includes(parseInt(e.target.id))) {
      setSelectedStatus(
        selectedStatus.filter((listId) => listId !== parseInt(e.target.id)),
      );
    } else {
      setSelectedStatus([...selectedStatus, parseInt(e.target.id)]);
    }
  };
  // handle that adds or deletes the clicked typology id
  const handleTypologies = (e) => {
    if (selectedTypology.includes(parseInt(e.target.id))) {
      setSelectedTypology(
        selectedTypology.filter((listId) => listId !== parseInt(e.target.id)),
      );
    } else {
      setSelectedTypology([...selectedTypology, parseInt(e.target.id)]);
    }
  };
  // handle for reset button with specific parameters
  const handleReset = () => {
    setIsActiveZone('boats');
    setModel('');
    setMinSize('');
    setMaxSize('');
    setMinPrice('');
    setMaxPrice('');
    setSelectedStatus([1]);
    setSelectedTypology([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  };

  return (
    <Form className="form">
      <div className="form__up">
        {/* Zones buttons */}
        <Form.Group className="form__buttonWrapper">
          <ToggleButtonGroup
            type="radio"
            name="zones"
            defaultValue="boats"
            className="form__buttonWrapper__group"
          >
            <ToggleButton
              value="boats"
              className={
                isActiveZone === 'boats'
                  ? 'form__buttonWrapper_button active'
                  : 'form__buttonWrapper_button'
              }
              onClick={() => setIsActiveZone('boats')}
            >
              {t('components.search.myBoats')}
            </ToggleButton>
            <ToggleButton
              value="agency"
              className={
                isActiveZone === 'agency'
                  ? 'form__buttonWrapper_button active'
                  : 'form__buttonWrapper_button'
              }
              onClick={() => setIsActiveZone('agency')}
            >
              {t('components.search.agency')}
            </ToggleButton>
            {filterData && (
              <CheckboxDropdown
                data={filterData.users}
                disabled={isActiveZone !== 'agency'}
                label=""
                selected={selectedUsers}
                setSelected={setSelectedUsers}
                size="sm"
                className={
                  isActiveZone === 'agency'
                    ? 'form__buttonWrapper__dropdown active'
                    : 'form__buttonWrapper__dropdown'
                }
              />
            )}
            <ToggleButton
              value="network"
              className={
                isActiveZone === 'network'
                  ? 'form__buttonWrapper_button active'
                  : 'form__buttonWrapper_button'
              }
              onClick={() => setIsActiveZone('network')}
            >
              {t('components.search.network')}
            </ToggleButton>
            {filterData && (
              <CheckboxDropdown
                data={filterData.agencies}
                disabled={isActiveZone !== 'network'}
                label=""
                selected={selectedAgencies}
                setSelected={setSelectedAgencies}
                size="sm"
                className={
                  isActiveZone === 'network'
                    ? 'form__buttonWrapper__dropdownEnd active'
                    : 'form__buttonWrapper__dropdownEnd'
                }
              />
            )}
          </ToggleButtonGroup>
          <Form.Control
            placeholder={t('components.search.model')}
            type="text"
            className="form__buttonWrapper__input"
            value={model}
            onChange={(e) => {
              setModel(e.target.value);
            }}
          />
        </Form.Group>
        {/* Inputs numbers with unit */}
        <Form.Group className="form_inputWrapper">
          <Form.Group className="form_inputWrapper_input">
            <InputNumbers
              placeholder={t('components.search.minSize')}
              setValue={setMinSize}
              value={minSize}
              text="m"
            />
            <InputNumbers
              placeholder={t('components.search.maxSize')}
              setValue={setMaxSize}
              value={maxSize}
              text="m"
            />
          </Form.Group>
          <Form.Group className="form_inputWrapper_input">
            <InputNumbers
              placeholder={t('components.search.minPrice')}
              setValue={setMinPrice}
              value={minPrice}
              text="€"
            />
            <InputNumbers
              placeholder={t('components.search.maxPrice')}
              setValue={setMaxPrice}
              value={maxPrice}
              text="€"
            />
          </Form.Group>
        </Form.Group>
      </div>
      {/* Status buttons */}
      <div className="form__down">
        {status
          .sort((a, b) => a.id - b.id)
          .map((stat) => {
            return (
              <Button
                key={stat.id}
                id={stat.id}
                active={selectedStatus.includes(stat.id)}
                onClick={handleStatus}
                className={
                  selectedStatus.includes(stat.id)
                    ? 'badge button__badge active'
                    : 'badge button__badge'
                }
              >
                {t(`${stat.tag}`)}
              </Button>
            );
          })}
      </div>
      {/* Typologies buttons */}
      <div className="form__down">
        {typologies.map((typo) => {
          return (
            <Button
              key={typo.id}
              id={typo.id}
              active={selectedTypology.includes(typo.id)}
              onClick={handleTypologies}
              className={
                selectedTypology.includes(typo.id)
                  ? 'badge button__badge active'
                  : 'badge button__badge'
              }
            >
              {t(`${typo.tag}`)}
            </Button>
          );
        })}
        {/* Reset button */}
        <Button
          variant="danger"
          type="reset"
          onClick={handleReset}
          className="badge"
        >
          {t('components.search.reset')}
        </Button>
      </div>
    </Form>
  );
}
// Export to call it up in boats page
export default Search;
