// import libraries
import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Accordion,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
  Spinner,
  Toast,
  ToastContainer,
} from 'react-bootstrap';
import 'react-phone-number-input/style.css';
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { MdAddBox } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
// import components
import ValidForm from '../../components/validForm';
import DropdownCustom from '../../components/customDropdown';
import BoatTable from '../../components/boatTable';
// import utils
import { API_ROUTES } from '../../utils/constants';
import {
  getData,
  getDataID,
  getDataParams,
  putDataID,
  postDataID,
  deleteDataID,
} from '../../utils/api';
// import css
import '../../assets/styles/person.scss';

/*
Person page
- Display all informations of a person, and you can update informations
- Possibility of adding a boat as owner or as leads
*/

const initialState = {
  id: '',
  id_title: 1,
  lastname: '',
  firstname: '',
  company: null,
  email: null,
  phone: null,
  address1: null,
  address2: null,
  zip_code: null,
  town: null,
  country: null,
  nationality: null,
  birthdate: null,
  birthplace: null,
  observations: null,
  id_network: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PERSON':
      return { ...state, ...action.value };
    case 'UPDATE':
      return { ...state, [action.field]: action.value };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

export default function Person() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState();
  const [title, setTitle] = useState();
  const [statusPeople, setStatusPeople] = useState();
  const [statusBoats, setStatusBoats] = useState();
  const [personDefault, setPersonDefault] = useState();
  const [person, dispatch] = useReducer(reducer, initialState);
  const [isDisplay, setIsDisplay] = useState({
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
    7: true,
  });
  const [show, setShow] = useState(false);
  const [bgToast, setBGToast] = useState('danger');
  const [textToast, setTextToast] = useState('Message');
  const [boats, setBoats] = useState([]);
  const [boatsDefault, setBoatsDefault] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('1');
  const [shares, setShares] = useState();
  const [addingOwner, setAddingOwner] = useState([]);
  const [addingLead, setAddingLead] = useState([]);
  const [getBoats, setGetBoats] = useState([]);
  const [deletedBoats, setDeletedBoats] = useState([]);
  // features
  const featurePersonNetwork = 'person_network';
  const featurePersonBoatOwnerOtherUser = 'person_boat_owner_other_user';
  const [auth, setAuth] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchAuthoriz = async () => {
      try {
        const response = await getData(API_ROUTES.USERS.GET.AUTHORIZATION);
        setAuth(response.data);
      } catch (error) {
        console.log('error on fetch auth ', error);
      }
    };
    fetchAuthoriz();
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await getData(API_ROUTES.PEOPLE.GET.TITLE);
        setTitle(response.data.sort((a, b) => a.id - b.id));
        const data = await getDataID(API_ROUTES.PEOPLE.GET.PERSON, id);
        dispatch({ type: 'SET_PERSON', value: data.data });
        setPersonDefault(data.data);
        const statusData = await getData(API_ROUTES.PEOPLE.GET.STATUS);
        setStatusPeople(statusData.data);
        let params;
        if (auth.includes(featurePersonBoatOwnerOtherUser)) {
          params = new URLSearchParams({
            agency: JSON.parse(localStorage.getItem('ids')).id_agency,
          });
        } else {
          params = new URLSearchParams({
            user: JSON.parse(localStorage.getItem('ids')).user,
          });
        }
        const responseBoat = await getDataParams(
          API_ROUTES.BOATS.GET.CARDLIST,
          params,
        );
        setBoats(Array.isArray(responseBoat.data) ? responseBoat.data : []);
        setBoatsDefault(
          Array.isArray(responseBoat.data) ? responseBoat.data : [],
        );
        const boatStatus = await getData(API_ROUTES.BOATS.GET.STATUS);
        setStatusBoats(boatStatus.data);
        const getBoats = await getDataID(API_ROUTES.PEOPLE.GET.BOATS, id);
        setGetBoats(getBoats.data);
      } catch (error) {
        if (error?.response?.status === 403) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          navigate('/login'); // redirect to login page if error 403
        }
        if (error?.response?.status === 418) {
          navigate(-1);
        }
        console.log('people index L120 error ', error);
      } finally {
        setLoading(false);
      }
    };
    if (auth.length > 0) fetchData();
  }, [navigate, id, auth]);

  const handleHeader = (id) => {
    setIsDisplay({ ...isDisplay, [`${id}`]: !isDisplay[`${id}`] });
  };

  const handleChange = (field) => (e) => {
    dispatch({ type: 'UPDATE', field, value: e.target.value });
  };

  useEffect(() => {
    dispatch({ type: 'UPDATE', field: 'phone', value: phone });
  }, [phone]);

  const validateEmail = (email) => {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexEmail.test(email);
  };

  const validateForm = () => {
    return (
      person.lastname ||
      person.firstname ||
      (person.email && validateEmail(person.email)) ||
      (person.phone && isValidPhoneNumber(person.phone))
    );
  };

  const isButtonDisabled = () => {
    if (!selectedItem) {
      return true;
    }
    if (selectedStatus === '1') {
      return !(shares >= 1 && shares <= 100);
    }
    if (selectedStatus === '2') {
      return false;
    }
    return true;
  };

  const handleAddBoat = () => {
    if (!selectedItem) {
      return;
    }

    if (selectedStatus === '1' && shares >= 0 && shares <= 100) {
      const newBoat = {
        ...selectedItem,
        statusPeople: selectedStatus,
        shares: shares,
      };
      setAddingOwner((prev) => [...prev, newBoat]);
    }
    if (selectedStatus === '2') {
      const newBoat = { ...selectedItem, statusPeople: selectedStatus };
      setAddingLead((prev) => [...prev, newBoat]);
    }
    setSelectedItem(null);
    setShares();
  };

  const handleDeleteBoat = (id) => {
    if (addingLead.some((element) => element.id === id)) {
      setAddingLead((prev) => prev.filter((element) => element.id !== id));
    } else if (addingOwner.some((element) => element.id === id)) {
      setAddingOwner((prev) => prev.filter((element) => element.id !== id));
    } else {
      setDeletedBoats((prev) => [...prev, id]);
      setBoats((prev) => prev.filter((element) => element.id !== id));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isFormValid = validateForm();
    if (isFormValid) {
      try {
        if (JSON.stringify(personDefault) !== JSON.stringify(person)) {
          await putDataID(API_ROUTES.PEOPLE.PUT.PERSON, person, id);

          const data = await getDataID(API_ROUTES.PEOPLE.GET.PERSON, id);
          dispatch({ type: 'SET_PERSON', value: data.data });
          setPersonDefault(data.data);
        }

        if (addingOwner.length > 0) {
          await postDataID(API_ROUTES.PEOPLE.POST.BOAT, addingOwner, id);
        }
        if (addingLead.length > 0) {
          await postDataID(API_ROUTES.PEOPLE.POST.BOAT, addingLead, id);
        }
        if (deletedBoats.length > 0) {
          const params = new URLSearchParams();
          deletedBoats.forEach((item) => params.append('id', item));
          await deleteDataID(API_ROUTES.PEOPLE.DELETE.BOAT, id, params);
        }

        setBGToast('success');
        setTextToast('Successfully saved!');
        setShow(true);
      } catch (error) {
        console.log('error person L210: ', error);
        setBGToast('danger');
        setTextToast('ERROR: Failed saved.');
        setShow(true);
      } finally {
        const getBoats = await getDataID(API_ROUTES.PEOPLE.GET.BOATS, id);
        setGetBoats(getBoats.data);
      }
    }
  };

  const handleReset = () => {
    dispatch({ type: 'SET_PERSON', value: personDefault });
    setAddingOwner([]);
    setAddingLead([]);
    setDeletedBoats([]);
    setBoats(boatsDefault);
    setSelectedItem(null);
  };

  return (
    <div className="person">
      {auth.includes(featurePersonNetwork) && (
        <React.Fragment>
          {loading ? (
            <Spinner />
          ) : (
            <React.Fragment>
              <Accordion
                as={Form}
                id="person_form"
                onSubmit={(e) => handleSubmit(e)}
                onReset={handleReset}
                alwaysOpen
                className="person__accordion"
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header onClick={() => handleHeader(0)} as="div">
                    <Row>
                      <span className="accordion__title">
                        {t('pages.person.informations.title')}
                      </span>
                      <hr />
                      <Row className="mb-3">
                        <span className="col-2">
                          {title &&
                            person &&
                            title.length > 0 &&
                            title.find(
                              (element) =>
                                element.id === Number(person.id_title),
                            ).value}
                        </span>
                        <span className="col-3">
                          {t('pages.person.informations.lastname')}
                          {person?.lastname}
                        </span>
                        <span className="col-3">
                          {t('pages.person.informations.firstname')}
                          {person?.firstname}
                        </span>
                        <span className="col-4">
                          {t('pages.person.informations.company')}
                          {person?.company}
                        </span>
                      </Row>
                      <Row className="mb-3">
                        <span className="col-5">
                          {t('pages.person.informations.email')}
                          {person?.email}
                        </span>
                        <span className="col-4">
                          {t('pages.person.informations.phone')}
                          {formatPhoneNumberIntl(person?.phone)}
                        </span>
                      </Row>
                      <Row className="mb-3">
                        <span className="col-8">
                          {t('pages.person.informations.address1')}
                          {person?.address1}
                        </span>
                        <span className="col-4">
                          {t('pages.person.informations.address2')}
                          {person?.address2}
                        </span>
                      </Row>
                      <Row className="mb-3">
                        <span className="col-3">
                          {t('pages.person.informations.zipCode')}
                          {person?.zip_code}
                        </span>
                        <span className="col-4">
                          {t('pages.person.informations.town')}
                          {person?.town}
                        </span>
                        <span className="col-3">
                          {t('pages.person.informations.country')}
                          {person?.country}
                        </span>
                      </Row>
                      <Row className="mb-3">
                        <span className="col-3">
                          {t('pages.person.informations.birthdate')}
                          {person?.birthdate}
                        </span>
                        <span className="col-3">
                          {t('pages.person.informations.birthplace')}
                          {person?.birthplace}
                        </span>
                        <span className="col-3">
                          {t('pages.person.informations.nationality')}
                          {person?.nationality}
                        </span>
                      </Row>
                    </Row>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        md={2}
                        className="top__group"
                        controlId="formTitle"
                      >
                        <Form.Select
                          name="title"
                          value={person?.id_title}
                          onChange={handleChange('title')}
                        >
                          {title &&
                            title.map((element, index) => {
                              return (
                                <option key={index} value={element.id}>
                                  {element.value}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        className="accordion__update"
                        controlId="formLastname"
                      >
                        <Form.Label column>
                          {t('pages.person.informations.lastname')}
                        </Form.Label>
                        <Form.Control
                          placeholder={t('pages.person.informations.lastname')}
                          name="lastname"
                          defaultValue={person?.lastname}
                          onChange={handleChange('lastname')}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        className="accordion__update"
                        controlId="formFirstname"
                      >
                        <Form.Label column>
                          {t('pages.person.informations.firstname')}
                        </Form.Label>
                        <Form.Control
                          placeholder={t('pages.person.informations.firstname')}
                          name="firstname"
                          defaultValue={person?.firstname}
                          onChange={handleChange('firstname')}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        className="accordion__update"
                        controlId="formCompany"
                      >
                        <Form.Label column md={3}>
                          {t('pages.person.informations.company')}
                        </Form.Label>
                        <Form.Control
                          placeholder={t('pages.person.informations.company')}
                          name="company"
                          defaultValue={person?.company}
                          onChange={handleChange('company')}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-4">
                      <Form.Group
                        as={Col}
                        md={4}
                        className="accordion__update"
                        controlId="formEmail"
                      >
                        <Form.Label column md={2}>
                          {t('pages.person.informations.email')}
                        </Form.Label>
                        <Form.Control
                          placeholder={t('pages.person.informations.email')}
                          name="email"
                          defaultValue={person?.email}
                          onChange={handleChange('email')}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md={3}
                        className="top__group"
                        controlId="formPhone"
                      >
                        <PhoneInput
                          placeholder={t('pages.person.informations.phone')}
                          name="phone"
                          defaultCountry="FR"
                          value={person?.phone}
                          onChange={setPhone}
                          inputComponent={Form.Control}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-4">
                      <Form.Group
                        as={Col}
                        md={8}
                        className="accordion__update"
                        controlId="formAddress1"
                      >
                        <Form.Label column md={1}>
                          {t('pages.person.informations.address1')}
                        </Form.Label>
                        <Form.Control
                          placeholder={t('pages.person.informations.address1')}
                          name="address1"
                          defaultValue={person?.address1}
                          onChange={handleChange('address1')}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md={4}
                        className="accordion__update"
                        controlId="formAddress2"
                      >
                        <Form.Label column md={2}>
                          {t('pages.person.informations.address2')}
                        </Form.Label>
                        <Form.Control
                          placeholder={t('pages.person.informations.address2')}
                          name="address2"
                          defaultValue={person?.address2}
                          onChange={handleChange('address2')}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-4">
                      <Form.Group
                        as={Col}
                        md={2}
                        className="accordion__update"
                        controlId="formZipCode"
                      >
                        <Form.Label column md={5}>
                          {t('pages.person.informations.zipCode')}
                        </Form.Label>
                        <Form.Control
                          placeholder={t('pages.person.informations.zipCode')}
                          name="zip_code"
                          defaultValue={person?.zip_code}
                          onChange={handleChange('zip_code')}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md={4}
                        className="accordion__update"
                        controlId="formTown"
                      >
                        <Form.Label column md={1}>
                          {t('pages.person.informations.town')}
                        </Form.Label>
                        <Form.Control
                          placeholder={t('pages.person.informations.town')}
                          name="town"
                          defaultValue={person?.town}
                          onChange={handleChange('town')}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md={3}
                        className="accordion__update"
                        controlId="formCountry"
                      >
                        <Form.Label column>
                          {t('pages.person.informations.country')}
                        </Form.Label>
                        <Form.Control
                          placeholder={t('pages.person.informations.country')}
                          name="country"
                          defaultValue={person?.country}
                          onChange={handleChange('country')}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-4">
                      <Form.Group
                        as={Col}
                        md={2}
                        className="accordion__update"
                        controlId="formBirthdate"
                      >
                        <Form.Label column>
                          {t('pages.person.informations.birthdate')}
                        </Form.Label>
                        <Form.Control
                          placeholder={t('pages.person.informations.birthdate')}
                          name="birthdate"
                          defaultValue={person?.birthdate}
                          onChange={handleChange('birthdate')}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md={3}
                        className="accordion__update"
                        controlId="formBirthplace"
                      >
                        <Form.Label column>
                          {t('pages.person.informations.birthplace')}
                        </Form.Label>
                        <Form.Control
                          placeholder={t(
                            'pages.person.informations.birthplace',
                          )}
                          name="birthplace"
                          defaultValue={person?.birthplace}
                          onChange={handleChange('birthplace')}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md={3}
                        className="accordion__update"
                        controlId="formNationality"
                      >
                        <Form.Label column>
                          {t('pages.person.informations.nationality')}
                        </Form.Label>
                        <Form.Control
                          placeholder={t(
                            'pages.person.informations.nationality',
                          )}
                          name="nationality"
                          defaultValue={person?.nationality}
                          onChange={handleChange('nationality')}
                        />
                      </Form.Group>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header onClick={() => handleHeader(1)} as="div">
                    <span className="accordion__title">
                      {t('pages.person.observations')}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      defaultValue={person?.observations}
                      onChange={handleChange('observations')}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div className="person__boats">
                <Row className="mb-3">
                  <span className="person__boats__title">
                    {t('pages.person.boats.title')}
                  </span>
                  <hr />
                </Row>
                <span>{t('pages.person.owner')}</span>
                <BoatTable
                  boats={
                    getBoats &&
                    boats
                      .filter((boat) =>
                        getBoats
                          .filter((gb) => gb.id_status === 1)
                          .map((gb) => gb.id_boats)
                          .includes(boat.id),
                      )
                      .map((boat) => {
                        const matchingGetBoat = getBoats.find(
                          (gb) => gb.id_boats === boat.id && gb.id_status === 1,
                        );
                        return {
                          ...boat,
                          shares: matchingGetBoat
                            ? matchingGetBoat.shares
                            : null,
                        };
                      })
                  }
                  addingBoats={addingOwner}
                  onDeleteBoat={handleDeleteBoat}
                  status={1}
                />
                <hr />
                <span>{t('pages.person.leads')}</span>
                <BoatTable
                  boats={
                    getBoats &&
                    boats
                      .filter((boat) =>
                        getBoats
                          .filter((gb) => gb.id_status === 2)
                          .map((gb) => gb.id_boats)
                          .includes(boat.id),
                      )
                      .map((boat) => {
                        const matchingGetBoat = getBoats.find(
                          (gb) => gb.id_boats === boat.id && gb.id_status === 2,
                        );
                        return {
                          ...boat,
                          shares: matchingGetBoat
                            ? matchingGetBoat.shares
                            : null,
                        };
                      })
                  }
                  addingBoats={addingLead}
                  onDeleteBoat={handleDeleteBoat}
                  status={2}
                />
                <hr />
                <div className="person__boats__addingBoat">
                  <Form.Group as={Col} md={4}>
                    <DropdownCustom
                      items={boats.filter(
                        (boat) => boat.status === 1 || boat.status === 3,
                      )}
                      status={statusBoats}
                      setSelectedItem={setSelectedItem}
                      buttonDefault={t('pages.person.selectBoat')}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={2}>
                    <Form.Select
                      name="status"
                      value={selectedStatus}
                      onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                      {statusPeople &&
                        statusPeople.map((element, index) => {
                          return (
                            <option key={index} value={element.id}>
                              {element.value}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} md={2}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        min={0}
                        max={100}
                        placeholder={t('pages.person.shares')}
                        onChange={(e) => setShares(Number(e.target.value))}
                        disabled={selectedStatus !== '1'}
                      />
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Button
                      variant="success"
                      onClick={handleAddBoat}
                      disabled={isButtonDisabled()}
                    >
                      <MdAddBox />
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </React.Fragment>
          )}
          <div className="validForm">
            <ToastContainer position="bottom-end" className="validForm__toast">
              <Toast
                onClose={() => setShow(false)}
                show={show}
                delay={3000}
                bg={bgToast}
                autohide
              >
                <Toast.Body>
                  <span className="me-auto">{textToast}</span>
                </Toast.Body>
              </Toast>
            </ToastContainer>
            <ValidForm
              form="person_form"
              disabled={
                JSON.stringify(personDefault) === JSON.stringify(person) &&
                addingOwner.length === 0 &&
                addingLead.length === 0 &&
                deletedBoats.length === 0
              }
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
