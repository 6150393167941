// import libraries
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Badge,
  Button,
  Form,
  InputGroup,
  Toast,
  ToastContainer,
} from 'react-bootstrap';
import { FiExternalLink } from 'react-icons/fi';
import { FaEdit, FaRegTrashAlt } from 'react-icons/fa';
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
  IoMdAddCircleOutline,
} from 'react-icons/io';
import { useTranslation } from 'react-i18next';
// import composants
import TransactionCorePerson from './transactionCorePerson';
import EditButtonGroup from '../editButtonGroup';
import AddBuyer from './addBuyer';
import ButtonModal from '../buttonModal';
// import utils
import { getDataID } from '../../utils/api';
import { API_ROUTES } from '../../utils/constants';
// import css
import '../../assets/styles/transactionCore.scss';

/*
TransactionCore Component
Display all details of a specific transaction by id
*/

export default function TransactionCore({
  isLoading,
  id,
  date,
  price,
  status,
  prepayment,
  priceOffer,
  id_boat,
  setDataUpdate,
  commissionUpdate,
  setCommissionUpdate,
  commissionCalcul,
  sharesUpdate,
  setSharesUpdate,
  handleSubmit,
  handleReset,
  show,
  setShow,
  bgToast,
  textToast,
  handleDelete,
  addBuyer,
  setAddBuyer,
  addNewBuyer,
  newFetch,
  rightUpdate,
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [sellers, setSellers] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [leads, setLeads] = useState([]);
  const [render, setRender] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await getDataID(API_ROUTES.BOATS.GET.DETAILS, id_boat);
        setData(response.data.detail);
        const people = await getDataID(API_ROUTES.TRANSACTIONS.GET.PEOPLE, id);
        setBuyers(people.data.filter((person) => person.status_people === 1));
        setSellers(people.data.filter((person) => person.status_people === 0));
        const peopleId = people.data.map((item) => item.id_people);
        const getPerson = await getDataID(API_ROUTES.BOATS.GET.PERSON, id_boat);
        setLeads(getPerson.data.filter((item) => !peopleId.includes(item.id)));
      } catch (error) {
        if (error?.response?.status === 403) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          navigate('/login'); // redirect to login page if error 403
        } else {
          console.log('people index L120 error ', error);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [navigate, id, id_boat, newFetch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'status') {
      const newStatus = status === 0 ? 1 : 0;
      setDataUpdate((prev) => ({ ...prev, status: newStatus }));
    } else {
      setDataUpdate((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleDeleteNewBuyer = (index) => {
    const Arr = JSON.parse(JSON.stringify(addBuyer));
    Arr.splice(index, 1);
    setAddBuyer(Arr);
  };

  return (
    <Form
      id="formTransaction"
      onSubmit={handleSubmit}
      onReset={handleReset}
      className="transactionCore"
    >
      <div className="transactionCore__foreTitle">
        <span>{date}</span>
        <span>ID {id}</span>
      </div>
      <div className="transactionCore__title">
        {isEditing ? (
          <React.Fragment>
            <h1>{t('components.transactionCore.title')}</h1>
            <InputGroup className="transactionCore__title__edit">
              <Form.Control
                type="text"
                name="price_offer"
                value={price}
                onChange={handleChange}
              />
              <InputGroup.Text>€</InputGroup.Text>
            </InputGroup>
            <Button
              size="sm"
              variant={status === 0 ? 'warning' : 'success'}
              name="status"
              onClick={handleChange}
            >
              {status === 0
                ? `${t('components.transactionCore.status.inProgress')}`
                : `${t('components.transactionCore.status.archived')}`}
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h1>
              {new Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                maximumFractionDigits: 0,
              }).format(price)}
            </h1>
            <Badge
              bg={status === 0 ? 'warning' : 'success'}
              text={status === 0 && 'dark'}
            >
              {status === 0
                ? `${t('components.transactionCore.status.inProgress')}`
                : `${t('components.transactionCore.status.archived')}`}
            </Badge>
          </React.Fragment>
        )}
      </div>
      <div
        className={
          isEditing
            ? 'transactionCore__subtitle transactionCore__subtitleEditing'
            : 'transactionCore__subtitle transactionCore__subtitleDisplay'
        }
      >
        {isEditing ? (
          <React.Fragment>
            <span>{t('components.transactionCore.prepayment')}</span>
            <InputGroup className="transactionCore__subtitleEditing__input">
              <Form.Control
                size="sm"
                type="text"
                name="prepayment"
                value={prepayment}
                onChange={handleChange}
              />
              <InputGroup.Text>€</InputGroup.Text>
            </InputGroup>
          </React.Fragment>
        ) : (
          <span>
            {t('components.transactionCore.prepayment')}{' '}
            {new Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 0,
            }).format(prepayment)}
          </span>
        )}
      </div>
      <hr className="transactionCore__transitionTitle" />
      {sellers &&
        sellers.map((element, index) => {
          return (
            <TransactionCorePerson
              key={index}
              element={element}
              status={t('components.transactionCore.seller')}
              priceOffer={priceOffer}
              id_boat={id_boat}
              isEditing={isEditing}
              commissionUpdate={commissionUpdate}
              setCommissionUpdate={setCommissionUpdate}
              commissionCalcul={commissionCalcul}
            />
          );
        })}
      <hr />
      {loading ? (
        <h2 className="transactionCore__loading">
          {t('components.transactionCore.loadingBoat')}
        </h2>
      ) : (
        <div className="transactionCore__boat">
          <div className="transactionCore__boat__title">
            <div className="transactionCore__boat__title__text">
              <h2>{t('components.transactionCore.boat')}</h2>
              <h3>
                {data?.brand} {data?.model}
              </h3>
            </div>
            <Button
              variant="info"
              as={Link}
              to={`/boats/${id_boat}`}
              className="transactionCore__boat__title__button"
            >
              <FiExternalLink />
              {t('components.transactionCore.link')}
            </Button>
          </div>
          <div className="transactionCore__boat__core">
            <div className="transactionCore__boat__core__info">
              <span className="transactionCore__boat__core__info__label">
                {t('components.transactionCore.year')}
              </span>
              <span>{data?.year}</span>
            </div>
            <div className="transactionCore__boat__core__info">
              <span className="transactionCore__boat__core__info__label">
                {t('components.transactionCore.price')}
              </span>
              <span>
                {new Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR',
                  maximumFractionDigits: 0,
                }).format(data?.price)}
              </span>
            </div>
            <div className="transactionCore__boat__core__info">
              <span className="transactionCore__boat__core__info__label">
                {t('components.transactionCore.name')}
              </span>
              <span>{data?.name}</span>
            </div>
          </div>
        </div>
      )}
      <hr />
      {buyers &&
        buyers.map((element, index) => {
          return (
            <TransactionCorePerson
              key={index}
              id_transaction={id}
              element={element}
              status={t('components.transactionCore.buyer')}
              priceOffer={priceOffer}
              shares={element.shares}
              isEditing={isEditing}
              sharesUpdate={sharesUpdate}
              setSharesUpdate={setSharesUpdate}
            />
          );
        })}
      {isEditing &&
        addBuyer.map((element, index) => {
          return (
            <AddBuyer
              key={index}
              index={index}
              buyer={element}
              dataLeads={leads}
              handleDelete={handleDeleteNewBuyer}
              render={render}
              setRender={setRender}
            />
          );
        })}
      {isEditing && (
        <Button
          onClick={addNewBuyer}
          size="sm"
          className="transactionCore__addBuyer"
        >
          <IoMdAddCircleOutline />
          <span>{t('components.transactionCore.addBuyer')}</span>
        </Button>
      )}
      <hr />
      <ToastContainer
        position="bottom-end"
        className="transactionCore__toastContainer"
      >
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          bg={bgToast}
          autohide
        >
          <Toast.Body>
            <span className="me-auto">{textToast}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      {rightUpdate && (
        <div className="transactionCore__editGroup">
          <EditButtonGroup
            editIcon={<FaEdit />}
            editButtonName={t('components.transactionCore.edit')}
            setIsEditing={setIsEditing}
            isEditing={isEditing}
            valideIcon={<IoMdCheckmarkCircleOutline />}
            valideButtonName={t('components.transactionCore.save')}
            cancelIcon={<IoMdCloseCircleOutline />}
            cancelButtonName={t('components.transactionCore.cancel')}
            form="formTransaction"
            isLoading={isLoading}
          />
          {isEditing && (
            <ButtonModal
              iconButton={<FaRegTrashAlt />}
              nameButton="Delete"
              className="d-flex align-items-center gap-1"
              variantButton="danger"
              modalTitle="Deleting the transaction"
              modalBody="Do you want to delete this transaction?"
              nameConfirm="Validate"
              variantConfirm="success"
              handleConfirm={handleDelete}
              nameCancel="Cancel"
              variantCancel="danger"
            />
          )}
        </div>
      )}
    </Form>
  );
}
