import axios from 'axios';
import { API_ROUTES } from './constants';

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Mark the query to avoid infinite loops
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await axios.get(API_ROUTES.TOKEN.REFRESH_TOKEN, {
          headers: { Authorization: `Bearer ${refreshToken}` },
        });
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        return axios({
          ...originalRequest,
          headers: {
            ...originalRequest.headers,
            Authorization: `Bearer ${response.data.token}`,
          },
        }); // Resend the original failed request
      } catch (refreshError) {
        console.log('Erreur de rafraîchissement du token:', refreshError);
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  },
);

// POST
export function postLogin(url, login, password) {
  try {
    return axios.post(url, {
      login: login,
      password: password,
    });
  } catch (error) {
    console.log(error?.response?.data);
    throw error;
  }
}

export function postCreateBoat(data, url, id) {
  const token = localStorage.getItem('token');
  try {
    return axios.post(url.replace(':id', id), data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.log(error?.response?.data);
    throw error;
  }
}

export function postImagesBoat(data, url, id) {
  const token = localStorage.getItem('token');
  try {
    return axios.post(url.replace(':id', id), data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    console.log(error?.response?.data);
    throw error;
  }
}

export function postDataID(url, data, id) {
  const token = localStorage.getItem('token');
  try {
    return axios.post(url.replace(':id', id), data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.log(error?.response?.data);
    throw error;
  }
}

export function postData(url, data) {
  const token = localStorage.getItem('token');
  try {
    return axios.post(url, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.log(error?.response?.data);
    throw error;
  }
}

export function postDataUser(url, data) {
  const token = localStorage.getItem('token');
  try {
    return axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
    });
  } catch (error) {
    console.log(error?.response?.data);
    throw error;
  }
}

// GET refresh token
export function getNewToken() {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    return axios.get(API_ROUTES.TOKEN.REFRESH_TOKEN, {
      headers: { Authorization: `Bearer ${refreshToken}` },
      withCredentials: true,
    });
  } catch (error) {
    console.log(error?.response?.data);
    throw error;
  }
}
// GET
export function getDashboard(url) {
  const token = localStorage.getItem('token');
  try {
    return axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.log(error?.response?.data);
    throw error;
  }
}

export function getAllAgency(url) {
  const token = localStorage.getItem('token');
  try {
    return axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.log(error?.response?.data);
    throw error;
  }
}

export function getData(url) {
  const token = localStorage.getItem('token');
  try {
    if (!token) {
      throw new Error('No token found in localStorage');
    }
    return axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.log(error?.response?.data);
    throw error;
  }
}

export function getDataID(url, id) {
  const token = localStorage.getItem('token');
  try {
    return axios.get(url.replace(':id', id), {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.log(error?.response?.data);
    throw error;
  }
}

export function getDataID2(url, id1, id2) {
  const token = localStorage.getItem('token');
  try {
    return axios.get(url.replace(':id1', id1).replace(':id2', id2), {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.log(error?.response?.data);
    throw error;
  }
}

export function getDataID2File(url, id1, id2) {
  const token = localStorage.getItem('token');
  try {
    return axios.get(url.replace(':id1', id1).replace(':id2', id2), {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob',
    });
  } catch (error) {
    console.log(error?.response?.data);
    throw error;
  }
}

export function getDataParams(url, params) {
  const token = localStorage.getItem('token');
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
      params: params,
    };
    return axios.get(url, config);
  } catch (error) {
    console.log('Error in getDataParams: ', error.message);
    throw error;
  }
}

// PUT
export function putDataID(url, data, id) {
  const token = localStorage.getItem('token');
  try {
    return axios.put(url.replace(':id', id), data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.log(error?.response?.data);
    throw error;
  }
}

export function putDataUser(url, data) {
  const token = localStorage.getItem('token');
  try {
    return axios.put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
    });
  } catch (error) {
    console.log(error?.response?.data);
    throw error;
  }
}

// DELETE
export function deleteDataID(url, id, params) {
  const token = localStorage.getItem('token');
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
      params: params,
    };
    return axios.delete(url.replace(':id', id), config);
  } catch (error) {
    console.log(error?.response?.data);
    throw error;
  }
}
