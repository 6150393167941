// import libraries
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import 'react-phone-number-input/style.css';
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';
// import utils
import { API_ROUTES } from '../../utils/constants';
import { getData, getDataParams, postData } from '../../utils/api';
// import css
import '../../assets/styles/people.scss';

/*
People page
- Form to search and create new people
*/

const initialState = {
  title: 1,
  lastname: '',
  firstname: '',
  company: '',
  email: '',
  phone: '',
  searchedPhone: '',
  address1: '',
  address2: '',
  zip_code: '',
  town: '',
  country: '',
  birthdate: '',
  birthplace: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'RESET':
      return initialState;
    case 'UPDATE':
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};

export default function People() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [title, setTitle] = useState();
  const [people, dispatch] = useReducer(reducer, initialState);
  const [resultSearch, setResultSearch] = useState([]);
  const [haveSearched, setHaveSearched] = useState(false);
  const [phone, setPhone] = useState();
  const [needConfirmation, setNeedConfirmation] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [disabledReset, setDisabledReset] = useState(false);
  const divNotif = useRef(null);
  const spanNotif = useRef(null);
  const confirmationResolve = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await getData(API_ROUTES.PEOPLE.GET.TITLE);
        setTitle(response.data.sort((a, b) => a.id - b.id));
      } catch (error) {
        if (error?.response?.status === 403) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          navigate('/login'); // redirect to login page if error 403
        }
        console.log('people index L47 error ', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [navigate]);

  const validateEmail = (email) => {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexEmail.test(email);
  };

  const handleChange = (field) => (e) => {
    dispatch({ type: 'UPDATE', field, value: e.target.value });
  };

  const clearNotif = () => {
    if (needConfirmation) {
      setNeedConfirmation(false);
    }
    divNotif.current &&
      divNotif.current.classList.remove(...divNotif.current.classList);
    if (spanNotif.current) {
      spanNotif.current.style.display = 'none';
      spanNotif.current.textContent = ' ';
    }
  };

  const handleValidationErrors = () => {
    divNotif.current.classList.add('people__notification', 'people__error');

    if (!people.lastname && !people.firstname && !people.email && !phone) {
      displayNotification(
        `${t('pages.people.notificationContent.missingField')}`,
      );
    } else if (!validateEmail(people.email) && people.email) {
      displayNotification(
        `${t('pages.people.notificationContent.wrongEmail')}`,
      );
    } else if (!isValidPhoneNumber(phone) && phone) {
      displayNotification(
        `${t('pages.people.notificationContent.wrongPhone')}`,
      );
    }
  };

  const displayNotification = (message) => {
    const err = document.createTextNode(message);
    spanNotif.current.appendChild(err);
    spanNotif.current.style.display = 'block';
  };

  useEffect(() => {
    dispatch({ type: 'UPDATE', field: 'phone', value: phone });
  }, [phone]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    clearNotif();
    try {
      const params = new URLSearchParams({
        lastname: people.lastname,
        firstname: people.firstname,
        email: people.email,
        phone: phone,
        searchedPhone: formatPhoneNumber(phone),
        town: people.town,
        country: people.country,
        birthdate: people.birthdate,
      });

      const search = await getDataParams(API_ROUTES.PEOPLE.GET.SEARCH, params);
      setResultSearch(search.data);
      setHaveSearched(true);
    } catch (error) {
      console.log('people index L136 error ', error);
    }

    setProcessing(false);
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      setProcessing(true);
      if (!haveSearched) {
        const params = new URLSearchParams({
          lastname: people.lastname,
          firstname: people.firstname,
          email: people.email,
          phone: phone,
          searchedPhone: formatPhoneNumber(phone),
          town: people.town,
          country: people.country,
          birthdate: people.birthdate,
        });

        const checkSearch = await getDataParams(
          API_ROUTES.PEOPLE.GET.CHECK_SEARCH,
          params,
        );
        setResultSearch(checkSearch.data);
        clearNotif();
        if (checkSearch.data) {
          if (divNotif.current) {
            divNotif.current.classList.add(
              'people__notification',
              'people__warn',
            );
            const warn = document.createTextNode(
              `${t('pages.people.notificationContent.possibleMatches')}`,
            );
            spanNotif.current.appendChild(warn);
            spanNotif.current.style.display = 'block';
            setNeedConfirmation(true);
            setDisabledReset(false);
          }
          setNeedConfirmation(true);

          await new Promise((resolve) => {
            confirmationResolve.current = resolve;
          });

          setHaveSearched(true);
        }
      }
      if (people.lastname || people.firstname || people.email || people.phone) {
        const response = await postData(API_ROUTES.PEOPLE.POST.PEOPLE, people);
        navigate(`/people/${response.data.id}`);
      } else {
        handleValidationErrors();
      }
      setProcessing(false);
    } catch (error) {
      console.log('people index L200 error ', error);
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (isConfirmed && confirmationResolve.current) {
      confirmationResolve.current();
      confirmationResolve.current = null;
    }
  }, [isConfirmed]);

  const handleReset = () => {
    dispatch({ type: 'RESET' });
    setPhone('');
    setHaveSearched(false);
    setProcessing(false);
    clearNotif();
    setNeedConfirmation(false);
    setIsConfirmed(false);
  };

  return (
    <div className="people">
      {!loading && (
        <section className="people__top">
          <Form onSubmit={(e) => handleSubmit(e)} onReset={handleReset}>
            <Row className="mb-4">
              <Form.Group
                as={Col}
                md={2}
                className="top__group"
                controlId="formTitle"
              >
                <Form.Select name="title" onChange={handleChange('title')}>
                  {title &&
                    title.map((element, index) => {
                      return (
                        <option key={index} value={element.id}>
                          {t(`${element.tag}`)}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
              <Form.Group
                as={Col}
                className="top__group"
                controlId="formLastname"
              >
                <Form.Label column md={3}>
                  {t('pages.people.lastname*')}
                </Form.Label>
                <Form.Control
                  placeholder={t('pages.people.lastname')}
                  name="lastname"
                  onChange={handleChange('lastname')}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                className="top__group"
                controlId="formFirstname"
              >
                <Form.Label column md={3}>
                  {t('pages.people.firstname')}
                </Form.Label>
                <Form.Control
                  placeholder={t('pages.people.firstname')}
                  name="firstname"
                  onChange={handleChange('firstname')}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                className="top__group"
                controlId="formCompany"
              >
                <Form.Label column md={3}>
                  {t('pages.people.company')}
                </Form.Label>
                <Form.Control
                  placeholder={t('pages.people.company')}
                  name="company"
                  onChange={handleChange('compagny')}
                />
              </Form.Group>
            </Row>
            <Row className="mb-4">
              <Form.Group
                as={Col}
                md={4}
                className="top__group"
                controlId="formEmail"
              >
                <Form.Label column md={2}>
                  {t('pages.people.email')}
                </Form.Label>
                <Form.Control
                  placeholder={t('pages.people.email')}
                  name="email"
                  onChange={handleChange('email')}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                md={3}
                className="top__group"
                controlId="formPhone"
              >
                <PhoneInput
                  placeholder={t('pages.people.phone')}
                  name="phone"
                  defaultCountry="FR"
                  value={phone}
                  onChange={setPhone}
                  inputComponent={Form.Control}
                />
              </Form.Group>
            </Row>
            <Row className="mb-4">
              <Form.Group
                as={Col}
                md={8}
                className="top__group"
                controlId="formAddress1"
              >
                <Form.Label column md={1}>
                  {t('pages.people.address1')}
                </Form.Label>
                <Form.Control
                  placeholder={t('pages.people.address1')}
                  name="address1"
                  onChange={handleChange('address1')}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                md={4}
                className="top__group"
                controlId="formAddress2"
              >
                <Form.Label column md={2}>
                  {t('pages.people.address2')}
                </Form.Label>
                <Form.Control
                  placeholder={t('pages.people.address2')}
                  name="address2"
                  onChange={handleChange('address2')}
                />
              </Form.Group>
            </Row>
            <Row className="mb-4">
              <Form.Group
                as={Col}
                md={2}
                className="top__group"
                controlId="formZipCode"
              >
                <Form.Label column md={5}>
                  {t('pages.people.zipCode')}
                </Form.Label>
                <Form.Control
                  placeholder={t('pages.people.zipCode')}
                  name="zip_code"
                  onChange={handleChange('zip_code')}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                md={4}
                className="top__group"
                controlId="formTown"
              >
                <Form.Label column md={1}>
                  {t('pages.people.town')}
                </Form.Label>
                <Form.Control
                  placeholder={t('pages.people.town')}
                  name="town"
                  onChange={handleChange('town')}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                md={3}
                className="top__group"
                controlId="formCountry"
              >
                <Form.Label column md={3}>
                  {t('pages.people.country')}
                </Form.Label>
                <Form.Control
                  placeholder={t('pages.people.country')}
                  name="country"
                  onChange={handleChange('country')}
                />
              </Form.Group>
            </Row>
            <Row className="mb-4">
              <Form.Group
                as={Col}
                md={2}
                className="top__group"
                controlId="formBirthdate"
              >
                <Form.Label column md={5}>
                  {t('pages.people.birthdate')}
                </Form.Label>
                <Form.Control
                  placeholder={t('pages.people.birthdate')}
                  name="birthdate"
                  onChange={handleChange('birthdate')}
                />
              </Form.Group>
              <Form.Group
                as={Col}
                md={3}
                className="top__group"
                controlId="formBirthplace"
              >
                <Form.Label column md={4}>
                  {t('pages.people.birthplace')}
                </Form.Label>
                <Form.Control
                  placeholder={t('pages.people.birthplace')}
                  name="birthplace"
                  onChange={handleChange('birthplace')}
                />
              </Form.Group>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center">
                <Button type="submit" disabled={processing}>
                  {processing
                    ? `${t('pages.people.loading')}`
                    : `${t('pages.people.search')}`}
                </Button>
              </Col>
              <Col className="d-flex justify-content-center">
                <Button
                  variant="success"
                  onClick={(e) => handleCreate(e)}
                  disabled={processing}
                >
                  {processing
                    ? `${t('pages.people.loading')}`
                    : `${t('pages.people.create')}`}
                </Button>
              </Col>
              <Col className="d-flex justify-content-center">
                <Button
                  type="reset"
                  variant="danger"
                  disabled={processing && disabledReset}
                >
                  {processing && disabledReset
                    ? `${t('pages.people.loading')}`
                    : `${t('pages.people.reset')}`}
                </Button>
              </Col>
            </Row>
          </Form>
        </section>
      )}
      <div
        id="people__notification__container"
        ref={divNotif}
        className="people__notification"
      >
        <span
          id="people__notification__text"
          ref={spanNotif}
          className="people__notification__text"
        >
          {t('pages.people.notification')}
        </span>
        {needConfirmation && (
          <Button
            variant="success"
            size="sm"
            onClick={() => setIsConfirmed(true)}
          >
            {t('pages.people.yes')}
          </Button>
        )}
      </div>
      <hr className="people__line" />
      {resultSearch.length > 0 ? (
        <Table striped bordered hover className="people__results">
          <thead>
            <tr>
              <th>{t('pages.people.id')}</th>
              <th>{t('pages.people.lastname')}</th>
              <th>{t('pages.people.firstname')}</th>
              <th>{t('pages.people.email')}</th>
              <th>{t('pages.people.phone')}</th>
            </tr>
          </thead>
          <tbody>
            {resultSearch.map((element, index) => {
              return (
                <tr key={index}>
                  <td>
                    <Link to={`/people/${element.id}`}>{element.id}</Link>
                  </td>
                  <td>
                    <Link to={`/people/${element.id}`}>{element.lastname}</Link>
                  </td>
                  <td>
                    <Link to={`/people/${element.id}`}>
                      {element.firstname}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/people/${element.id}`}>{element.email}</Link>
                  </td>
                  <td>
                    <Link to={`/people/${element.id}`}>
                      {formatPhoneNumberIntl(element.phone)}
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <span className="people_nofound">{t('pages.people.noMatch')}</span>
      )}
    </div>
  );
}
