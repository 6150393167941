// import libraries
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Accordion,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
  Toast,
  ToastContainer,
  Table,
  Spinner,
  Dropdown,
} from 'react-bootstrap';
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
  IoMdAddCircleOutline,
} from 'react-icons/io';
import { FaRegTrashAlt } from 'react-icons/fa';
import { IoDuplicate } from 'react-icons/io5';
import { FiExternalLink } from 'react-icons/fi';
import { MdAddBox } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
// import components
import Carousel from '../../components/carousel';
import AddEngine from '../../components/addEngine';
import AddBattery from '../../components/addBattery';
import AddGenerator from '../../components/addGenerator';
import AddTank from '../../components/addTank';
import AddCabin from '../../components/addCabin';
import AddKitchen from '../../components/addKitchen';
import AddBathroom from '../../components/addBathroom';
import AddSail from '../../components/addSail';
// import utils
import { dafn } from '../../utils/dafn';
import { API_ROUTES } from '../../utils/constants';
import {
  deleteDataID,
  getAllAgency,
  getData,
  getDataID,
  getDataID2,
  getDataParams,
  postDataID,
  putDataID,
} from '../../utils/api';
import { CURRENCIES } from '../../utils/currencies';
// import css
import '../../assets/styles/boat.scss';
import '../../assets/styles/customDropdown.scss';
import 'flag-icons/css/flag-icons.min.css';

/*
Boat details page
Display all images and details of a boat fetch by his id.
Each informations can be modify.
*/

export default function BoatDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [details, setDetails] = useState();
  const [enginesDisplay, setEnginesDisplay] = useState([]);
  const [maneuverOptions, setManeuverOptions] = useState();
  const [batteries, setBatteries] = useState([]);
  const [generators, setGenerators] = useState([]);
  const [solarPanel, setSolarPanel] = useState();
  const [windturbine, setWindturbine] = useState();
  const [electronic, setElectronic] = useState();
  const [tanks, setTanks] = useState([]);
  const [comfort, setComfort] = useState();
  const [cabinsDisplay, setCabinsDisplay] = useState([]);
  const [kitchens, setKitchens] = useState([]);
  const [bathroom, setBathroom] = useState([]);
  const [cover, setCover] = useState();
  const [sails, setSails] = useState([]);
  const [rigging, setRigging] = useState();
  const [fittings, setFittings] = useState();
  const [equipments, setEquipments] = useState();
  const [ads, setAds] = useState();
  const [security, setSecurity] = useState();
  const [securityCategory, setSecurityCategory] = useState([]);
  const [saving, setSaving] = useState(false);
  // features
  const featureBoatNetwork = 'boat_network';
  const featureBoatAgency = 'boat_agency';
  const featureBoatUpdateSub = 'boat_update_sub';
  // const featureBoatUpdateAs = "boat_update_as";
  const featureBoatInfosSupp = 'boat_infos_supp';
  // const featureBoatOwner = "boat_owner";
  const [auth, setAuth] = useState([]);
  const [displayBoat, setDisplayBoat] = useState(false);

  useEffect(() => {
    const fetchAuthoriz = async () => {
      try {
        // const responseFeatures = await getData(API_ROUTES.USERS.GET.FEATURES);
        // setFeatures(responseFeatures.data);
        const response = await getData(API_ROUTES.USERS.GET.AUTHORIZATION);
        setAuth(response.data);
        const responseLevel = await getAllAgency(API_ROUTES.USERS.GET.AGENCY);
        let network = [];
        responseLevel.data.networkid.forEach((element) => {
          network.push(element.agencyID);
        });
        const params = new URLSearchParams({
          agency: network,
        });
        const responseBoat = await getDataParams(
          API_ROUTES.BOATS.GET.CARDLIST,
          params,
        );
        setData(responseBoat.data);
      } catch (error) {
        console.log('error on fetch auth ', error);
      }
    };
    fetchAuthoriz();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const displayBoatCheck = () => {
        if (data.some((item) => item.id === Number(id))) {
          auth.includes(featureBoatNetwork) && setDisplayBoat(true);
          if (
            JSON.parse(localStorage.getItem('ids')).agency ===
              data.find((item) => item.id === Number(id)).agency &&
            auth.includes(featureBoatAgency)
          )
            setDisplayBoat(true);
        } else {
          setDisplayBoat(false);
        }
      };
      displayBoatCheck();
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response0 = await getDataID(API_ROUTES.BOATS.GET.DETAILS, id);
        setDetails(response0.data.detail);
        if (auth.includes(featureBoatUpdateSub)) {
          const responseLevel = await getAllAgency(API_ROUTES.USERS.GET.AGENCY);
          let network = [];
          responseLevel.data.networkid.forEach((element) => {
            network.push(element.agencyID);
          });
          const params = new URLSearchParams();
          network.forEach((id) => params.append('network', id));
          params.append('user', JSON.parse(localStorage.getItem('ids')).user);
          const responseSubordinates = await getDataParams(
            API_ROUTES.USERS.GET.SUBORDINATES,
            params,
          );
          const checkBoat = data.find((item) => item.id === Number(id));
          setUser(
            response0.data.modify ||
              responseSubordinates.data.some(
                (item) => item.id === checkBoat.user,
              ),
          );
        } else {
          setUser(response0.data.modify);
        }
        const response1 = await getDataID(
          API_ROUTES.BOATS.GET.ENGINES_DISPLAY,
          id,
        );
        setEnginesDisplay(response1.data);
        const response2 = await getDataID(API_ROUTES.BOATS.GET.BATTERIES, id);
        setBatteries(response2.data);
        setBatteriesUpdate(response2.data);
        const response3 = await getDataID(API_ROUTES.BOATS.GET.GENERATORS, id);
        setGenerators(response3.data);
        setGeneratorsUpdate(response3.data);
        const response4 = await getDataID(
          API_ROUTES.BOATS.GET.TANKS_DISPLAY,
          id,
        );
        setTanks(response4.data);
        const response5 = await getDataID(
          API_ROUTES.BOATS.GET.CABINS_DISPLAY,
          id,
        );
        setCabinsDisplay(response5.data);
        const response6 = await getDataID(
          API_ROUTES.BOATS.GET.KITCHENS_DISPLAY,
          id,
        );
        setKitchens(response6.data);
        const response7 = await getDataID(
          API_ROUTES.BOATS.GET.BATHROOMS_DISPLAY,
          id,
        );
        setBathroom(response7.data);
        const response8 = await getDataID(
          API_ROUTES.BOATS.GET.SAILS_DISPLAY,
          id,
        );
        setSails(response8.data);
        const response9 = await getDataID(
          API_ROUTES.BOATS.GET.SECURITY_CATEGORY_UPDATE,
          id,
        );
        setSecurityCategory(response9.data);
      } catch (error) {
        console.log('boat index L110 error ', error);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [auth, data]);

  useEffect(() => {
    if (details) {
      try {
        const fetchData = async () => {
          if (details.id_maneuver_options) {
            const response0 = await getDataID(
              API_ROUTES.BOATS.GET.MANEUVER_OPTIONS,
              details.id_maneuver_options,
            );
            setManeuverOptions(response0.data);
            setManeuverOptionsUpdate(response0.data);
          }
          if (details.id_solar_panel) {
            const response1 = await getDataID(
              API_ROUTES.BOATS.GET.SOLAR_PANELS,
              details.id_solar_panel,
            );
            setSolarPanel(response1.data);
            setSolarPanelUpdate(response1.data);
          }
          if (details.id_windturbine) {
            const response2 = await getDataID(
              API_ROUTES.BOATS.GET.WIND_TURBINES,
              details.id_windturbine,
            );
            setWindturbine(response2.data);
            setWindturbineUpdate(response2.data);
          }
          if (details.id_electronic) {
            const response3 = await getDataID(
              API_ROUTES.BOATS.GET.ELECTRONICS,
              details.id_electronic,
            );
            setElectronic(response3.data);
            setElectronicUpdate(response3.data);
          }
          if (details.id_comfort) {
            const response4 = await getDataID(
              API_ROUTES.BOATS.GET.COMFORT,
              details.id_comfort,
            );
            setComfort(response4.data);
            setComfortUpdate(response4.data);
          }
          if (details.id_cover) {
            const response5 = await getDataID(
              API_ROUTES.BOATS.GET.COVER,
              details.id_cover,
            );
            setCover(response5.data);
            setCoverUpdate(response5.data);
          }
          if (details.id_rigging) {
            const response6 = await getDataID(
              API_ROUTES.BOATS.GET.RIGGING,
              details.id_rigging,
            );
            setRigging(response6.data);
            setRiggingUpdate(response6.data);
          }
          if (details.id_fittings) {
            const response7 = await getDataID(
              API_ROUTES.BOATS.GET.FITTINGS,
              details.id_fittings,
            );
            setFittings(response7.data);
            setFittingsUpdate(response7.data);
          }
          if (details.id_security) {
            const response8 = await getDataID(
              API_ROUTES.BOATS.GET.SECURITY,
              details.id_security,
            );
            setSecurity(response8.data);
          }
          if (details.id_equipment) {
            const response = await getDataID(
              API_ROUTES.BOATS.GET.EQUIPMENTS,
              details.id_equipment,
            );
            setEquipments(response.data);
            setEquipmentsUpdate(response.data);
          }
          if (details.id_ads) {
            const response = await getDataID(
              API_ROUTES.BOATS.GET.ADS,
              details.id_ads,
            );
            setAds(response.data);
            setAdsUpdate(response.data);
          }
        };
        fetchData();
      } catch (error) {
        console.log('boat index L213 error', error);
      }
    }
    // eslint-disable-next-line
  }, [details]);

  const [user, setUser] = useState(false);
  const [boat, setBoat] = useState();
  const [boatUpdate, setBoatUpdate] = useState();
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [status, setStatus] = useState([]);
  const [typologies, setTypologies] = useState([]);
  const [flags, setFlags] = useState([]);
  const [transmission, setTransmission] = useState([]);
  const [keels, setKeels] = useState([]);
  const [enginesDefault, setEnginesDefault] = useState([]);
  const [enginesUpdate, setEnginesUpdate] = useState([]);
  const [addEngine, setAddEngine] = useState([]);
  const [deleteEngines, setDeleteEngines] = useState([]);
  const [maneuverOptionsUpdate, setManeuverOptionsUpdate] = useState();
  const [batteriesUpdate, setBatteriesUpdate] = useState([]);
  const [addBattery, setAddBattery] = useState([]);
  const [deleteBatteries, setDeleteBatteries] = useState([]);
  const [generatorsUpdate, setGeneratorsUpdate] = useState([]);
  const [addGenerator, setAddGenerator] = useState([]);
  const [deleteGenerators, setDeleteGenerators] = useState([]);
  const [solarPanelUpdate, setSolarPanelUpdate] = useState();
  const [windturbineUpdate, setWindturbineUpdate] = useState();
  const [electronicUpdate, setElectronicUpdate] = useState();
  const [tanksDefault, setTanksDefault] = useState([]);
  const [tanksUpdate, setTanksUpdate] = useState([]);
  const [addTanks, setAddTanks] = useState([]);
  const [deleteTanks, setDeleteTanks] = useState([]);
  const [tanksTypes, setTanksTypes] = useState([]);
  const [material, setMaterial] = useState([]);
  const [comfortUpdate, setComfortUpdate] = useState();
  const [cabinsDefault, setCabinsDefault] = useState([]);
  const [cabinsUpdate, setCabinsUpdate] = useState([]);
  const [addCabins, setAddCabins] = useState([]);
  const [deleteCabins, setDeleteCabins] = useState([]);
  const [cabinsTypes, setCabinsTypes] = useState([]);
  const [position, setPosition] = useState([]);
  const [kitchensDefault, setKitchensDefault] = useState([]);
  const [kitchensUpdate, setKitchensUpdate] = useState([]);
  const [addKitchens, setAddKitchens] = useState([]);
  const [deleteKitchens, setDeleteKitchens] = useState([]);
  const [hotplates, setHotplates] = useState([]);
  const [oven, setOven] = useState([]);
  const [bathroomDefault, setBathroomDefault] = useState([]);
  const [bathroomUpdate, setBathroomUpdate] = useState([]);
  const [addBathrooms, setAddBathrooms] = useState([]);
  const [deleteBathrooms, setDeleteBathrooms] = useState([]);
  const [coverUpdate, setCoverUpdate] = useState();
  const [sailsDefault, setSailsDefault] = useState([]);
  const [sailsUpdate, setSailsUpdate] = useState([]);
  const [addSails, setAddSails] = useState([]);
  const [deleteSails, setDeleteSails] = useState([]);
  const [sailsTypes, setSailsTypes] = useState([]);
  const [riggingUpdate, setRiggingUpdate] = useState();
  const [fittingsUpdate, setFittingsUpdate] = useState();
  const [equipmentsUpdate, setEquipmentsUpdate] = useState();
  const [adsUpdate, setAdsUpdate] = useState();
  const [securityDefault, setSecurityDefault] = useState();
  const [securityUpdate, setSecurityUpdate] = useState();
  const [securityEquipment, setSecurityEquipment] = useState([]);
  const [securityCertifDefault, setSecurityCertifDefault] = useState([]);
  const [securityCertifUpdate, setSecurityCertifUpdate] = useState([]);
  const [securityCategoryList, setSecurityCategoryList] = useState([]);
  const [addCertif, setAddCertif] = useState([]);
  const [delCertif, setDelCertif] = useState([]);
  const [people, setPeople] = useState([]);
  const [peopleDefault, setPeopleDefault] = useState([]);
  const [person, setPerson] = useState([]);
  const [personDefault, setPersonDefault] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [statusPeople, setStatusPeople] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('1');
  const [shares, setShares] = useState('');
  const [addingOwner, setAddingOwner] = useState([]);
  const [addingLead, setAddingLead] = useState([]);
  const [deletedPeople, setDeletedPeople] = useState([]);
  const [transaction, setTransaction] = useState();
  const [transactionUpdate, setTransactionUpdate] = useState();
  const [commissionMoney, setCommissionMoney] = useState();
  const [commissionPercentage, setCommissionPercentage] = useState();
  const [buyerList, setBuyerList] = useState([]);
  const [tracking, setTracking] = useState([]);

  // update engines field and set new values
  const updateEnginesField = (e, index, field) => {
    const Arr = JSON.parse(JSON.stringify(enginesUpdate));
    Arr[index][`${field}`] = e.target.value;
    setEnginesUpdate(Arr);
  };

  const deleteEngine = (id) => {
    setDeleteEngines([...deleteEngines, id]);
    const Arr = JSON.parse(JSON.stringify(enginesUpdate));
    const index = Arr.findIndex((x) => x.id === id);
    Arr.splice(index, 1);
    setEnginesUpdate(Arr);
  };

  const addNewEngine = () => {
    const newEngine = {
      id: addEngine.length + 1,
      brand: '',
      model: '',
      power: 0,
      fiscal_power: 0,
      id_transmission: 1,
      hours: 0,
      serial_number: '',
    };
    setAddEngine([...addEngine, newEngine]);
  };

  const deleteObjectEngine = (id) => {
    const Arr = JSON.parse(JSON.stringify(addEngine));
    const index = Arr.map((element) => element.id).indexOf(id);
    Arr.splice(index, 1);
    setAddEngine(Arr);
  };

  const duplicateEngine = (id) => {
    const duplicate = enginesUpdate.find((element) => element.id === id);
    const clonedDuplicate = JSON.parse(JSON.stringify(duplicate));
    setAddEngine([...addEngine, clonedDuplicate]);
  };

  const duplicateNewEngine = (id) => {
    const duplicateNew = addEngine[id - 1];
    setAddEngine([...addEngine, duplicateNew]);
  };

  // update battery park field and set new values
  const updateBatteryField = (e, index, field) => {
    const Arr = JSON.parse(JSON.stringify(batteriesUpdate));
    Arr[index][`${field}`] = e.target.value;
    setBatteriesUpdate(Arr);
  };

  const addNewBattery = () => {
    const newBattery = {
      id: addBattery.length + 1,
      quantity: 0,
      voltage: 0,
      amperage: 0,
      assignment: '',
      type: '',
    };
    setAddBattery([...addBattery, newBattery]);
  };

  const deleteObjectBattery = (id) => {
    const Arr = JSON.parse(JSON.stringify(addBattery));
    const index = Arr.map((element) => element.id).indexOf(id);
    Arr.splice(index, 1);
    setAddBattery(Arr);
  };

  const deleteBattery = (id) => {
    setDeleteBatteries([...deleteBatteries, id]);
    const Arr = JSON.parse(JSON.stringify(batteriesUpdate));
    const index = Arr.findIndex((x) => x.id === id);
    Arr.splice(index, 1);
    setBatteriesUpdate(Arr);
  };

  // update generators field and set new values
  const updateGeneratorField = (e, index, field) => {
    const Arr = JSON.parse(JSON.stringify(generatorsUpdate));
    Arr[index][`${field}`] = e.target.value;
    setGeneratorsUpdate(Arr);
  };

  const addNewGenerator = () => {
    const newGenerator = {
      id: addGenerator.length + 1,
      brand: '',
      model: '',
      power: 0,
      hours: 0,
      serial_number: '',
    };
    setAddGenerator([...addGenerator, newGenerator]);
  };

  const deleteObjectGenerator = (id) => {
    const Arr = JSON.parse(JSON.stringify(addGenerator));
    const index = Arr.map((element) => element.id).indexOf(id);
    Arr.splice(index, 1);
    setAddGenerator(Arr);
  };

  const deleteGenerator = (id) => {
    setDeleteGenerators([...deleteGenerators, id]);
    const Arr = JSON.parse(JSON.stringify(generatorsUpdate));
    const index = Arr.findIndex((x) => x.id === id);
    Arr.splice(index, 1);
    setGeneratorsUpdate(Arr);
  };

  // update tanks field and set new values
  const updateTanksField = (e, index, field) => {
    const Arr = JSON.parse(JSON.stringify(tanksUpdate));
    if (field === 'id_material' && e.target.value === '0') {
      Arr[index].id_material = null;
    } else {
      Arr[index][`${field}`] = e.target.value;
      setTanksUpdate(Arr);
    }
  };

  const addNewTank = () => {
    const newTank = {
      id: addTanks.length + 1,
      capacity: 0,
      id_tanks_type: 1,
      id_material: null,
    };
    setAddTanks([...addTanks, newTank]);
  };

  const deleteObjectTank = (id) => {
    const Arr = JSON.parse(JSON.stringify(addTanks));
    const index = Arr.map((element) => element.id).indexOf(id);
    Arr.splice(index, 1);
    setAddTanks(Arr);
  };

  const deleteTank = (id) => {
    setDeleteTanks([...deleteTanks, id]);
    const Arr = JSON.parse(JSON.stringify(tanksUpdate));
    const index = Arr.findIndex((x) => x.id === id);
    Arr.splice(index, 1);
    setTanksUpdate(Arr);
  };

  // update cabins field and set new values
  const updateCabinsField = (e, index, field) => {
    if (e.target.checked !== undefined && field !== 'quantity_single_bed') {
      const Arr = JSON.parse(JSON.stringify(cabinsUpdate));
      Arr[index][`${field}`] = e.target.checked;
      setCabinsUpdate(Arr);
    } else {
      const Arr = JSON.parse(JSON.stringify(cabinsUpdate));
      Arr[index][`${field}`] = e.target.value;
      setCabinsUpdate(Arr);
    }
  };

  const addNewCabin = () => {
    const newCabin = {
      id: addCabins.length + 1,
      id_cabin_type: 1,
      id_position: 1,
      quantity_single_bed: 0,
      double_bed: false,
      double_bed_description: null,
      air_conditioning: false,
      pulse_air_heating: false,
      hifi: false,
      hifi_description: null,
      tv: false,
      tv_description: null,
    };
    setAddCabins([...addCabins, newCabin]);
  };

  const deleteObjectCabin = (id) => {
    const Arr = JSON.parse(JSON.stringify(addCabins));
    const index = Arr.map((element) => element.id).indexOf(id);
    Arr.splice(index, 1);
    setAddCabins(Arr);
  };

  const deleteCabin = (id) => {
    setDeleteCabins([...deleteCabins, id]);
    const Arr = JSON.parse(JSON.stringify(cabinsUpdate));
    const index = Arr.findIndex((x) => x.id === id);
    Arr.splice(index, 1);
    setCabinsUpdate(Arr);
  };

  // update kitchens field and set new values
  const updateKitchensField = (e, index, field) => {
    if (field === 'microwave') {
      const Arr = JSON.parse(JSON.stringify(kitchensUpdate));
      Arr[index][`${field}`] = e.target.checked;
      setKitchensUpdate(Arr);
    } else {
      const Arr = JSON.parse(JSON.stringify(kitchensUpdate));
      Arr[index][`${field}`] = e.target.value;
      setKitchensUpdate(Arr);
    }
  };

  const addNewKitchen = () => {
    const newKitchen = {
      id: addKitchens.length + 1,
      id_hotplates: null,
      hotplates_description: '',
      quantity_oven: 0,
      id_oven: null,
      microwave: false,
      quantity_fridge: 0,
      quantity_freezer: 0,
      description: '',
    };
    setAddKitchens([...addKitchens, newKitchen]);
  };

  const deleteObjectKitchen = (id) => {
    const Arr = JSON.parse(JSON.stringify(addKitchens));
    const index = Arr.map((element) => element.id).indexOf(id);
    Arr.splice(index, 1);
    setAddKitchens(Arr);
  };

  const deleteKitchen = (id) => {
    setDeleteKitchens([...deleteKitchens, id]);
    const Arr = JSON.parse(JSON.stringify(kitchensUpdate));
    const index = Arr.findIndex((x) => x.id === id);
    Arr.splice(index, 1);
    setKitchensUpdate(Arr);
  };

  // update bathrooms field and set new values
  const updateBathroomField = (e, index, field) => {
    const Arr = JSON.parse(JSON.stringify(bathroomUpdate));
    Arr[index][`${field}`] = e.target.value;
    setBathroomUpdate(Arr);
  };

  const addNewBathroom = () => {
    const newBathroom = {
      id: addBathrooms.length + 1,
      id_position: 1,
      description: '',
      toilets: '',
      shower: '',
      bathtub: '',
    };
    setAddBathrooms([...addBathrooms, newBathroom]);
  };

  const deleteObjectBathroom = (id) => {
    const Arr = JSON.parse(JSON.stringify(addBathrooms));
    const index = Arr.map((element) => element.id).indexOf(id);
    Arr.splice(index, 1);
    setAddBathrooms(Arr);
  };

  const deleteBathroom = (id) => {
    setDeleteBathrooms([...deleteBathrooms, id]);
    const Arr = JSON.parse(JSON.stringify(bathroomUpdate));
    const index = Arr.findIndex((x) => x.id === id);
    Arr.splice(index, 1);
    setBathroomUpdate(Arr);
  };

  // update sails field and set new values
  const updateSailsField = (e, index, field) => {
    const Arr = JSON.parse(JSON.stringify(sailsUpdate));
    Arr[index][`${field}`] = e.target.value;
    setSailsUpdate(Arr);
  };

  const addNewSail = () => {
    const newSail = {
      id: addSails.length + 1,
      id_sail_type: 1,
      brand: null,
      area: null,
      year: null,
      description: null,
    };
    setAddSails([...addSails, newSail]);
  };

  const deleteObjectSail = (id) => {
    const Arr = JSON.parse(JSON.stringify(addSails));
    const index = Arr.map((element) => element.id).indexOf(id);
    Arr.splice(index, 1);
    setAddSails(Arr);
  };

  const deleteSail = (id) => {
    setDeleteSails([...deleteSails, id]);
    const Arr = JSON.parse(JSON.stringify(sailsUpdate));
    const index = Arr.findIndex((x) => x.id === id);
    Arr.splice(index, 1);
    setSailsUpdate(Arr);
  };

  // update catgory field and set new values
  const updateCategoryField = (index, value) => {
    if (value === '') {
      setDelCertif([...delCertif, index]);
      const Arr = JSON.parse(JSON.stringify(securityCertifUpdate));
      const ind = securityCertifUpdate.indexOf(
        securityCertifUpdate.find((item) => item.id_category === index),
      );
      Arr.splice(ind, 1);
      setSecurityCertifUpdate(Arr);
    } else if (
      securityCertifUpdate.find((item) => item.id_category === index)
    ) {
      const Arr = JSON.parse(JSON.stringify(securityCertifUpdate));
      Arr[
        securityCertifUpdate.indexOf(
          securityCertifUpdate.find((item) => item.id_category === index),
        )
      ].quantity_people = value;
      setSecurityCertifUpdate(Arr);
    } else {
      if (addCertif.find((item) => item.id_category === index)) {
        const Arr = JSON.parse(JSON.stringify(addCertif));
        Arr[
          addCertif.indexOf(
            addCertif.find((item) => item.id_category === index),
          )
        ].quantity_people = value;
        setAddCertif(Arr);
      } else {
        const newCat = {
          id: addCertif.length + 1,
          id_boats: id,
          id_category: index,
          quantity_people: value,
        };
        setAddCertif([...addCertif, newCat]);
      }
    }
  };

  const compareArrays = (a, b) => {
    if (a && b) {
      return (
        a.length === b.length &&
        a.every((element, index) => compareObjects(element, b[index]))
      );
    } else {
      return true;
    }
  };

  const compareObjects = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  };

  const isButtonDisabled = () => {
    if (!selectedItem) {
      return true;
    }
    if (selectedStatus === '1') {
      return !(shares >= 1 && shares <= 100);
    }
    if (selectedStatus === '2') {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (user || auth.includes(featureBoatInfosSupp)) {
      const fetchData = async () => {
        try {
          const response = await getDataID(API_ROUTES.BOATS.GET.BOAT, id);
          setBoat({
            ...response.data,
            model: details.model,
            brand: details.brand,
          });
          setBoatUpdate({
            ...response.data,
            model: details.model,
            brand: details.brand,
          });
          const response0 = await getData(API_ROUTES.BOATS.GET.BRANDS);
          setBrands(response0.data);
          const response1 = await getData(API_ROUTES.BOATS.GET.MODELS);
          setModels(response1.data);
          const response2 = await getData(API_ROUTES.BOATS.GET.STATUS);
          setStatus(response2.data);
          const response3 = await getData(API_ROUTES.BOATS.GET.TYPOLOGIES);
          setTypologies(response3.data);
          const response4 = await getData(API_ROUTES.BOATS.GET.FLAGS);
          setFlags(response4.data);
          const response5 = await getData(API_ROUTES.BOATS.GET.TRANSMISSIONS);
          setTransmission(response5.data.sort((a, b) => a.id - b.id));
          const response6 = await getDataID(
            API_ROUTES.BOATS.GET.ENGINES_UPDATE,
            id,
          );
          const getKeels = await getData(API_ROUTES.BOATS.GET.KEELS);
          setKeels(getKeels.data);
          setEnginesDefault(response6.data);
          setEnginesUpdate(response6.data);
          const response7 = await getDataID(
            API_ROUTES.BOATS.GET.TANKS_UPDATE,
            id,
          );
          setTanksDefault(response7.data);
          setTanksUpdate(response7.data);
          const response8 = await getData(API_ROUTES.BOATS.GET.TANKS_TYPES);
          setTanksTypes(response8.data);
          const response9 = await getData(API_ROUTES.BOATS.GET.MATERIALS);
          setMaterial(response9.data);
          const response10 = await getDataID(
            API_ROUTES.BOATS.GET.CABINS_UPDATE,
            id,
          );
          setCabinsDefault(response10.data);
          setCabinsUpdate(response10.data);
          const response11 = await getData(API_ROUTES.BOATS.GET.CABINS_TYPES);
          setCabinsTypes(response11.data);
          const response12 = await getData(API_ROUTES.BOATS.GET.POSITIONS);
          setPosition(response12.data);
          const response13 = await getDataID(
            API_ROUTES.BOATS.GET.KITCHENS_UPDATE,
            id,
          );
          setKitchensDefault(response13.data);
          setKitchensUpdate(response13.data);
          const response14 = await getData(API_ROUTES.BOATS.GET.HOTPLATES);
          setHotplates(response14.data.sort((a, b) => a.id - b.id));
          const response15 = await getData(API_ROUTES.BOATS.GET.OVENS);
          setOven(response15.data.sort((a, b) => a.id - b.id));
          const response16 = await getDataID(
            API_ROUTES.BOATS.GET.BATHROOMS_UPDATE,
            id,
          );
          setBathroomDefault(response16.data);
          setBathroomUpdate(response16.data);
          const response17 = await getDataID(
            API_ROUTES.BOATS.GET.SAILS_UPDATE,
            id,
          );
          setSailsDefault(response17.data);
          setSailsUpdate(response17.data);
          const response18 = await getData(API_ROUTES.BOATS.GET.SAILS_TYPES);
          setSailsTypes(response18.data.sort((a, b) => a.id - b.id));
          const response19 = await getDataID(
            API_ROUTES.BOATS.GET.SECURITY_CERTIFICATION,
            id,
          );
          setSecurityCertifDefault(response19.data);
          setSecurityCertifUpdate(response19.data);
          const response20 = await getData(
            API_ROUTES.BOATS.GET.SECURITY_CATEGORY_DISPLAY,
          );
          setSecurityCategoryList(response20.data);
          const getPeople = await getDataID(API_ROUTES.BOATS.GET.PEOPLE, id);
          setPeople(getPeople.data);
          setPeopleDefault(getPeople.data);
          const getPerson = await getDataID(API_ROUTES.BOATS.GET.PERSON, id);
          setPerson(getPerson.data);
          setPersonDefault(getPerson.data);
          const statusData = await getData(API_ROUTES.PEOPLE.GET.STATUS);
          setStatusPeople(statusData.data);
          const boatEvent = await getDataID(API_ROUTES.BOATS.GET.EVENTS, id);
          setTracking(boatEvent.data);
        } catch (error) {
          console.log('index boat L710 error', error);
        }
      };
      fetchData();
    }
    // eslint-disable-next-line
  }, [user, featureBoatInfosSupp]);

  useEffect(() => {
    if (user && boat) {
      try {
        const fetchData = async () => {
          if (boat.id_security) {
            const response0 = await getDataID2(
              API_ROUTES.BOATS.GET.SECURITY_BOAT,
              id,
              boat.id_security,
            );
            setSecurityDefault(response0.data);
            setSecurityUpdate(response0.data);
          }
          const response = await getData(
            API_ROUTES.BOATS.GET.SECURITY_EQUIPMENT,
          );
          setSecurityEquipment(response.data);
          if (boat.id_status === 8) {
            const transaction = await getDataID(
              API_ROUTES.BOATS.GET.TRANSACTION,
              id,
            );
            setTransaction(transaction.data[0]);
            setTransactionUpdate(transaction.data[0]);
            setCommissionMoney(transaction.data[0].commission);
            setCommissionPercentage(
              (
                (transaction.data[0].commission /
                  transaction.data[0].price_offer) *
                100
              )
                .toFixed(4)
                .toString()
                .replace(/\.00$/, ''),
            );
            if (transaction.data[0].status === 0) {
              const buyers = await getDataID(
                API_ROUTES.TRANSACTIONS.GET.PEOPLE,
                transaction.data[0].id,
              );
              const filteredData = buyers.data
                .filter((item) => item.status_people === 1)
                .map((item) => ({
                  id: item.id_people,
                  shares: item.shares,
                }));
              setBuyerList(filteredData);
            }
          }
        };
        fetchData();
      } catch (error) {
        console.log('index boat L800 error ', error);
      }
    }
    // eslint-disable-next-line
  }, [user, boat]);

  const handleSelectItem = (item) => {
    setSelectedItem(item);
  };

  const CustomMenu = React.forwardRef(
    (
      {
        children,
        style,
        className,
        'aria-labelledby': labeledBy,
        onSelectItem,
      },
      ref,
    ) => {
      const [value, setValue] = useState('');
      const [results, setResults] = useState([]);
      const typingTimeout = useRef(null);

      const handleSearch = async (query) => {
        try {
          const params = new URLSearchParams();
          params.append('value', query);
          const response = await getDataParams(
            API_ROUTES.BOATS.GET.SEARCH_PEOPLE,
            params,
          );
          setResults(response.data);
        } catch (error) {
          console.error('Error fetching search results', error);
        }
      };

      const handleChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);

        if (typingTimeout.current) {
          clearTimeout(typingTimeout.current);
        }

        typingTimeout.current = setTimeout(() => {
          if (newValue.trim()) {
            handleSearch(newValue);
          } else {
            setResults([]);
          }
        }, 300);
      };

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={handleChange}
            value={value}
          />
          <ul className="list-unstyled">
            {results.length > 0 ? (
              results.map((result) => (
                <Dropdown.Item
                  key={result.id}
                  eventKey={result.id}
                  onClick={() => onSelectItem(result)}
                >
                  {result.firstname} {result.lastname}, {result.company} -{' '}
                  {result.email} - {result.phone}
                </Dropdown.Item>
              ))
            ) : (
              <li className="px-3 py-2 text-muted">No results found</li>
            )}
          </ul>
        </div>
      );
    },
  );

  const handleAddPeople = () => {
    if (!selectedItem) {
      return;
    }

    if (selectedStatus === '1' && shares >= 0 && shares <= 100) {
      const newOwner = {
        ...selectedItem,
        statusPeople: selectedStatus,
        shares: shares,
        contact: 0,
        commission: 1000,
      };
      setAddingOwner((prev) => [...prev, newOwner]);
    }
    if (selectedStatus === '2') {
      const newLead = { ...selectedItem, statusPeople: selectedStatus };
      setAddingLead((prev) => [...prev, newLead]);
    }
    setSelectedItem(null);
    setShares('');
  };

  const handleDeletePeople = (id) => {
    if (addingLead.some((element) => element.id === id)) {
      setAddingLead((prev) => prev.filter((element) => element.id !== id));
    } else if (addingOwner.some((element) => element.id === id)) {
      setAddingOwner((prev) => prev.filter((element) => element.id !== id));
    } else {
      setDeletedPeople((prev) => [...prev, id]);
      setPerson((prev) => prev.filter((element) => element.id !== id));
    }
  };

  const handleUpdatePeople = (id, field, value) => {
    if (addingOwner.some((element) => element.id === id)) {
      if (field === 'commission') {
        setAddingOwner((prevPerson) =>
          prevPerson.map((pers) =>
            pers.id === id ? { ...pers, [field]: value * 100 } : pers,
          ),
        );
      } else {
        setAddingOwner((prevPerson) =>
          prevPerson.map((pers) =>
            pers.id === id ? { ...pers, [field]: value } : pers,
          ),
        );
      }
    } else {
      if (field === 'commission') {
        setPeople((prevPerson) =>
          prevPerson.map((pers) =>
            pers.id_people === id ? { ...pers, [field]: value * 100 } : pers,
          ),
        );
      } else {
        setPeople((prevPerson) =>
          prevPerson.map((pers) =>
            pers.id_people === id ? { ...pers, [field]: value } : pers,
          ),
        );
      }
    }
  };

  const addNewBuyer = () => {
    const newBuyer = {
      id: null,
      name: null,
      shares: 0,
    };
    setBuyerList([...buyerList, newBuyer]);
  };

  const handleSelectBuyer = (eventKey, e, index) => {
    const Arr = JSON.parse(JSON.stringify(buyerList));
    Arr[index].id = eventKey;
    Arr[index].name = e.target.textContent;
    setBuyerList(Arr);
  };

  const handleChangeBuyer = (shares, index) => {
    const Arr = JSON.parse(JSON.stringify(buyerList));
    Arr[index].shares = shares;
    setBuyerList(Arr);
  };

  const handleDeleteBuyer = (index) => {
    const Arr = JSON.parse(JSON.stringify(buyerList));
    Arr.splice(index, 1);
    setBuyerList(Arr);
  };

  const handleChangeTransaction = (e) => {
    const { name, value } = e.target;
    setTransactionUpdate((prev) => ({ ...prev, [name]: value }));

    transactionUpdate?.price_offer &&
      setTransactionUpdate((prev) => ({
        ...prev,
        commission: transactionUpdate.price_offer * 0.1,
      }));

    // set at 10% commission => to rework
    // setCommissionPercentage(10);
    // setCommissionMoney(transactionUpdate.price_offer * 0.1);
    // setTransactionUpdate((prev) => ({
    //   ...prev,
    //   commission: transactionUpdate.price_offer * 0.1,
    // }));
  };

  // const handleMoneyChange = (e) => {
  //   const newCommission = e.target.value.replace(/[^0-9]/g, '');
  //   setCommissionMoney(newCommission);
  //   const newRate = (newCommission / transactionUpdate.price_offer) * 100;
  //   setCommissionPercentage(newRate.toFixed(4));
  //   setTransactionUpdate((prev) => ({ ...prev, commission: newCommission }));
  // };

  // const handlePercentageChange = (e) => {
  //   const newRate = e.target.value;
  //   setCommissionPercentage(newRate);
  //   const newCommission = (newRate / 100) * transactionUpdate.price_offer;
  //   setCommissionMoney(newCommission.toFixed(0));
  //   setTransactionUpdate((prev) => ({ ...prev, commission: newCommission }));
  // };

  const [isDisplay, setIsDisplay] = useState({
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
    7: true,
    8: true,
    9: true,
    10: true,
    11: true,
    12: true,
    13: true,
  });
  const handleHeader = (id) => {
    setIsDisplay({ ...isDisplay, [`${id}`]: !isDisplay[`${id}`] });
  };

  const [show, setShow] = useState(false);
  const [bgToast, setBGToast] = useState('danger');
  const [textToast, setTextToast] = useState('Message');
  // Valid button to save data in db
  const handleSave = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      if (JSON.stringify(boat) !== JSON.stringify(boatUpdate)) {
        await putDataID(API_ROUTES.BOATS.PUT.DETAILS, boatUpdate, id);
        if (boat.price !== boatUpdate.price) {
          const data = {
            id_events: 3,
            date: new Date(),
            description: `Price update from ${boat.price} to ${boatUpdate.price}`,
          };
          await postDataID(API_ROUTES.BOATS.POST.EVENTS, data, id);
        }
      }
      if (!compareArrays(enginesDefault, enginesUpdate)) {
        await putDataID(API_ROUTES.BOATS.PUT.ENGINE, enginesUpdate, id);
      }
      if (addEngine.length > 0) {
        const response = await postDataID(
          API_ROUTES.BOATS.POST.ENGINE,
          addEngine,
          id,
        );
        response.status === 200 && setAddEngine([]);
      }
      if (deleteEngines.length > 0) {
        const params = new URLSearchParams();
        deleteEngines.forEach((item) => params.append('id', item));
        const response = await deleteDataID(
          API_ROUTES.BOATS.DELETE.ENGINE,
          id,
          params,
        );
        response.status === 200 && setDeleteEngines([]);
      }
      if (
        JSON.stringify(maneuverOptions) !==
        JSON.stringify(maneuverOptionsUpdate)
      ) {
        if (boatUpdate.id_maneuver_options) {
          await putDataID(
            API_ROUTES.BOATS.PUT.MANEUVER_OPTIONS,
            maneuverOptionsUpdate,
            id,
          );
        } else {
          await postDataID(
            API_ROUTES.BOATS.POST.MANEUVER_OPTIONS,
            maneuverOptionsUpdate,
            id,
          );
        }
      }
      if (!compareArrays(batteries, batteriesUpdate)) {
        await putDataID(API_ROUTES.BOATS.PUT.BATTERIES, batteriesUpdate, id);
      }
      if (addBattery.length > 0) {
        const response = await postDataID(
          API_ROUTES.BOATS.POST.BATTERIES,
          addBattery,
          id,
        );
        response.status === 200 && setAddBattery([]);
      }
      if (deleteBatteries.length > 0) {
        const params = new URLSearchParams();
        deleteBatteries.forEach((item) => params.append('id', item));
        const response = await deleteDataID(
          API_ROUTES.BOATS.DELETE.BATTERIES,
          id,
          params,
        );
        response.status === 200 && setDeleteBatteries([]);
      }
      if (!compareArrays(generators, generatorsUpdate)) {
        await putDataID(API_ROUTES.BOATS.PUT.GENERATORS, generatorsUpdate, id);
      }
      if (addGenerator.length > 0) {
        const response = await postDataID(
          API_ROUTES.BOATS.POST.GENERATORS,
          addGenerator,
          id,
        );
        response.status === 200 && setAddGenerator([]);
      }
      if (deleteGenerators.length > 0) {
        const params = new URLSearchParams();
        deleteGenerators.forEach((item) => params.append('id', item));
        const response = await deleteDataID(
          API_ROUTES.BOATS.DELETE.GENERATORS,
          id,
          params,
        );
        response.status === 200 && setDeleteGenerators([]);
      }
      if (JSON.stringify(solarPanel) !== JSON.stringify(solarPanelUpdate)) {
        solarPanelUpdate.id
          ? await putDataID(
              API_ROUTES.BOATS.PUT.SOLAR_PANELS,
              solarPanelUpdate,
              id,
            )
          : await postDataID(
              API_ROUTES.BOATS.POST.SOLAR_PANELS,
              solarPanelUpdate,
              id,
            );
      }
      if (JSON.stringify(windturbine) !== JSON.stringify(windturbineUpdate)) {
        windturbineUpdate.id
          ? await putDataID(
              API_ROUTES.BOATS.PUT.WIND_TURBINES,
              windturbineUpdate,
              id,
            )
          : await postDataID(
              API_ROUTES.BOATS.POST.WIND_TURBINES,
              windturbineUpdate,
              id,
            );
      }
      if (JSON.stringify(electronic) !== JSON.stringify(electronicUpdate)) {
        if (boatUpdate.id_electronic) {
          await putDataID(
            API_ROUTES.BOATS.PUT.ELECTRONICS,
            electronicUpdate,
            id,
          );
        } else {
          await postDataID(
            API_ROUTES.BOATS.POST.ELECTRONICS,
            electronicUpdate,
            id,
          );
        }
      }
      if (!compareArrays(tanksDefault, tanksUpdate)) {
        await putDataID(API_ROUTES.BOATS.PUT.TANKS, tanksUpdate, id);
      }
      if (addTanks.length > 0) {
        const response = await postDataID(
          API_ROUTES.BOATS.POST.TANKS,
          addTanks,
          id,
        );
        response.status === 200 && setAddTanks([]);
      }
      if (deleteTanks.length > 0) {
        const params = new URLSearchParams();
        deleteTanks.forEach((item) => params.append('id', item));
        const response = await deleteDataID(
          API_ROUTES.BOATS.DELETE.TANKS,
          id,
          params,
        );
        response.status === 200 && setDeleteTanks([]);
      }
      if (JSON.stringify(comfort) !== JSON.stringify(comfortUpdate)) {
        if (boatUpdate.id_comfort) {
          await putDataID(API_ROUTES.BOATS.PUT.COMFORT, comfortUpdate, id);
        } else {
          await postDataID(API_ROUTES.BOATS.POST.COMFORT, comfortUpdate, id);
        }
      }
      if (!compareArrays(cabinsDefault, cabinsUpdate)) {
        await putDataID(API_ROUTES.BOATS.PUT.CABINS, cabinsUpdate, id);
      }
      if (addCabins.length > 0) {
        const response = await postDataID(
          API_ROUTES.BOATS.POST.CABINS,
          addCabins,
          id,
        );
        response.status === 200 && setAddCabins([]);
      }
      if (deleteCabins.length > 0) {
        const params = new URLSearchParams();
        deleteCabins.forEach((item) => params.append('id', item));
        const response = await deleteDataID(
          API_ROUTES.BOATS.DELETE.CABINS,
          id,
          params,
        );
        response.status === 200 && setDeleteCabins([]);
      }
      if (!compareArrays(kitchensDefault, kitchensUpdate)) {
        await putDataID(API_ROUTES.BOATS.PUT.KITCHENS, kitchensUpdate, id);
      }
      if (addKitchens.length > 0) {
        const response = await postDataID(
          API_ROUTES.BOATS.POST.KITCHENS,
          addKitchens,
          id,
        );
        response.status === 200 && setAddKitchens([]);
      }
      if (deleteKitchens.length > 0) {
        const params = new URLSearchParams();
        deleteKitchens.forEach((item) => params.append('id', item));
        const response = await deleteDataID(
          API_ROUTES.BOATS.DELETE.KITCHENS,
          id,
          params,
        );
        response.status === 200 && setDeleteKitchens([]);
      }
      if (!compareArrays(bathroomDefault, bathroomUpdate)) {
        await putDataID(API_ROUTES.BOATS.PUT.BATHROOMS, bathroomUpdate, id);
      }
      if (addBathrooms.length > 0) {
        const response = await postDataID(
          API_ROUTES.BOATS.POST.BATHROOMS,
          addBathrooms,
          id,
        );
        response.status === 200 && setAddBathrooms([]);
      }
      if (deleteBathrooms.length > 0) {
        const params = new URLSearchParams();
        deleteBathrooms.forEach((item) => params.append('id', item));
        const response = await deleteDataID(
          API_ROUTES.BOATS.DELETE.BATHROOMS,
          id,
          params,
        );
        response.status === 200 && setDeleteBathrooms([]);
      }
      if (JSON.stringify(cover) !== JSON.stringify(coverUpdate)) {
        if (boatUpdate.id_cover) {
          await putDataID(API_ROUTES.BOATS.PUT.COVER, coverUpdate, id);
        } else {
          await postDataID(API_ROUTES.BOATS.POST.COVER, coverUpdate, id);
        }
      }
      if (!compareArrays(sailsDefault, sailsUpdate)) {
        await putDataID(API_ROUTES.BOATS.PUT.SAILS, sailsUpdate, id);
      }
      if (addSails.length > 0) {
        const response = await postDataID(
          API_ROUTES.BOATS.POST.SAILS,
          addSails,
          id,
        );
        response.status === 200 && setAddSails([]);
      }
      if (deleteSails.length > 0) {
        const params = new URLSearchParams();
        deleteSails.forEach((item) => params.append('id', item));
        const response = await deleteDataID(
          API_ROUTES.BOATS.DELETE.SAILS,
          id,
          params,
        );
        response.status === 200 && setDeleteSails([]);
      }
      if (JSON.stringify(rigging) !== JSON.stringify(riggingUpdate)) {
        if (boatUpdate.id_rigging) {
          await putDataID(API_ROUTES.BOATS.PUT.RIGGINGS, riggingUpdate, id);
        } else {
          await postDataID(API_ROUTES.BOATS.POST.RIGGINGS, riggingUpdate, id);
        }
      }
      if (JSON.stringify(fittings) !== JSON.stringify(fittingsUpdate)) {
        if (boatUpdate.id_fittings) {
          await putDataID(API_ROUTES.BOATS.PUT.FITTINGS, fittingsUpdate, id);
        } else {
          await postDataID(API_ROUTES.BOATS.POST.FITTINGS, fittingsUpdate, id);
        }
      }
      if (JSON.stringify(equipments) !== JSON.stringify(equipmentsUpdate)) {
        if (boatUpdate.id_equipment) {
          await putDataID(
            API_ROUTES.BOATS.PUT.EQUIPMENTS,
            equipmentsUpdate,
            id,
          );
        } else {
          await postDataID(
            API_ROUTES.BOATS.POST.EQUIPMENTS,
            equipmentsUpdate,
            id,
          );
        }
      }
      if (JSON.stringify(securityDefault) !== JSON.stringify(securityUpdate)) {
        if (boatUpdate.id_security) {
          await putDataID(API_ROUTES.BOATS.PUT.SECURITY, securityUpdate, id);
        } else {
          await postDataID(API_ROUTES.BOATS.POST.SECURITY, securityUpdate, id);
        }
      }
      if (!compareArrays(securityCertifDefault, securityCertifUpdate)) {
        await putDataID(
          API_ROUTES.BOATS.PUT.SECURITY_CATEGORY,
          securityCertifUpdate,
          id,
        );
      }
      if (addCertif.length > 0) {
        const response = await postDataID(
          API_ROUTES.BOATS.POST.SECURITY_CATEGORY,
          addCertif,
          id,
        );
        response.status === 200 && setAddCertif([]);
      }
      if (delCertif.length > 0) {
        const params = new URLSearchParams();
        delCertif.forEach((item) => params.append('id', item));
        const response = await deleteDataID(
          API_ROUTES.BOATS.DELETE.SECURITY_CATEGORY,
          id,
          params,
        );
        response.status === 200 && setDelCertif([]);
      }
      if (JSON.stringify(ads) !== JSON.stringify(adsUpdate)) {
        if (boatUpdate.id_ads) {
          await putDataID(API_ROUTES.BOATS.PUT.ADS, adsUpdate, id);
        } else {
          await postDataID(API_ROUTES.BOATS.POST.ADS, adsUpdate, id);
        }
      }
      if (addingOwner.length > 0) {
        await postDataID(API_ROUTES.BOATS.POST.PEOPLE, addingOwner, id);
      }
      if (addingLead.length > 0) {
        await postDataID(API_ROUTES.BOATS.POST.PEOPLE, addingLead, id);
      }
      if (deletedPeople.length > 0) {
        const params = new URLSearchParams();
        deletedPeople.forEach((item) => params.append('id', item));
        await deleteDataID(API_ROUTES.BOATS.DELETE.PEOPLE, id, params);
      }
      if (!compareArrays(peopleDefault, people)) {
        await putDataID(API_ROUTES.BOATS.PUT.PEOPLE, people, id);
      }
      if (!transaction && transactionUpdate) {
        transactionUpdate.id_user = JSON.parse(
          localStorage.getItem('ids'),
        ).user;
        transactionUpdate.buyers = buyerList;
        transactionUpdate.sellers = person
          .map((pers) => {
            const boatInfo = people?.find((pb) => pb.id_people === pers.id);
            return {
              ...pers,
              status: boatInfo.id_status || null,
              shares: boatInfo.shares || null,
              contact: boatInfo.contact || 0,
              commission: boatInfo.commission / 100 || null,
            };
          })
          .filter((x) => x.status === 1);
        await postDataID(
          API_ROUTES.TRANSACTIONS.POST.CREATE,
          transactionUpdate,
          id,
        );
      }
      if (
        transaction &&
        JSON.stringify(transaction) !== JSON.stringify(transactionUpdate)
      ) {
        await putDataID(
          API_ROUTES.TRANSACTIONS.PUT.UPDATE,
          transactionUpdate,
          transactionUpdate.id,
        );
      }

      setBGToast('success');
      setTextToast('Successfully saved!');
      setShow(true);
      setSaving(false);
      window.location.reload(); // DELETE THIS => reload component
    } catch (error) {
      if (error?.response?.status === 403) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        navigate('/login'); // redirect to login page if error
      } else {
        console.log('index boat handlesave L1000 error: ', error);
        setBGToast('danger');
        setTextToast('ERROR: Failed saved.');
        setShow(true);
      }
      setSaving(false);
    }
  };
  // cancel button to reset value
  const handleCancel = () => {
    setBoatUpdate(boat);
    setDeleteEngines([]);
    setAddEngine([]);
    setEnginesUpdate(enginesDefault);
    setManeuverOptionsUpdate(maneuverOptions);
    setBatteriesUpdate(batteries);
    setAddBattery([]);
    setDeleteBatteries([]);
    setGeneratorsUpdate(generators);
    setAddGenerator([]);
    setDeleteGenerators([]);
    setSolarPanelUpdate(solarPanel);
    setWindturbineUpdate(windturbine);
    setElectronicUpdate(electronic);
    setTanksUpdate(tanksDefault);
    setAddTanks([]);
    setDeleteTanks([]);
    setComfortUpdate(comfort);
    setCabinsUpdate(cabinsDefault);
    setAddCabins([]);
    setDeleteCabins([]);
    setKitchensUpdate(kitchensDefault);
    setAddKitchens([]);
    setDeleteKitchens([]);
    setBathroomUpdate(bathroomDefault);
    setAddBathrooms([]);
    setDeleteBathrooms([]);
    setCoverUpdate(cover);
    setSailsUpdate(sailsDefault);
    setAddSails([]);
    setDeleteSails([]);
    setRiggingUpdate(rigging);
    setFittingsUpdate(fittings);
    setSecurityUpdate(securityDefault);
    setSecurityCertifUpdate(securityCertifDefault);
    setEquipmentsUpdate(equipments);
    setAddCertif([]);
    setDelCertif([]);
    setAddingOwner([]);
    setAddingLead([]);
    setPerson(personDefault);
    setPeople(peopleDefault);
    setDeletedPeople([]);
    setTransactionUpdate(transaction);
    if (transaction) {
      setCommissionMoney(transaction.commission);
      setCommissionPercentage(
        ((transaction.commission / transaction.price_offer) * 100)
          .toFixed(4)
          .toString()
          .replace(/\.00$/, ''),
      );
    }
    if (!transaction) {
      setBuyerList([]);
    }
  };

  return (
    <div className="boat">
      {displayBoat ? (
        <React.Fragment>
          <section className="boat__carousel">
            <Carousel id={id} user={user} />
          </section>
          <Accordion
            as={Form}
            id="form"
            onSubmit={(e) => handleSave(e)}
            onReset={handleCancel}
            alwaysOpen
            className="boat__accordion"
          >
            {details ? (
              <Accordion.Item eventKey="0">
                <Accordion.Header onClick={() => handleHeader(0)} as="div">
                  <section className="accordion__specifications">
                    <div className="specifications__info">
                      <span className="info__strong">
                        {details.brand} - {details.model}
                      </span>
                      <span className="info__status">
                        {details.name && `"${details.name}" - `}
                        {t('pages.boat.display.details.status')}
                        {details.status}
                      </span>
                    </div>
                    <hr />
                    {isDisplay['0'] && (
                      <React.Fragment>
                        <Row>
                          <span className="col-4">
                            {t('pages.boat.display.details.year')}
                            {details.year}
                          </span>
                          <span className="col-4">
                            {t('pages.boat.display.details.typology')}
                            {details.typology}
                          </span>
                          <span className="col-4">
                            {t('pages.boat.display.details.flag')}
                            {details.flag}
                          </span>
                        </Row>
                        <Row>
                          <div className="col-4">
                            {details.draft > 0 && (
                              <span className="specifications__measures">
                                {t('pages.boat.display.details.draft')}
                                {new Intl.NumberFormat(`${details.locale}`, {
                                  style: 'unit',
                                  unit: 'meter',
                                }).format(details.draft / 100)}
                              </span>
                            )}
                            {details.air_draft > 0 && (
                              <span className="specifications__measures">
                                {t('pages.boat.display.details.airDraft')}
                                {new Intl.NumberFormat(`${details.locale}`, {
                                  style: 'unit',
                                  unit: 'meter',
                                }).format(details.air_draft / 100)}
                              </span>
                            )}
                          </div>
                          <div className="col-4">
                            {details.length > 0 && (
                              <span className="specifications__measures">
                                {t('pages.boat.display.details.length')}
                                {new Intl.NumberFormat(`${details.locale}`, {
                                  style: 'unit',
                                  unit: 'meter',
                                }).format(details.act_length / 100)}
                              </span>
                            )}
                            {details.act_width > 0 && (
                              <span className="specifications__measures">
                                {t('pages.boat.display.details.width')}
                                {new Intl.NumberFormat(`${details.locale}`, {
                                  style: 'unit',
                                  unit: 'meter',
                                }).format(details.act_width / 100)}
                              </span>
                            )}
                          </div>
                          <div className="col-4">
                            {details.adm_length > 0 && (
                              <span className="specifications__measures">
                                {t('pages.boat.display.details.admLength')}
                                {new Intl.NumberFormat(`${details.locale}`, {
                                  style: 'unit',
                                  unit: 'meter',
                                }).format(details.adm_length / 100)}
                              </span>
                            )}
                            {details.adm_width > 0 && (
                              <span className="specifications__measures">
                                {t('pages.boat.display.details.admWidth')}
                                {new Intl.NumberFormat(`${details.locale}`, {
                                  style: 'unit',
                                  unit: 'meter',
                                }).format(details.adm_width / 100)}
                              </span>
                            )}
                          </div>
                        </Row>
                        <Row>
                          <span className="col-4">
                            {details.headroom &&
                              `${t(
                                'pages.boat.display.details.headroom',
                              )}${new Intl.NumberFormat(`${details.locale}`, {
                                style: 'unit',
                                unit: 'meter',
                              }).format(details.headroom / 100)}`}
                          </span>
                          <span className="col-4">
                            {details.weight &&
                              `${t(
                                'pages.boat.display.details.weight',
                              )}${new Intl.NumberFormat(`${details.locale}`, {
                                style: 'unit',
                                unit: 'kilogram',
                              }).format(details.weight)}`}
                          </span>
                          <span className="col-4">
                            {details &&
                              enginesDisplay &&
                              `${t(
                                'pages.boat.display.details.taxNav',
                              )}${new Intl.NumberFormat(`${details.locale}`, {
                                style: 'currency',
                                currency: `${
                                  CURRENCIES.find(
                                    (currency) =>
                                      currency.locale === details.locale,
                                  ).code
                                }`,
                                maximumFractionDigits: 0,
                              }).format(
                                dafn(
                                  details.typology,
                                  details.year,
                                  enginesDisplay,
                                  details.adm_length / 100,
                                ),
                              )}`}
                          </span>
                        </Row>
                        <Row>
                          <span className="col-4">
                            {details.keel &&
                              `${t('pages.boat.display.details.keel')}${
                                details.keel
                              }`}
                          </span>
                          <span className="col-2">
                            {t('pages.boat.display.details.berthPlace')}
                            {details.berth_place
                              ? `${t('true')}`
                              : `${t('false')}`}
                          </span>
                          <span className="col-6">
                            {details?.berth_place_description}
                          </span>
                        </Row>
                        <Row>
                          <span className="col-6">
                            {details.town &&
                              `${t('pages.boat.display.details.town')}${
                                details.town
                              }`}
                          </span>
                          <span className="col-6">
                            {details.country &&
                              `${t('pages.boat.display.details.country')}${
                                details.country
                              }`}
                          </span>
                        </Row>
                        <Row>
                          <span className="col-4">
                            {details.serial_number &&
                              `${t('pages.boat.display.details.serialNumber')}${
                                details.serial_number
                              }`}
                          </span>
                          <span className="col-4">
                            {details.registration &&
                              `${t('pages.boat.display.details.registration')}${
                                details.registration
                              }`}
                          </span>
                        </Row>
                        <hr />
                        <div className="specifications__info">
                          <span className="info__strong">
                            {t('pages.boat.display.details.price')}
                            {new Intl.NumberFormat(`${details.locale}`, {
                              style: 'currency',
                              currency: `${
                                CURRENCIES.find(
                                  (currency) =>
                                    currency.locale === details.locale,
                                ).code
                              }`,
                              maximumFractionDigits: 0,
                            }).format(details.price)}{' '}
                            {details.ttc
                              ? `${t('pages.boat.display.details.ttc')}`
                              : `${t('pages.boat.display.details.ht')}`}
                          </span>
                        </div>
                      </React.Fragment>
                    )}
                  </section>
                </Accordion.Header>
                {user && boat && models && brands && (
                  <Accordion.Body className="accordion__update">
                    <Row className="mb-4">
                      <Form.Group as={Col} md={2}>
                        {brands.length > 0 && models.length > 0 ? (
                          <Form.Control
                            placeholder={t('pages.boat.edit.details.brand')}
                            defaultValue={
                              brands?.find(
                                (x) =>
                                  x.id ===
                                  models?.find((y) => y.id === boat?.id_model)
                                    ?.id_brand,
                              )?.value
                            }
                            onChange={(e) =>
                              setBoatUpdate({
                                ...boatUpdate,
                                brand: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <span>Loading</span>
                        )}
                      </Form.Group>
                      <Form.Group as={Col}>
                        {boat && models.length > 0 ? (
                          <Form.Control
                            placeholder={t('pages.boat.edit.details.model')}
                            defaultValue={
                              models?.find((y) => y.id === boat?.id_model)
                                ?.value
                            }
                            onChange={(e) =>
                              setBoatUpdate({
                                ...boatUpdate,
                                model: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <span>Loading</span>
                        )}
                      </Form.Group>
                      <Form.Group as={Col} md={2}>
                        <Form.Control
                          placeholder={t('pages.boat.edit.details.name')}
                          defaultValue={boat.name}
                          onChange={(e) => {
                            setBoatUpdate({
                              ...boatUpdate,
                              name: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md={2}>
                        <Form.Select
                          value={boatUpdate.id_status}
                          onChange={(e) => {
                            setBoatUpdate({
                              ...boatUpdate,
                              id_status: e.target.value,
                            });
                          }}
                        >
                          {status.map((element, index) => {
                            return (
                              <option key={index} value={element.id}>
                                {t(element.tag)}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Row>
                    <hr />
                    <Row className="mb-3">
                      <Form.Group as={Col} className="update__label">
                        <Form.Label column md={2}>
                          {t('pages.boat.edit.details.year')}
                        </Form.Label>
                        <Form.Control
                          placeholder={t('pages.boat.edit.details.year')}
                          value={boatUpdate.year}
                          maxLength={4}
                          onChange={(e) => {
                            setBoatUpdate({
                              ...boatUpdate,
                              year: e.target.value.replace(/[^0-9]/g, ''),
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Select
                          value={boatUpdate.id_typology}
                          onChange={(e) => {
                            setBoatUpdate({
                              ...boatUpdate,
                              id_typology: e.target.value,
                            });
                          }}
                        >
                          {typologies.map((element, index) => {
                            return (
                              <option key={index} value={element.id}>
                                {t(element.tag)}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Select
                          value={boatUpdate.id_flag}
                          onChange={(e) => {
                            setBoatUpdate({
                              ...boatUpdate,
                              id_flag: e.target.value,
                            });
                          }}
                        >
                          {flags.map((element, index) => {
                            return (
                              <option key={index} value={element.id}>
                                {t(element.tag)}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} className="update__label">
                        <Form.Label column md={2}>
                          {t('pages.boat.edit.details.draft')}
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder={t('pages.boat.edit.details.draft')}
                            value={boatUpdate.draft / 100}
                            onChange={(e) => {
                              setBoatUpdate({
                                ...boatUpdate,
                                draft: e.target.value * 100,
                              });
                            }}
                          />
                          <InputGroup.Text>m</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} className="update__label">
                        <Form.Label column md={2}>
                          {t('pages.boat.edit.details.length')}
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder={t('pages.boat.edit.details.length')}
                            defaultValue={
                              details.act_length / 100 !== 0
                                ? details.act_length / 100
                                : null
                            }
                            onChange={(e) => {
                              setBoatUpdate({
                                ...boatUpdate,
                                act_length: e.target.value * 100,
                              });
                            }}
                          />
                          <InputGroup.Text>m</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} className="update__label">
                        <Form.Label column md={5}>
                          {t('pages.boat.edit.details.admLength')}
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder={t('pages.boat.edit.details.admLength')}
                            defaultValue={
                              details.adm_length / 100 !== 0
                                ? details.adm_length / 100
                                : null
                            }
                            onChange={(e) => {
                              setBoatUpdate({
                                ...boatUpdate,
                                adm_length: e.target.value * 100,
                              });
                            }}
                          />
                          <InputGroup.Text>m</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} className="update__label">
                        <Form.Label column md={3}>
                          {t('pages.boat.edit.details.airDraft')}
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder={t('pages.boat.edit.details.airDraft')}
                            defaultValue={
                              details.air_draft / 100 !== 0
                                ? details.air_draft / 100
                                : null
                            }
                            onChange={(e) => {
                              setBoatUpdate({
                                ...boatUpdate,
                                air_draft: e.target.value * 100,
                              });
                            }}
                          />
                          <InputGroup.Text>m</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} className="update__label">
                        <Form.Label column md={2}>
                          {t('pages.boat.edit.details.width')}
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder={t('pages.boat.edit.details.width')}
                            defaultValue={
                              details.act_width / 100 !== 0
                                ? details.act_width / 100
                                : null
                            }
                            onChange={(e) => {
                              setBoatUpdate({
                                ...boatUpdate,
                                act_width: e.target.value * 100,
                              });
                            }}
                          />
                          <InputGroup.Text>m</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} className="update__label">
                        <Form.Label column md={5}>
                          {t('pages.boat.edit.details.admWidth')}
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder={t('pages.boat.edit.details.admWidth')}
                            defaultValue={
                              details.adm_width / 100 !== 0
                                ? details.adm_width / 100
                                : null
                            }
                            onChange={(e) => {
                              setBoatUpdate({
                                ...boatUpdate,
                                adm_width: e.target.value * 100,
                              });
                            }}
                          />
                          <InputGroup.Text>m</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} className="update__label">
                        <Form.Label column md={3}>
                          {t('pages.boat.edit.details.headroom')}
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder={t('pages.boat.edit.details.headroom')}
                            defaultValue={
                              details.headroom / 100 !== 0
                                ? details.headroom / 100
                                : null
                            }
                            onChange={(e) => {
                              setBoatUpdate({
                                ...boatUpdate,
                                headroom: e.target.value * 100,
                              });
                            }}
                          />
                          <InputGroup.Text>m</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} className="update__label">
                        <Form.Label column md={2}>
                          {t('pages.boat.edit.details.weight')}
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder={t('pages.boat.edit.details.weight')}
                            defaultValue={details.weight}
                            onChange={(e) => {
                              setBoatUpdate({
                                ...boatUpdate,
                                weight: e.target.value,
                              });
                            }}
                          />
                          <InputGroup.Text>kg</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} className="update__label">
                        <Form.Label column md={5}>
                          {t('pages.boat.edit.details.taxNav')}
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder={t('pages.boat.edit.details.taxNav')}
                            defaultValue={
                              details && enginesDisplay
                                ? dafn(
                                    details.typology,
                                    details.year,
                                    enginesDisplay,
                                    details.adm_length / 100,
                                  )
                                : 0
                            }
                            readOnly
                          />
                          <InputGroup.Text>€</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} className="update__label">
                        <Form.Select
                          defaultValue={boatUpdate?.id_keel}
                          onChange={(e) => {
                            setBoatUpdate({
                              ...boatUpdate,
                              id_keel: e.target.value,
                            });
                          }}
                        >
                          <option value={null}>
                            {t('pages.boat.edit.details.none')}
                          </option>
                          {keels.map((element, index) => {
                            return (
                              <option key={index} value={element.id}>
                                {t(element.tag)}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group as={Col} className="update__label" md={8}>
                        <Form.Check
                          type="checkbox"
                          label={t('pages.boat.edit.details.berthPlace')}
                          checked={boatUpdate?.berth_place || false}
                          onChange={(e) =>
                            setBoatUpdate({
                              ...boatUpdate,
                              berth_place: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                        <Form.Control
                          as="textarea"
                          rows={1}
                          disabled={!boatUpdate?.berth_place}
                          defaultValue={boatUpdate?.berth_place_description}
                          onChange={(e) =>
                            setBoatUpdate({
                              ...boatUpdate,
                              berth_place_description: e.target.value,
                            })
                          }
                          className="container__description"
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} className="update__label" md={8}>
                        <Form.Control
                          as="textarea"
                          placeholder={t('pages.boat.edit.details.town')}
                          rows={1}
                          defaultValue={boatUpdate?.town}
                          onChange={(e) =>
                            setBoatUpdate({
                              ...boatUpdate,
                              town: e.target.value,
                            })
                          }
                          className="container__description"
                        />
                        <Form.Control
                          as="textarea"
                          placeholder={t('pages.boat.edit.details.country')}
                          rows={1}
                          defaultValue={boatUpdate?.country}
                          onChange={(e) =>
                            setBoatUpdate({
                              ...boatUpdate,
                              country: e.target.value,
                            })
                          }
                          className="container__description"
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} className="update__label">
                        <Form.Label column md={4}>
                          {t('pages.boat.edit.details.serialNumber')}
                        </Form.Label>
                        <Form.Control
                          placeholder={t(
                            'pages.boat.edit.details.serialNumber',
                          )}
                          defaultValue={
                            details.serial_number !== 0
                              ? details.serial_number
                              : null
                          }
                          onChange={(e) => {
                            setBoatUpdate({
                              ...boatUpdate,
                              serial_number: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="update__label">
                        <Form.Label column md={4}>
                          {t('pages.boat.edit.details.registration')}
                        </Form.Label>
                        <Form.Control
                          placeholder={t(
                            'pages.boat.edit.details.registration',
                          )}
                          defaultValue={
                            details.registration !== 0
                              ? details.registration
                              : null
                          }
                          onChange={(e) => {
                            setBoatUpdate({
                              ...boatUpdate,
                              registration: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    </Row>
                    <hr />
                    <Row>
                      <Form.Group as={Col} className="update__label">
                        <Form.Label>
                          {t('pages.boat.edit.details.price')}
                        </Form.Label>
                        <Form.Control
                          placeholder={t('pages.boat.edit.details.price')}
                          defaultValue={details.price}
                          onChange={(e) => {
                            setBoatUpdate({
                              ...boatUpdate,
                              price: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="update__label">
                        <Dropdown
                          onSelect={(eventKey) => {
                            setBoatUpdate({
                              ...boatUpdate,
                              locale: eventKey,
                            });
                          }}
                          className="update__label__currencies"
                        >
                          <Dropdown.Toggle id="dropdown-currency">
                            <span
                              className={`fi fi-${boatUpdate.locale
                                .split('-')[1]
                                .toLowerCase()} update__label__currencies__menu__flag`}
                            ></span>
                            {boatUpdate.locale
                              ? `${
                                  CURRENCIES.filter(
                                    (item) => item.locale === boatUpdate.locale,
                                  )[0].code
                                } | ${
                                  CURRENCIES.filter(
                                    (item) => item.locale === boatUpdate.locale,
                                  )[0].country
                                } - ${
                                  CURRENCIES.filter(
                                    (item) => item.locale === boatUpdate.locale,
                                  )[0].name
                                }`
                              : 'Select currency*'}
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="update__label__currencies__menu">
                            {CURRENCIES.map((item, index) => {
                              const countryCode = item.locale
                                .split('-')[1]
                                .toLowerCase();
                              return (
                                <Dropdown.Item
                                  key={index}
                                  eventKey={item.locale}
                                >
                                  <span
                                    className={`fi fi-${countryCode} update__label__currencies__menu__flag`}
                                  ></span>
                                  {item.code} | {item.country} - {item.name}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Form.Group>
                      <Form.Group as={Col} className="update__label">
                        <Form.Check
                          type="checkbox"
                          label={t('pages.boat.edit.details.ttc')}
                          checked={boatUpdate?.ttc || false}
                          onChange={(e) =>
                            setBoatUpdate({
                              ...boatUpdate,
                              ttc: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                      </Form.Group>
                    </Row>
                  </Accordion.Body>
                )}
              </Accordion.Item>
            ) : (
              <span>Loading...</span>
            )}
            <Accordion.Item eventKey="1">
              <Accordion.Header onClick={() => handleHeader(1)} as="div">
                <section className="accordion__header">
                  <span className="accordion__title">
                    {t('pages.boat.display.engines.engines')}
                  </span>
                  <hr />
                  {isDisplay['1'] && (
                    <React.Fragment>
                      <div className="header__container">
                        {enginesDisplay &&
                          enginesDisplay.map((element, index) => {
                            return (
                              <Row key={index}>
                                <span className="accordion__subtitle">
                                  {t('pages.boat.display.engines.engine')}
                                  {index + 1}
                                </span>
                                <Row>
                                  <span className="col-6">
                                    {t('pages.boat.display.engines.brand')}
                                    {element.brand}
                                  </span>
                                  <span className="col-6">
                                    {element.model &&
                                      `${t(
                                        'pages.boat.display.engines.model',
                                      )}${element.model}`}
                                  </span>
                                </Row>
                                <Row>
                                  <span className="col-6">
                                    {t('pages.boat.display.engines.power')}
                                    {element.power} CV
                                  </span>
                                  <span className="col-6">
                                    {t(
                                      'pages.boat.display.engines.fiscalPower',
                                    )}
                                    {element.fiscal_power} CV
                                  </span>
                                </Row>
                                <Row>
                                  <span className="col-6">
                                    {element.hours > 0 &&
                                      `${t(
                                        'pages.boat.display.engines.hours',
                                      )}${new Intl.NumberFormat('fr-FR', {
                                        style: 'unit',
                                        unit: 'hour',
                                      }).format(element.hours)}`}
                                  </span>
                                  {element.transmission !== 'None' && (
                                    <span className="col-6">
                                      {t(
                                        'pages.boat.display.engines.transmission',
                                      )}
                                      {element.transmission}
                                    </span>
                                  )}
                                </Row>
                                <Row>
                                  <span>
                                    {element.serial_number &&
                                      `${t(
                                        'pages.boat.display.engines.serialNumber',
                                      )}${element.serial_number}`}
                                  </span>
                                </Row>
                              </Row>
                            );
                          })}
                      </div>
                      {maneuverOptions && (
                        <Row>
                          <span className="accordion__subtitle">
                            {t('pages.boat.display.maneuverOptions.title')}
                          </span>
                          <Row>
                            <span className="col-4">
                              {t(
                                'pages.boat.display.maneuverOptions.bowThruster',
                              )}
                              {maneuverOptions?.bow_thruster ? 'Yes' : 'No'}
                            </span>
                            {maneuverOptions?.bow_thruster && (
                              <p className="col-8">
                                {maneuverOptions?.bow_thruster_description}
                              </p>
                            )}
                          </Row>
                          <Row>
                            <span className="col-4">
                              {t(
                                'pages.boat.display.maneuverOptions.sternThruster',
                              )}
                              {maneuverOptions?.stern_thruster ? 'Yes' : 'No'}
                            </span>
                            {maneuverOptions?.stern_thruster && (
                              <p className="col-8">
                                {maneuverOptions?.stern_thruster_description}
                              </p>
                            )}
                          </Row>
                          <Row>
                            <span className="col-4">
                              {t('pages.boat.display.maneuverOptions.flaps')}
                              {maneuverOptions?.flaps ? 'Yes' : 'No'}
                            </span>
                            {maneuverOptions?.flaps && (
                              <p className="col-8">
                                {maneuverOptions?.flaps_description}
                              </p>
                            )}
                          </Row>
                          <Row>
                            <span className="col-4">
                              {t('pages.boat.display.maneuverOptions.windlass')}
                              {maneuverOptions?.windlass ? 'Yes' : 'No'}
                            </span>
                            <span className="col-4">
                              {maneuverOptions?.windlass && (
                                <p className="col-4">
                                  {maneuverOptions?.windlass_description}
                                </p>
                              )}
                            </span>
                            <span className="col-4">
                              {maneuverOptions?.windlass && (
                                <span>
                                  {t(
                                    'pages.boat.display.maneuverOptions.electricWindlass',
                                  )}
                                  {maneuverOptions?.electric_windlass
                                    ? 'Yes'
                                    : 'No'}
                                </span>
                              )}
                            </span>
                          </Row>
                          <Row>
                            <span className="col-12">
                              {t(
                                'pages.boat.display.maneuverOptions.yachtController',
                              )}
                              {maneuverOptions?.yacht_controller ? 'Yes' : 'No'}
                            </span>
                          </Row>
                        </Row>
                      )}
                    </React.Fragment>
                  )}
                </section>
              </Accordion.Header>
              {user && (
                <Accordion.Body className="accordion__update">
                  <Row>
                    {enginesUpdate &&
                      enginesUpdate.map((element, index) => {
                        return (
                          <Row key={index}>
                            <Col className="update__canDelete">
                              <span className="accordion__subtitle">
                                {t('pages.boat.edit.engines.engine')}
                                {index + 1}
                              </span>
                              <div className="btn__container">
                                <Button
                                  variant="warning"
                                  onClick={() => duplicateEngine(element.id)}
                                  className="canDelete__button"
                                >
                                  <IoDuplicate />
                                </Button>
                                <Button
                                  variant="danger"
                                  onClick={() => deleteEngine(element.id)}
                                  className="canDelete__button"
                                >
                                  <FaRegTrashAlt />
                                </Button>
                              </div>
                            </Col>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={3}>
                                  {t('pages.boat.edit.engines.brand')}
                                </Form.Label>
                                <Form.Control
                                  placeholder={t(
                                    'pages.boat.edit.engines.brand',
                                  )}
                                  defaultValue={element.brand}
                                  onChange={(e) =>
                                    updateEnginesField(e, index, 'brand')
                                  }
                                />
                              </Form.Group>
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={4}>
                                  {t('pages.boat.edit.engines.model')}
                                </Form.Label>
                                <Form.Control
                                  placeholder={t(
                                    'pages.boat.edit.engines.model',
                                  )}
                                  defaultValue={element.model}
                                  onChange={(e) =>
                                    updateEnginesField(e, index, 'model')
                                  }
                                />
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={3}>
                                  {t('pages.boat.edit.engines.power')}
                                </Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    placeholder={t(
                                      'pages.boat.edit.engines.power',
                                    )}
                                    defaultValue={element.power}
                                    onChange={(e) =>
                                      updateEnginesField(e, index, 'power')
                                    }
                                  />
                                  <InputGroup.Text>CV</InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={4}>
                                  {t('pages.boat.edit.engines.fiscalPower')}
                                </Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    placeholder={t(
                                      'pages.boat.edit.engines.fiscalPower',
                                    )}
                                    defaultValue={element.fiscal_power}
                                    onChange={(e) =>
                                      updateEnginesField(
                                        e,
                                        index,
                                        'fiscal_power',
                                      )
                                    }
                                  />
                                  <InputGroup.Text>CV</InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={3}>
                                  {t('pages.boat.edit.engines.hours')}
                                </Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    placeholder={t(
                                      'pages.boat.edit.engines.hours',
                                    )}
                                    defaultValue={element.hours}
                                    onChange={(e) =>
                                      updateEnginesField(e, index, 'hours')
                                    }
                                  />
                                  <InputGroup.Text>h</InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={4}>
                                  {t('pages.boat.edit.engines.transmission')}
                                </Form.Label>
                                <Form.Select
                                  defaultValue={element.id_transmission}
                                  onChange={(e) =>
                                    updateEnginesField(
                                      e,
                                      index,
                                      'id_transmission',
                                    )
                                  }
                                >
                                  {transmission.map((element, index) => {
                                    return (
                                      <option key={index} value={element.id}>
                                        {t(element.tag)}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={2}>
                                  {t('pages.boat.edit.engines.serialNumber')}
                                </Form.Label>
                                <Form.Control
                                  placeholder={t(
                                    'pages.boat.edit.engines.serialNumber',
                                  )}
                                  defaultValue={element.serial_number}
                                  onChange={(e) =>
                                    updateEnginesField(
                                      e,
                                      index,
                                      'serial_number',
                                    )
                                  }
                                />
                              </Form.Group>
                            </Row>
                          </Row>
                        );
                      })}
                    <Row>
                      {addEngine.map((element, index) => {
                        return (
                          <AddEngine
                            key={index}
                            transmission={transmission}
                            engine={element}
                            deleteItem={deleteObjectEngine}
                            duplicateItem={duplicateNewEngine}
                          />
                        );
                      })}
                      <Button
                        onClick={addNewEngine}
                        className="update__addButton"
                      >
                        <IoMdAddCircleOutline />
                        <span>{t('pages.boat.edit.engines.addNew')}</span>
                      </Button>
                    </Row>
                    <Row>
                      <span className="accordion__subtitle">
                        {t('pages.boat.edit.maneuverOptions.title')}
                      </span>
                      <Form.Group className="category__container mb-3">
                        <Form.Check
                          type="checkbox"
                          label={t(
                            'pages.boat.edit.maneuverOptions.bowThruster',
                          )}
                          checked={maneuverOptionsUpdate?.bow_thruster || false}
                          onChange={(e) =>
                            setManeuverOptionsUpdate({
                              ...maneuverOptionsUpdate,
                              bow_thruster: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                        <Form.Control
                          as="textarea"
                          rows={1}
                          disabled={!maneuverOptionsUpdate?.bow_thruster}
                          value={
                            maneuverOptions?.bow_thruster_description || ''
                          }
                          onChange={(e) =>
                            setManeuverOptionsUpdate({
                              ...maneuverOptionsUpdate,
                              bow_thruster_description: e.target.value,
                            })
                          }
                          className="container__description"
                        />
                      </Form.Group>
                      <Form.Group className="category__container mb-3">
                        <Form.Check
                          type="checkbox"
                          label={t(
                            'pages.boat.edit.maneuverOptions.sternThruster',
                          )}
                          checked={
                            maneuverOptionsUpdate?.stern_thruster || false
                          }
                          onChange={(e) =>
                            setManeuverOptionsUpdate({
                              ...maneuverOptionsUpdate,
                              stern_thruster: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                        <Form.Control
                          as="textarea"
                          rows={1}
                          disabled={!maneuverOptionsUpdate?.stern_thruster}
                          defaultValue={
                            maneuverOptions?.stern_thruster_description
                          }
                          onChange={(e) =>
                            setManeuverOptionsUpdate({
                              ...maneuverOptionsUpdate,
                              stern_thruster_description: e.target.value,
                            })
                          }
                          className="container__description"
                        />
                      </Form.Group>
                      <Form.Group className="category__container mb-3">
                        <Form.Check
                          type="checkbox"
                          label={t('pages.boat.edit.maneuverOptions.flaps')}
                          checked={maneuverOptionsUpdate?.flaps || false}
                          onChange={(e) =>
                            setManeuverOptionsUpdate({
                              ...maneuverOptionsUpdate,
                              flaps: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                        <Form.Control
                          as="textarea"
                          rows={1}
                          disabled={!maneuverOptionsUpdate?.flaps}
                          defaultValue={maneuverOptions?.flaps_description}
                          onChange={(e) =>
                            setManeuverOptionsUpdate({
                              ...maneuverOptionsUpdate,
                              flaps_description: e.target.value,
                            })
                          }
                          className="container__description"
                        />
                      </Form.Group>
                      <Form.Group className="category__container mb-3">
                        <Form.Check
                          type="checkbox"
                          label={t('pages.boat.edit.maneuverOptions.windlass')}
                          checked={maneuverOptionsUpdate?.windlass || false}
                          onChange={(e) =>
                            setManeuverOptionsUpdate({
                              ...maneuverOptionsUpdate,
                              windlass: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                        <Form.Control
                          as="textarea"
                          rows={1}
                          disabled={!maneuverOptionsUpdate?.windlass}
                          defaultValue={maneuverOptions?.windlass_description}
                          onChange={(e) =>
                            setManeuverOptionsUpdate({
                              ...maneuverOptionsUpdate,
                              windlass_description: e.target.value,
                            })
                          }
                          className="container__description"
                        />
                      </Form.Group>
                      <Form.Group className="category__container mb-3">
                        <Form.Check
                          type="checkbox"
                          label={t(
                            'pages.boat.edit.maneuverOptions.electricWindlass',
                          )}
                          checked={
                            maneuverOptionsUpdate?.electric_windlass || false
                          }
                          onChange={(e) =>
                            setManeuverOptionsUpdate({
                              ...maneuverOptionsUpdate,
                              electric_windlass: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                        <Form.Check
                          type="checkbox"
                          label={t(
                            'pages.boat.edit.maneuverOptions.yachtController',
                          )}
                          checked={
                            maneuverOptionsUpdate?.yacht_controller || false
                          }
                          onChange={(e) =>
                            setManeuverOptionsUpdate({
                              ...maneuverOptionsUpdate,
                              yacht_controller: e.target.checked,
                            })
                          }
                        />
                      </Form.Group>
                    </Row>
                  </Row>
                </Accordion.Body>
              )}
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header onClick={() => handleHeader(2)} as="div">
                <section className="accordion__header">
                  <span className="accordion__title">
                    {t('pages.boat.display.electricity.title')}
                  </span>
                  <hr />
                  {isDisplay['2'] && (
                    <React.Fragment>
                      {batteries && (
                        <React.Fragment>
                          <span className="row accordion__subtitle">
                            {t(
                              'pages.boat.display.electricity.batteryPark.title',
                            )}
                          </span>
                          <div className="header__container">
                            {batteries.map((element, index) => {
                              return (
                                <Row key={index}>
                                  <Row>
                                    <span className="col-4">
                                      {t(
                                        'pages.boat.display.electricity.batteryPark.quantity',
                                      )}
                                      {element.quantity}
                                    </span>
                                    <span className="col-4">
                                      {t(
                                        'pages.boat.display.electricity.batteryPark.assignment',
                                      )}
                                      {element.assignment}
                                    </span>
                                    {element.type && (
                                      <span className="col-4">
                                        {t(
                                          'pages.boat.display.electricity.batteryPark.type',
                                        )}
                                        {element.type}
                                      </span>
                                    )}
                                  </Row>
                                  <Row>
                                    <span className="col-4">
                                      {t(
                                        'pages.boat.display.electricity.batteryPark.voltage',
                                      )}
                                      {element.voltage} V
                                    </span>
                                    {element.amperage && (
                                      <span className="col-4">
                                        {t(
                                          'pages.boat.display.electricity.batteryPark.amperage',
                                        )}
                                        {element.amperage} A
                                      </span>
                                    )}
                                  </Row>
                                </Row>
                              );
                            })}
                          </div>
                        </React.Fragment>
                      )}
                      {generators && (
                        <React.Fragment>
                          <span className="row accordion__subtitle">
                            {t(
                              'pages.boat.display.electricity.generators.title',
                            )}
                          </span>
                          <div className="header__container">
                            {generators.map((element, index) => {
                              return (
                                <Row key={index}>
                                  <Row>
                                    <span className="col-6">
                                      {t(
                                        'pages.boat.display.electricity.generators.brand',
                                      )}
                                      {element.brand}
                                    </span>
                                    {element.model && (
                                      <span className="col-6">
                                        {t(
                                          'pages.boat.display.electricity.generators.model',
                                        )}
                                        {element.model}
                                      </span>
                                    )}
                                  </Row>
                                  <Row>
                                    <span className="col-6">
                                      {t(
                                        'pages.boat.display.electricity.generators.power',
                                      )}
                                      {element.power} kW
                                    </span>
                                    {element.hours > 0 && (
                                      <span className="col-6">
                                        {t(
                                          'pages.boat.display.electricity.generators.hours',
                                        )}
                                        {new Intl.NumberFormat('fr-FR', {
                                          style: 'unit',
                                          unit: 'hour',
                                        }).format(element.hours)}
                                      </span>
                                    )}
                                  </Row>
                                  <Row>
                                    {element.serial_number && (
                                      <span>
                                        {t(
                                          'pages.boat.display.electricity.generators.serialNumber',
                                        )}
                                        {element.serial_number}
                                      </span>
                                    )}
                                  </Row>
                                </Row>
                              );
                            })}
                          </div>
                        </React.Fragment>
                      )}
                      {solarPanel && (
                        <div className="header__container">
                          <span className="row accordion__subtitle">
                            {t(
                              'pages.boat.display.electricity.solarPanel.title',
                            )}
                          </span>
                          <Row>
                            <span className="col-4">
                              {t(
                                'pages.boat.display.electricity.solarPanel.power',
                              )}
                              {solarPanel.power}
                            </span>
                            {solarPanel.description && (
                              <span className="col-8">
                                {solarPanel.description}
                              </span>
                            )}
                          </Row>
                        </div>
                      )}
                      {windturbine && (
                        <div className="header__container">
                          <span className="row accordion__subtitle">
                            {t(
                              'pages.boat.display.electricity.windturbine.title',
                            )}
                          </span>
                          <Row>
                            <Row>
                              <span className="col-4">
                                {t(
                                  'pages.boat.display.electricity.windturbine.quantity',
                                )}
                                {windturbine.quantity}
                              </span>
                              <span className="col-4">
                                {t(
                                  'pages.boat.display.electricity.windturbine.totalPower',
                                )}
                                {windturbine.total_power}
                              </span>
                            </Row>
                            {windturbine.description && (
                              <Row>
                                <span className="col-8">
                                  {windturbine.description}
                                </span>
                              </Row>
                            )}
                          </Row>
                        </div>
                      )}
                      <div className="header__container">
                        {electronic && (
                          <Row>
                            <span className="row accordion__subtitle">
                              {t(
                                'pages.boat.display.electricity.electronic.title',
                              )}
                            </span>
                            <Row>
                              <Row>
                                <span className="col-4">
                                  {t(
                                    'pages.boat.display.electricity.electronic.lochSpeedo',
                                  )}
                                  {electronic.loch_speedo ? 'Yes' : 'No'}
                                </span>
                                {electronic.loch_speedo && (
                                  <span className="col-8">
                                    {electronic.loch_speedo_description}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                <span className="col-4">
                                  {t(
                                    'pages.boat.display.electricity.electronic.sounder',
                                  )}
                                  {electronic.sounder ? 'Yes' : 'No'}
                                </span>
                                {electronic.sounder && (
                                  <span className="col-8">
                                    {electronic.sounder_description}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                <span className="col-4">
                                  {t(
                                    'pages.boat.display.electricity.electronic.gps',
                                  )}
                                  {electronic.gps ? 'Yes' : 'No'}
                                </span>
                                {electronic.gps && (
                                  <span className="col-8">
                                    {electronic.gps_description}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                <span className="col-4">
                                  {t(
                                    'pages.boat.display.electricity.electronic.vhf',
                                  )}
                                  {electronic.vhf ? 'Yes' : 'No'}
                                </span>
                                {electronic.vhf && (
                                  <span className="col-8">
                                    {electronic.vhf_description}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                <span className="col-4">
                                  {t(
                                    'pages.boat.display.electricity.electronic.radar',
                                  )}
                                  {electronic.radar ? 'Yes' : 'No'}
                                </span>
                                {electronic.radar && (
                                  <span className="col-8">
                                    {electronic.radar_description}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                <span className="col-4">
                                  {t(
                                    'pages.boat.display.electricity.electronic.ais',
                                  )}
                                  {electronic.ais ? 'Yes' : 'No'}
                                </span>
                                {electronic.ais && (
                                  <span className="col-8">
                                    {electronic.ais_description}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                <span className="col-4">
                                  {t(
                                    'pages.boat.display.electricity.electronic.windvaneAnemometer',
                                  )}
                                  {electronic.windvane_anemometer
                                    ? 'Yes'
                                    : 'No'}
                                </span>
                                {electronic.windvane_anemometer && (
                                  <span className="col-8">
                                    {electronic.windvane_anemometer_description}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                {electronic.battery_charger_power && (
                                  <span className="col-4">
                                    {t(
                                      'pages.boat.display.electricity.electronic.batteryChargerPower',
                                    )}
                                    {electronic.battery_charger_power}
                                  </span>
                                )}
                                {electronic.battery_charger_power && (
                                  <span className="col-8">
                                    {electronic.battery_charger_description}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                <span className="col-4">
                                  {t(
                                    'pages.boat.display.electricity.electronic.autoPilot',
                                  )}
                                  {electronic.auto_pilot ? 'Yes' : 'No'}
                                </span>
                                {electronic.auto_pilot && (
                                  <span className="col-8">
                                    {electronic.auto_pilot_description}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                <span className="col-4">
                                  {t(
                                    'pages.boat.display.electricity.electronic.dockConnector',
                                  )}
                                  {electronic.dock_connector ? 'Yes' : 'No'}
                                </span>
                              </Row>
                            </Row>
                          </Row>
                        )}
                      </div>
                    </React.Fragment>
                  )}
                </section>
              </Accordion.Header>
              {user && (
                <Accordion.Body className="accordion__update">
                  <Row>
                    {batteriesUpdate &&
                      batteriesUpdate.map((element, index) => {
                        return (
                          <Row key={index}>
                            <Col className="update__canDelete">
                              <span className="accordion__subtitle">
                                {t(
                                  'pages.boat.edit.electricity.batteryPark.title',
                                )}
                                {index + 1}
                              </span>
                              <Button
                                variant="danger"
                                onClick={() => deleteBattery(element.id)}
                                className="canDelete__button"
                              >
                                <FaRegTrashAlt />
                              </Button>
                            </Col>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={3}>
                                  {t(
                                    'pages.boat.edit.electricity.batteryPark.quantity',
                                  )}
                                </Form.Label>
                                <Form.Control
                                  placeholder={t(
                                    'pages.boat.edit.electricity.batteryPark.quantity',
                                  )}
                                  defaultValue={element.quantity}
                                  onChange={(e) =>
                                    updateBatteryField(e, index, 'quantity')
                                  }
                                />
                              </Form.Group>
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={4}>
                                  {t(
                                    'pages.boat.edit.electricity.batteryPark.assignment',
                                  )}
                                </Form.Label>
                                <Form.Control
                                  placeholder={t(
                                    'pages.boat.edit.electricity.batteryPark.assignment',
                                  )}
                                  defaultValue={element.assignment}
                                  onChange={(e) =>
                                    updateBatteryField(e, index, 'assignment')
                                  }
                                />
                              </Form.Group>
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={2}>
                                  {t(
                                    'pages.boat.edit.electricity.batteryPark.type',
                                  )}
                                </Form.Label>
                                <Form.Control
                                  placeholder={t(
                                    'pages.boat.edit.electricity.batteryPark.type',
                                  )}
                                  defaultValue={element.type}
                                  onChange={(e) =>
                                    updateBatteryField(e, index, 'type')
                                  }
                                />
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={3}>
                                  {t(
                                    'pages.boat.edit.electricity.batteryPark.voltage',
                                  )}
                                </Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    placeholder={t(
                                      'pages.boat.edit.electricity.batteryPark.voltage',
                                    )}
                                    defaultValue={element.voltage}
                                    onChange={(e) =>
                                      updateBatteryField(e, index, 'voltage')
                                    }
                                  />
                                  <InputGroup.Text>V</InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={3}>
                                  {t(
                                    'pages.boat.edit.electricity.batteryPark.amperage',
                                  )}
                                </Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    placeholder={t(
                                      'pages.boat.edit.electricity.batteryPark.amperage',
                                    )}
                                    defaultValue={element.amperage}
                                    onChange={(e) =>
                                      updateBatteryField(e, index, 'amperage')
                                    }
                                  />
                                  <InputGroup.Text>A</InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                className="update__label"
                              ></Form.Group>
                            </Row>
                          </Row>
                        );
                      })}
                    <Row>
                      {addBattery.map((element, index) => {
                        return (
                          <AddBattery
                            key={index}
                            battery={element}
                            deleteItem={deleteObjectBattery}
                          />
                        );
                      })}
                      <Button
                        onClick={addNewBattery}
                        className="update__addButton"
                      >
                        <IoMdAddCircleOutline />
                        <span>
                          {t('pages.boat.edit.electricity.batteryPark.addNew')}
                        </span>
                      </Button>
                    </Row>
                    {generatorsUpdate &&
                      generatorsUpdate.map((element, index) => {
                        return (
                          <Row key={index}>
                            <Col className="update__canDelete">
                              <span className="accordion__subtitle">
                                {t(
                                  'pages.boat.edit.electricity.generators.title',
                                )}
                                {index + 1}
                              </span>
                              <Button
                                variant="danger"
                                onClick={() => deleteGenerator(element.id)}
                                className="canDelete__button"
                              >
                                <FaRegTrashAlt />
                              </Button>
                            </Col>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={3}>
                                  {t(
                                    'pages.boat.edit.electricity.generators.brand',
                                  )}
                                </Form.Label>
                                <Form.Control
                                  placeholder={t(
                                    'pages.boat.edit.electricity.generators.brand',
                                  )}
                                  defaultValue={element.brand}
                                  onChange={(e) =>
                                    updateGeneratorField(e, index, 'brand')
                                  }
                                />
                              </Form.Group>
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={4}>
                                  {t(
                                    'pages.boat.edit.electricity.generators.model',
                                  )}
                                </Form.Label>
                                <Form.Control
                                  placeholder={t(
                                    'pages.boat.edit.electricity.generators.model',
                                  )}
                                  defaultValue={element.model}
                                  onChange={(e) =>
                                    updateGeneratorField(e, index, 'model')
                                  }
                                />
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={3}>
                                  {t(
                                    'pages.boat.edit.electricity.generators.power',
                                  )}
                                </Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    placeholder={t(
                                      'pages.boat.edit.electricity.generators.power',
                                    )}
                                    defaultValue={element.power}
                                    onChange={(e) =>
                                      updateGeneratorField(e, index, 'power')
                                    }
                                  />
                                  <InputGroup.Text>kW</InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={4}>
                                  {t(
                                    'pages.boat.edit.electricity.generators.hours',
                                  )}
                                </Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    placeholder={t(
                                      'pages.boat.edit.electricity.generators.hours',
                                    )}
                                    defaultValue={element.hours}
                                    onChange={(e) =>
                                      updateGeneratorField(e, index, 'hours')
                                    }
                                  />
                                  <InputGroup.Text>h</InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={2}>
                                  {t(
                                    'pages.boat.edit.electricity.generators.serialNumber',
                                  )}
                                </Form.Label>
                                <Form.Control
                                  placeholder={t(
                                    'pages.boat.edit.electricity.generators.serialNumber',
                                  )}
                                  defaultValue={element.serial_number}
                                  onChange={(e) =>
                                    updateGeneratorField(
                                      e,
                                      index,
                                      'serial_number',
                                    )
                                  }
                                />
                              </Form.Group>
                            </Row>
                          </Row>
                        );
                      })}
                    <Row>
                      {addGenerator.map((element, index) => {
                        return (
                          <AddGenerator
                            key={index}
                            generator={element}
                            deleteItem={deleteObjectGenerator}
                          />
                        );
                      })}
                      <Button
                        onClick={addNewGenerator}
                        className="update__addButton"
                      >
                        <IoMdAddCircleOutline />
                        <span>
                          {t('pages.boat.edit.electricity.generators.addNew')}
                        </span>
                      </Button>
                    </Row>
                    <Row>
                      <span className="row accordion__subtitle">
                        {t('pages.boat.edit.electricity.solarPanel.title')}
                      </span>
                      <Row className="mb-3">
                        <Form.Group as={Col} md={3} className="update__label">
                          <Form.Label column md={4}>
                            {t('pages.boat.edit.electricity.solarPanel.power')}
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              placeholder={t(
                                'pages.boat.edit.electricity.solarPanel.power',
                              )}
                              defaultValue={solarPanel && solarPanel.power}
                              onChange={(e) =>
                                setSolarPanelUpdate({
                                  ...solarPanelUpdate,
                                  power: e.target.value,
                                })
                              }
                            />
                            <InputGroup.Text>W</InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} className="update__label">
                          <Form.Control
                            placeholder="Description"
                            defaultValue={solarPanel && solarPanel.description}
                            onChange={(e) =>
                              setSolarPanelUpdate({
                                ...solarPanelUpdate,
                                description: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                      </Row>
                    </Row>
                    <Row>
                      <span className="row accordion__subtitle">
                        {t('pages.boat.edit.electricity.windturbine.title')}
                      </span>
                      <Row className="mb-3">
                        <Form.Group as={Col} md={3} className="update__label">
                          <Form.Label column md={4}>
                            {t(
                              'pages.boat.edit.electricity.windturbine.quantity',
                            )}
                          </Form.Label>
                          <Form.Control
                            placeholder={t(
                              'pages.boat.edit.electricity.windturbine.quantity',
                            )}
                            defaultValue={windturbine && windturbine.quantity}
                            onChange={(e) =>
                              setWindturbineUpdate({
                                ...windturbineUpdate,
                                quantity: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                        <Form.Group as={Col} md={4} className="update__label">
                          <Form.Label column md={5}>
                            {t(
                              'pages.boat.edit.electricity.windturbine.totalPower',
                            )}
                          </Form.Label>
                          <Form.Control
                            placeholder={t(
                              'pages.boat.edit.electricity.windturbine.totalPower',
                            )}
                            defaultValue={
                              windturbine && windturbine.total_power
                            }
                            onChange={(e) =>
                              setWindturbineUpdate({
                                ...windturbineUpdate,
                                total_power: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} className="update__label">
                          <Form.Control
                            placeholder={t(
                              'pages.boat.edit.electricity.windturbine.description',
                            )}
                            defaultValue={
                              windturbine && windturbine.description
                            }
                            onChange={(e) =>
                              setWindturbineUpdate({
                                ...windturbineUpdate,
                                description: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                      </Row>
                    </Row>
                    <Row>
                      <span className="row accordion__subtitle">
                        {t('pages.boat.edit.electricity.electronic.title')}
                      </span>
                      <Row className="mb-3">
                        <Form.Group className="category__container mb-3">
                          <Form.Check
                            type="checkbox"
                            label={t(
                              'pages.boat.edit.electricity.electronic.lochSpeedo',
                            )}
                            checked={electronicUpdate?.loch_speedo || false}
                            onChange={(e) =>
                              setElectronicUpdate({
                                ...electronicUpdate,
                                loch_speedo: e.target.checked,
                              })
                            }
                            className="container__checkbox"
                          />
                          <Form.Control
                            as="textarea"
                            rows={1}
                            disabled={!electronicUpdate?.loch_speedo}
                            defaultValue={
                              electronicUpdate?.loch_speedo_description
                            }
                            onChange={(e) =>
                              setElectronicUpdate({
                                ...electronicUpdate,
                                loch_speedo_description: e.target.value,
                              })
                            }
                            className="container__description"
                          />
                        </Form.Group>
                        <Form.Group className="category__container mb-3">
                          <Form.Check
                            type="checkbox"
                            label={t(
                              'pages.boat.edit.electricity.electronic.sounder',
                            )}
                            checked={electronicUpdate?.sounder || false}
                            onChange={(e) =>
                              setElectronicUpdate({
                                ...electronicUpdate,
                                sounder: e.target.checked,
                              })
                            }
                            className="container__checkbox"
                          />
                          <Form.Control
                            as="textarea"
                            rows={1}
                            disabled={!electronicUpdate?.sounder}
                            defaultValue={electronicUpdate?.sounder_description}
                            onChange={(e) =>
                              setElectronicUpdate({
                                ...electronicUpdate,
                                sounder_description: e.target.value,
                              })
                            }
                            className="container__description"
                          />
                        </Form.Group>
                        <Form.Group className="category__container mb-3">
                          <Form.Check
                            type="checkbox"
                            label={t(
                              'pages.boat.edit.electricity.electronic.gps',
                            )}
                            checked={electronicUpdate?.gps || false}
                            onChange={(e) =>
                              setElectronicUpdate({
                                ...electronicUpdate,
                                gps: e.target.checked,
                              })
                            }
                            className="container__checkbox"
                          />
                          <Form.Control
                            as="textarea"
                            rows={1}
                            disabled={!electronicUpdate?.gps}
                            defaultValue={electronicUpdate?.gps_description}
                            onChange={(e) =>
                              setElectronicUpdate({
                                ...electronicUpdate,
                                gps_description: e.target.value,
                              })
                            }
                            className="container__description"
                          />
                        </Form.Group>
                        <Form.Group className="category__container mb-3">
                          <Form.Check
                            type="checkbox"
                            label={t(
                              'pages.boat.edit.electricity.electronic.vhf',
                            )}
                            checked={electronicUpdate?.vhf || false}
                            onChange={(e) =>
                              setElectronicUpdate({
                                ...electronicUpdate,
                                vhf: e.target.checked,
                              })
                            }
                            className="container__checkbox"
                          />
                          <Form.Control
                            as="textarea"
                            rows={1}
                            disabled={!electronicUpdate?.vhf}
                            defaultValue={electronicUpdate?.vhf_description}
                            onChange={(e) =>
                              setElectronicUpdate({
                                ...electronicUpdate,
                                vhf_description: e.target.value,
                              })
                            }
                            className="container__description"
                          />
                        </Form.Group>
                        <Form.Group className="category__container mb-3">
                          <Form.Check
                            type="checkbox"
                            label={t(
                              'pages.boat.edit.electricity.electronic.radar',
                            )}
                            checked={electronicUpdate?.radar || false}
                            onChange={(e) =>
                              setElectronicUpdate({
                                ...electronicUpdate,
                                radar: e.target.checked,
                              })
                            }
                            className="container__checkbox"
                          />
                          <Form.Control
                            as="textarea"
                            rows={1}
                            disabled={!electronicUpdate?.radar}
                            defaultValue={electronicUpdate?.radar_description}
                            onChange={(e) =>
                              setElectronicUpdate({
                                ...electronicUpdate,
                                radar_description: e.target.value,
                              })
                            }
                            className="container__description"
                          />
                        </Form.Group>
                        <Form.Group className="category__container mb-3">
                          <Form.Check
                            type="checkbox"
                            label={t(
                              'pages.boat.edit.electricity.electronic.ais',
                            )}
                            checked={electronicUpdate?.ais || false}
                            onChange={(e) =>
                              setElectronicUpdate({
                                ...electronicUpdate,
                                ais: e.target.checked,
                              })
                            }
                            className="container__checkbox"
                          />
                          <Form.Control
                            as="textarea"
                            rows={1}
                            disabled={!electronicUpdate?.ais}
                            defaultValue={electronicUpdate?.ais_description}
                            onChange={(e) =>
                              setElectronicUpdate({
                                ...electronicUpdate,
                                ais_description: e.target.value,
                              })
                            }
                            className="container__description"
                          />
                        </Form.Group>
                        <Form.Group className="category__container mb-3">
                          <Form.Check
                            type="checkbox"
                            label={t(
                              'pages.boat.edit.electricity.electronic.windvaneAnemometer',
                            )}
                            checked={
                              electronicUpdate?.windvane_anemometer || false
                            }
                            onChange={(e) =>
                              setElectronicUpdate({
                                ...electronicUpdate,
                                windvane_anemometer: e.target.checked,
                              })
                            }
                            className="container__checkbox"
                          />
                          <Form.Control
                            as="textarea"
                            rows={1}
                            disabled={!electronicUpdate?.windvane_anemometer}
                            defaultValue={
                              electronicUpdate?.windvane_anemometer_description
                            }
                            onChange={(e) =>
                              setElectronicUpdate({
                                ...electronicUpdate,
                                windvane_anemometer_description: e.target.value,
                              })
                            }
                            className="container__description"
                          />
                        </Form.Group>
                        <Form.Group className="category__container mb-3">
                          <Form.Label column md={3}>
                            {t(
                              'pages.boat.edit.electricity.electronic.batteryChargerPower',
                            )}
                          </Form.Label>
                          <Form.Control
                            placeholder={t(
                              'pages.boat.edit.electricity.electronic.batteryChargerPower',
                            )}
                            defaultValue={
                              electronicUpdate?.battery_charger_power
                            }
                            onChange={(e) => {
                              setElectronicUpdate({
                                ...electronicUpdate,
                                battery_charger_power: e.target.value,
                              });
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="category__container mb-3">
                          <Form.Label column md={3}>
                            {t(
                              'pages.boat.edit.electricity.electronic.batteryChargerDescription',
                            )}
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={1}
                            disabled={!electronicUpdate?.battery_charger_power}
                            defaultValue={
                              electronicUpdate?.battery_charger_description
                            }
                            onChange={(e) =>
                              setElectronicUpdate({
                                ...electronicUpdate,
                                battery_charger_description: e.target.value,
                              })
                            }
                            className="container__description"
                          />
                        </Form.Group>
                        <Form.Group className="category__container mb-3">
                          <Form.Check
                            type="checkbox"
                            label={t(
                              'pages.boat.edit.electricity.electronic.autoPilot',
                            )}
                            checked={electronicUpdate?.auto_pilot || false}
                            onChange={(e) =>
                              setElectronicUpdate({
                                ...electronicUpdate,
                                auto_pilot: e.target.checked,
                              })
                            }
                            className="container__checkbox"
                          />
                          <Form.Control
                            as="textarea"
                            rows={1}
                            disabled={!electronicUpdate?.auto_pilot}
                            defaultValue={
                              electronicUpdate?.auto_pilot_description
                            }
                            onChange={(e) =>
                              setElectronicUpdate({
                                ...electronicUpdate,
                                auto_pilot_description: e.target.value,
                              })
                            }
                            className="container__description"
                          />
                        </Form.Group>
                        <Form.Group className="category__container mb-3">
                          <Form.Check
                            type="checkbox"
                            label={t(
                              'pages.boat.edit.electricity.electronic.dockConnector',
                            )}
                            checked={electronicUpdate?.dock_connector || false}
                            onChange={(e) =>
                              setElectronicUpdate({
                                ...electronicUpdate,
                                dock_connector: e.target.checked,
                              })
                            }
                            className="container__checkbox"
                          />
                        </Form.Group>
                      </Row>
                    </Row>
                  </Row>
                </Accordion.Body>
              )}
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header onClick={() => handleHeader(3)} as="div">
                <section className="accordion__header">
                  <span className="accordion__title">
                    {t('pages.boat.display.tanks.title')}
                  </span>
                  <hr />
                  {isDisplay['3'] && (
                    <Row>
                      {tanks &&
                        tanks.map((element, index) => {
                          return (
                            <div key={index} className="col-3">
                              <span className="row accordion__subtitle">
                                {t('pages.boat.display.tanks.subtitle')}
                                {index + 1}
                              </span>
                              <Row>
                                <span>
                                  {t('pages.boat.display.tanks.capacity')}
                                  {element.capacity}
                                </span>
                                <span>
                                  {t('pages.boat.display.tanks.type')}
                                  {element.type}
                                </span>
                                {element.material && (
                                  <span>
                                    {t('pages.boat.display.tanks.material')}
                                    {element.material}
                                  </span>
                                )}
                              </Row>
                            </div>
                          );
                        })}
                    </Row>
                  )}
                </section>
              </Accordion.Header>
              {user && (
                <Accordion.Body className="accordion__update">
                  {tanksUpdate &&
                    tanksUpdate.map((element, index) => {
                      return (
                        <Row key={index}>
                          <Col className="update__canDelete">
                            <span className="accordion__subtitle">
                              {t('pages.boat.edit.tanks.title')}
                              {index + 1}
                            </span>
                            <Button
                              variant="danger"
                              onClick={() => deleteTank(element.id)}
                              className="canDelete__button"
                            >
                              <FaRegTrashAlt />
                            </Button>
                          </Col>
                          <Row className="mb-3">
                            <Form.Group as={Col} className="update__label">
                              <Form.Label column md={3}>
                                {t('pages.boat.edit.tanks.capacity')}
                              </Form.Label>
                              <Form.Control
                                placeholder={t(
                                  'pages.boat.edit.tanks.capacity',
                                )}
                                defaultValue={element.capacity}
                                onChange={(e) =>
                                  updateTanksField(e, index, 'capacity')
                                }
                              />
                            </Form.Group>
                            <Form.Group as={Col} className="update__label">
                              <Form.Label column md={3}>
                                {t('pages.boat.edit.tanks.type')}
                              </Form.Label>
                              <Form.Select
                                value={
                                  element.id_tanks_type === null
                                    ? 0
                                    : element.id_tanks_type
                                }
                                onChange={(e) =>
                                  updateTanksField(e, index, 'id_tanks_type')
                                }
                              >
                                {tanksTypes.map((element, index) => {
                                  return (
                                    <option key={index} value={element.id}>
                                      {t(element.tag)}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} className="update__label">
                              <Form.Label column md={3}>
                                {t('pages.boat.edit.tanks.material')}
                              </Form.Label>
                              <Form.Select
                                value={
                                  element.id_material === null
                                    ? 0
                                    : element.id_material
                                }
                                onChange={(e) =>
                                  updateTanksField(e, index, 'id_material')
                                }
                              >
                                <option value={0}>None</option>
                                {material.map((element, index) => {
                                  return (
                                    <option key={index} value={element.id}>
                                      {t(element.tag)}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Form.Group>
                          </Row>
                        </Row>
                      );
                    })}
                  <Row>
                    {addTanks.map((element, index) => {
                      return (
                        <AddTank
                          key={index}
                          tanksTypes={tanksTypes}
                          material={material}
                          tank={element}
                          deleteItem={deleteObjectTank}
                        />
                      );
                    })}
                    <Button onClick={addNewTank} className="update__addButton">
                      <IoMdAddCircleOutline />
                      <span>{t('pages.boat.edit.tanks.addNew')}</span>
                    </Button>
                  </Row>
                </Accordion.Body>
              )}
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header onClick={() => handleHeader(4)} as="div">
                <section className="accordion__header">
                  <span className="accordion__title">
                    {t('pages.boat.display.layout.title')}
                  </span>
                  <hr />
                  {isDisplay['4'] && (
                    <React.Fragment>
                      {comfort && (
                        <Row>
                          <span className="accordion__subtitle">
                            {t('pages.boat.display.layout.comfort.title')}
                          </span>
                          <div className="header__container">
                            <Row>
                              <Row>
                                <span className="col-4">
                                  {t(
                                    'pages.boat.display.layout.comfort.airConditioning',
                                  )}
                                  {comfort.air_conditioning ? 'Yes' : 'No'}
                                </span>
                                {comfort.air_conditioning && (
                                  <span className="col-8">
                                    {comfort.air_conditioning_description}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                <span className="col-4">
                                  {t(
                                    'pages.boat.display.layout.comfort.pulseAirHeating',
                                  )}
                                  {comfort.pulse_air_heating ? 'Yes' : 'No'}
                                </span>
                                {comfort.pulse_air_heating && (
                                  <span className="col-8">
                                    {comfort.pulse_air_heating_description}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                <span className="col-2">
                                  {t('pages.boat.display.details.tv')}
                                  {comfort.tv
                                    ? `${t('true')}`
                                    : `${t('false')}`}
                                </span>
                                <span className="col-6">
                                  {comfort.tv_description}
                                </span>
                              </Row>
                              <Row>
                                <span className="col-4">
                                  {t(
                                    'pages.boat.display.layout.comfort.hotWater',
                                  )}
                                  {comfort.hot_water ? 'Yes' : 'No'}
                                </span>
                                {comfort.hot_water && (
                                  <span className="col-8">
                                    {comfort.hot_water_description}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                <span className="col-4">
                                  {t(
                                    'pages.boat.display.layout.comfort.dessalator',
                                  )}
                                  {comfort.dessalator
                                    ? `${t('true')}`
                                    : `${t('false')}`}
                                </span>
                                {comfort.dessalator && (
                                  <span className="col-8">
                                    {comfort.dessalator_description}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                <span className="col-4">
                                  {t(
                                    'pages.boat.display.layout.comfort.mooringQuantity',
                                  )}
                                  {comfort.mooring_quantity}
                                </span>
                                {comfort.mooring_quantity && (
                                  <span className="col-8">
                                    {comfort.mooring_description}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                <span className="col-3">
                                  {t(
                                    'pages.boat.display.layout.comfort.deckShower',
                                  )}
                                  {comfort.deck_shower
                                    ? `${t('true')}`
                                    : `${t('false')}`}
                                </span>
                                <span className="col-3">
                                  {t(
                                    'pages.boat.display.layout.comfort.pressurizedWater',
                                  )}
                                  {comfort.pressurized_water
                                    ? `${t('true')}`
                                    : `${t('false')}`}
                                </span>
                                <span className="col-3">
                                  {t(
                                    'pages.boat.display.layout.comfort.iceMaker',
                                  )}
                                  {comfort.ice_maker
                                    ? `${t('true')}`
                                    : `${t('false')}`}
                                </span>
                                <span className="col-4">
                                  {t('pages.boat.display.details.teakDeck')}
                                  {comfort.teak_deck
                                    ? `${t('true')}`
                                    : `${t('false')}`}
                                </span>
                              </Row>
                            </Row>
                          </div>
                        </Row>
                      )}
                      {cabinsDisplay && (
                        <React.Fragment>
                          <span className="accordion__subtitle">Cabins</span>
                          <div className="header__container">
                            {cabinsDisplay.map((element, index) => {
                              return (
                                <Row key={index}>
                                  <span className="accordion__subtitle">
                                    {t(
                                      'pages.boat.display.layout.cabins.title',
                                    )}
                                    {index + 1}
                                  </span>
                                  <Row>
                                    <span className="col-4">
                                      {t(
                                        'pages.boat.display.layout.cabins.type',
                                      )}
                                      {element.type}
                                    </span>
                                    <span className="col-4">
                                      {t(
                                        'pages.boat.display.layout.cabins.position',
                                      )}
                                      {element.position}
                                    </span>
                                  </Row>
                                  <Row>
                                    <span className="col-4">
                                      {t(
                                        'pages.boat.display.layout.cabins.quantitySingleBed',
                                      )}
                                      {element.quantity_single_bed}
                                    </span>
                                    <span className="col-4">
                                      {t(
                                        'pages.boat.display.layout.cabins.airConditioning',
                                      )}
                                      {element.air_conditioning ? 'Yes' : 'No'}
                                    </span>
                                    <span className="col-4">
                                      {t(
                                        'pages.boat.display.layout.cabins.pulseAirHeating',
                                      )}
                                      {element.pulse_air_heating ? 'Yes' : 'No'}
                                    </span>
                                  </Row>
                                  <Row>
                                    <span className="col-4">
                                      {t(
                                        'pages.boat.display.layout.cabins.doubleBed',
                                      )}
                                      {element.double_bed ? 'Yes' : 'No'}
                                    </span>
                                    {element.double_bed && (
                                      <span className="col-8">
                                        {element.double_bed_description}
                                      </span>
                                    )}
                                  </Row>
                                  <Row>
                                    <span className="col-4">
                                      {t(
                                        'pages.boat.display.layout.cabins.hifi',
                                      )}
                                      {element.hifi ? 'Yes' : 'No'}
                                    </span>
                                    {element.hifi && (
                                      <span className="col-8">
                                        {element.hifi_description}
                                      </span>
                                    )}
                                  </Row>
                                  <Row>
                                    <span className="col-4">
                                      {t('pages.boat.display.layout.cabins.tv')}
                                      {element.tv ? 'Yes' : 'No'}
                                    </span>
                                    {element.tv && (
                                      <span className="col-8">
                                        {element.tv_description}
                                      </span>
                                    )}
                                  </Row>
                                </Row>
                              );
                            })}
                          </div>
                        </React.Fragment>
                      )}
                      <Row>
                        {kitchens && (
                          <React.Fragment>
                            <span className="accordion__subtitle">
                              {t('pages.boat.display.layout.kitchens.title')}
                            </span>
                            <div className="header__container">
                              {kitchens.map((element, index) => {
                                return (
                                  <Row key={index}>
                                    <Row>
                                      {element.hotplates && (
                                        <span className="col-4">
                                          {t(
                                            'pages.boat.display.layout.kitchens.hotplates',
                                          )}
                                          {element.hotplates}
                                        </span>
                                      )}
                                      {element.hotplates_description && (
                                        <span className="col-8">
                                          {element.hotplates_description}
                                        </span>
                                      )}
                                    </Row>
                                    <Row>
                                      <span className="col-4">
                                        {t(
                                          'pages.boat.display.layout.kitchens.quantityOven',
                                        )}
                                        {element.quantity_oven}
                                      </span>
                                      {element.oven && (
                                        <span className="col-8">
                                          {element.oven}
                                        </span>
                                      )}
                                    </Row>
                                    <Row>
                                      <span className="col-4">
                                        {t(
                                          'pages.boat.display.layout.kitchens.microwave',
                                        )}
                                        {element.microwave ? 'Yes' : 'No'}
                                      </span>
                                      {element.quantity_fridge && (
                                        <span className="col-4">
                                          {t(
                                            'pages.boat.display.layout.kitchens.quantityFridge',
                                          )}
                                          {element.quantity_fridge}
                                        </span>
                                      )}
                                      {element.quantity_freezer && (
                                        <span className="col-4">
                                          {t(
                                            'pages.boat.display.layout.kitchens.quantityFreezer',
                                          )}
                                          {element.quantity_freezer}
                                        </span>
                                      )}
                                    </Row>
                                    {element.description && (
                                      <span>{element.description}</span>
                                    )}
                                  </Row>
                                );
                              })}
                            </div>
                          </React.Fragment>
                        )}
                      </Row>
                      <Row>
                        {bathroom && (
                          <React.Fragment>
                            <span className="accordion__subtitle">
                              {t('pages.boat.display.layout.bathrooms.title')}
                            </span>
                            <div className="header__container">
                              {bathroom.map((element, index) => {
                                return (
                                  <Row key={index}>
                                    <Row>
                                      <span>
                                        {t(
                                          'pages.boat.display.layout.bathrooms.position',
                                        )}
                                        {element.position}
                                      </span>
                                      {element.description && (
                                        <span>
                                          {t(
                                            'pages.boat.display.layout.bathrooms.description',
                                          )}
                                          {element.description}
                                        </span>
                                      )}
                                    </Row>
                                    <Row>
                                      {element.toilets && (
                                        <span>
                                          {t(
                                            'pages.boat.display.layout.bathrooms.toilets',
                                          )}
                                          {element.toilets}
                                        </span>
                                      )}
                                      {element.shower && (
                                        <span>
                                          {t(
                                            'pages.boat.display.layout.bathrooms.shower',
                                          )}
                                          {element.shower}
                                        </span>
                                      )}
                                      {element.bathtub && (
                                        <span>
                                          {t(
                                            'pages.boat.display.layout.bathrooms.bathtub',
                                          )}
                                          {element.bathtub}
                                        </span>
                                      )}
                                    </Row>
                                  </Row>
                                );
                              })}
                            </div>
                          </React.Fragment>
                        )}
                      </Row>
                    </React.Fragment>
                  )}
                </section>
              </Accordion.Header>
              {user && (
                <Accordion.Body className="accordion__update">
                  <Row>
                    <Row>
                      <span className="accordion__subtitle">
                        {t('pages.boat.edit.layout.comfort.title')}
                      </span>
                      <Form.Group className="category__container mb-3">
                        <Form.Check
                          type="checkbox"
                          label={t(
                            'pages.boat.edit.layout.comfort.airConditioning',
                          )}
                          checked={comfortUpdate?.air_conditioning || false}
                          onChange={(e) =>
                            setComfortUpdate({
                              ...comfortUpdate,
                              air_conditioning: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                        <Form.Control
                          as="textarea"
                          rows={1}
                          disabled={!comfortUpdate?.air_conditioning}
                          defaultValue={
                            comfortUpdate?.air_conditioning_description
                          }
                          onChange={(e) =>
                            setComfortUpdate({
                              ...comfortUpdate,
                              air_conditioning_description: e.target.value,
                            })
                          }
                          className="container__description"
                        />
                      </Form.Group>
                      <Form.Group className="category__container mb-3">
                        <Form.Check
                          type="checkbox"
                          label={t(
                            'pages.boat.edit.layout.comfort.pulseAirHeating',
                          )}
                          checked={comfortUpdate?.pulse_air_heating || false}
                          onChange={(e) =>
                            setComfortUpdate({
                              ...comfortUpdate,
                              pulse_air_heating: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                        <Form.Control
                          as="textarea"
                          rows={1}
                          disabled={!comfortUpdate?.pulse_air_heating}
                          defaultValue={
                            comfortUpdate?.pulse_air_heating_description
                          }
                          onChange={(e) =>
                            setComfortUpdate({
                              ...comfortUpdate,
                              pulse_air_heating_description: e.target.value,
                            })
                          }
                          className="container__description"
                        />
                      </Form.Group>
                      <Form.Group className="category__container mb-3">
                        <Form.Check
                          type="checkbox"
                          label={t('pages.boat.edit.details.tv')}
                          checked={comfortUpdate?.tv || false}
                          onChange={(e) =>
                            setComfortUpdate({
                              ...comfortUpdate,
                              tv: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                        <Form.Control
                          as="textarea"
                          rows={1}
                          disabled={!comfortUpdate?.tv}
                          defaultValue={comfortUpdate?.tv_description}
                          onChange={(e) =>
                            setComfortUpdate({
                              ...comfortUpdate,
                              tv_description: e.target.value,
                            })
                          }
                          className="container__description"
                        />
                      </Form.Group>
                      <Form.Group className="category__container mb-3">
                        <Form.Check
                          type="checkbox"
                          label={t('pages.boat.edit.layout.comfort.hotWater')}
                          checked={comfortUpdate?.hotWater || false}
                          onChange={(e) =>
                            setComfortUpdate({
                              ...comfortUpdate,
                              hotWater: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                        <Form.Control
                          as="textarea"
                          rows={1}
                          disabled={!comfortUpdate?.hotWater}
                          defaultValue={comfortUpdate?.hotWater_description}
                          onChange={(e) =>
                            setComfortUpdate({
                              ...comfortUpdate,
                              hotWater_description: e.target.value,
                            })
                          }
                          className="container__description"
                        />
                      </Form.Group>
                      <Form.Group className="category__container mb-3">
                        <Form.Check
                          type="checkbox"
                          label={t('pages.boat.edit.layout.comfort.dessalator')}
                          checked={comfortUpdate?.dessalator || false}
                          onChange={(e) =>
                            setComfortUpdate({
                              ...comfortUpdate,
                              dessalator: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                        <Form.Control
                          as="textarea"
                          rows={1}
                          disabled={!comfortUpdate?.dessalator}
                          defaultValue={comfortUpdate?.dessalator_description}
                          onChange={(e) =>
                            setComfortUpdate({
                              ...comfortUpdate,
                              dessalator_description: e.target.value,
                            })
                          }
                          className="container__description"
                        />
                      </Form.Group>
                      <Form.Group className="category__container mb-3 mr-2">
                        <Form.Label column md={2}>
                          {t('pages.boat.edit.layout.comfort.mooringQuantity')}
                        </Form.Label>
                        <Form.Control
                          defaultValue={comfortUpdate?.mooring_quantity}
                          onChange={(e) =>
                            setComfortUpdate({
                              ...comfortUpdate,
                              mooring_quantity: e.target.value,
                            })
                          }
                        />
                        <Form.Group as={Col} md={8} className="update__label">
                          <Form.Control
                            as="textarea"
                            rows={1}
                            disabled={!comfortUpdate?.mooring_quantity}
                            defaultValue={comfortUpdate?.mooring_description}
                            onChange={(e) =>
                              setComfortUpdate({
                                ...comfortUpdate,
                                mooring_description: e.target.value,
                              })
                            }
                            className="container__description"
                          />
                        </Form.Group>
                      </Form.Group>
                      <Form.Group className="category__container mb-3">
                        <Form.Check
                          type="checkbox"
                          label={t('pages.boat.edit.layout.comfort.deckShower')}
                          checked={comfortUpdate?.deck_shower || false}
                          onChange={(e) =>
                            setComfortUpdate({
                              ...comfortUpdate,
                              deck_shower: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                        <Form.Check
                          type="checkbox"
                          label={t(
                            'pages.boat.edit.layout.comfort.pressurizedWater',
                          )}
                          checked={comfortUpdate?.pressurized_water || false}
                          onChange={(e) =>
                            setComfortUpdate({
                              ...comfortUpdate,
                              pressurized_water: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                        <Form.Check
                          type="checkbox"
                          label={t('pages.boat.edit.layout.comfort.iceMaker')}
                          checked={comfortUpdate?.ice_maker || false}
                          onChange={(e) =>
                            setComfortUpdate({
                              ...comfortUpdate,
                              ice_maker: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                        <Form.Check
                          type="checkbox"
                          label={t('pages.boat.edit.details.teakDeck')}
                          checked={comfortUpdate?.teak_deck || false}
                          onChange={(e) =>
                            setComfortUpdate({
                              ...comfortUpdate,
                              teak_deck: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                      </Form.Group>
                    </Row>
                    {cabinsUpdate &&
                      cabinsUpdate.map((element, index) => {
                        return (
                          <Row key={index}>
                            <Col className="update__canDelete">
                              <span className="accordion__subtitle">
                                {t('pages.boat.edit.layout.cabins.title')}
                                {index + 1}
                              </span>
                              <Button
                                variant="danger"
                                onClick={() => deleteCabin(element.id)}
                                className="canDelete__button"
                              >
                                <FaRegTrashAlt />
                              </Button>
                            </Col>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={2}>
                                  {t('pages.boat.edit.layout.cabins.type')}
                                </Form.Label>
                                <Form.Select
                                  defaultValue={element.id_cabin_type}
                                  onChange={(e) =>
                                    updateCabinsField(e, index, 'id_cabin_type')
                                  }
                                >
                                  {cabinsTypes.map((element, index) => {
                                    return (
                                      <option key={index} value={element.id}>
                                        {t(element.tag)}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={2}>
                                  {t('pages.boat.edit.layout.cabins.position')}
                                </Form.Label>
                                <Form.Select
                                  defaultValue={element.id_position}
                                  onChange={(e) =>
                                    updateCabinsField(e, index, 'id_position')
                                  }
                                >
                                  {position.map((element, index) => {
                                    return (
                                      <option key={index} value={element.id}>
                                        {t(element.tag)}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={8}>
                                  {t(
                                    'pages.boat.edit.layout.cabins.quantitySingleBed',
                                  )}
                                </Form.Label>
                                <Form.Control
                                  placeholder="Quantity"
                                  defaultValue={element.quantity_single_bed}
                                  onChange={(e) =>
                                    updateCabinsField(
                                      e,
                                      index,
                                      'quantity_single_bed',
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group as={Col} className="update__label">
                                <Form.Check
                                  type="checkbox"
                                  label={t(
                                    'pages.boat.edit.layout.cabins.airConditioning',
                                  )}
                                  checked={element.air_conditioning || false}
                                  onChange={(e) =>
                                    updateCabinsField(
                                      e,
                                      index,
                                      'air_conditioning',
                                    )
                                  }
                                  className="container__checkbox"
                                />
                              </Form.Group>
                              <Form.Group as={Col} className="update__label">
                                <Form.Check
                                  type="checkbox"
                                  label={t(
                                    'pages.boat.edit.layout.cabins.pulseAirHeating',
                                  )}
                                  checked={element.pulse_air_heating || false}
                                  onChange={(e) =>
                                    updateCabinsField(
                                      e,
                                      index,
                                      'pulse_air_heating',
                                    )
                                  }
                                  className="container__checkbox"
                                />
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                md={2}
                                className="update__label"
                              >
                                <Form.Check
                                  type="checkbox"
                                  label={t(
                                    'pages.boat.edit.layout.cabins.doubleBed',
                                  )}
                                  checked={element.double_bed || false}
                                  onChange={(e) =>
                                    updateCabinsField(e, index, 'double_bed')
                                  }
                                  className="container__checkbox"
                                />
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Form.Control
                                  as="textarea"
                                  rows={1}
                                  disabled={!element.double_bed}
                                  defaultValue={element.double_bed_description}
                                  onChange={(e) =>
                                    updateCabinsField(
                                      e,
                                      index,
                                      'double_bed_description',
                                    )
                                  }
                                  className="container__description"
                                />
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                md={2}
                                className="update__label"
                              >
                                <Form.Check
                                  type="checkbox"
                                  label={t(
                                    'pages.boat.edit.layout.cabins.hifi',
                                  )}
                                  checked={element.hifi || false}
                                  onChange={(e) =>
                                    updateCabinsField(e, index, 'hifi')
                                  }
                                  className="container__checkbox"
                                />
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Form.Control
                                  as="textarea"
                                  rows={1}
                                  disabled={!element.hifi}
                                  defaultValue={element.hifi_description}
                                  onChange={(e) =>
                                    updateCabinsField(
                                      e,
                                      index,
                                      'hifi_description',
                                    )
                                  }
                                  className="container__description"
                                />
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                md={2}
                                className="update__label"
                              >
                                <Form.Check
                                  type="checkbox"
                                  label={t('pages.boat.edit.layout.cabins.tv')}
                                  checked={element.tv || false}
                                  onChange={(e) =>
                                    updateCabinsField(e, index, 'tv')
                                  }
                                  className="container__checkbox"
                                />
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Form.Control
                                  as="textarea"
                                  rows={1}
                                  disabled={!element.tv}
                                  defaultValue={element.tv_description}
                                  onChange={(e) =>
                                    updateCabinsField(
                                      e,
                                      index,
                                      'tv_description',
                                    )
                                  }
                                  className="container__description"
                                />
                              </Form.Group>
                            </Row>
                          </Row>
                        );
                      })}
                    <Row>
                      {addCabins.map((element, index) => {
                        return (
                          <AddCabin
                            key={index}
                            cabinsTypes={cabinsTypes}
                            position={position}
                            cabin={element}
                            deleteItem={deleteObjectCabin}
                          />
                        );
                      })}
                      <Button
                        onClick={addNewCabin}
                        className="update__addButton"
                      >
                        <IoMdAddCircleOutline />
                        <span>{t('pages.boat.edit.layout.cabins.addNew')}</span>
                      </Button>
                    </Row>
                    {kitchensUpdate &&
                      kitchensUpdate.map((element, index) => {
                        return (
                          <Row key={index}>
                            <Col className="update__canDelete">
                              <span className="accordion__subtitle">
                                {t('pages.boat.edit.layout.kitchens.title')}
                                {index + 1}
                              </span>
                              <Button
                                variant="danger"
                                onClick={() => deleteKitchen(element.id)}
                                className="canDelete__button"
                              >
                                <FaRegTrashAlt />
                              </Button>
                            </Col>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                md={4}
                                className="update__label"
                              >
                                <Form.Label column md={3}>
                                  {t(
                                    'pages.boat.edit.layout.kitchens.hotplates',
                                  )}
                                </Form.Label>
                                <Form.Select
                                  value={
                                    element.id_hotplates === null
                                      ? 0
                                      : element.id_hotplates
                                  }
                                  onChange={(e) =>
                                    updateKitchensField(
                                      e,
                                      index,
                                      'id_hotplates',
                                    )
                                  }
                                >
                                  {hotplates.map((element, index) => {
                                    return (
                                      <option key={index} value={element.id}>
                                        {t(element.tag)}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Form.Control
                                  as="textarea"
                                  rows={1}
                                  disabled={
                                    element.id_hotplates === null ||
                                    element.id_hotplates === '0'
                                  }
                                  defaultValue={element.hotplates_description}
                                  onChange={(e) =>
                                    updateKitchensField(
                                      e,
                                      index,
                                      'hotplates_description',
                                    )
                                  }
                                  className="container__description"
                                />
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                md={4}
                                className="update__label"
                              >
                                <Form.Label column md={6}>
                                  {t(
                                    'pages.boat.edit.layout.kitchens.quantityOven',
                                  )}
                                </Form.Label>
                                <Form.Control
                                  placeholder="Quantity"
                                  defaultValue={element.quantity_oven}
                                  disabled={
                                    element.id_oven === null ||
                                    element.id_oven === '0'
                                  }
                                  onChange={(e) =>
                                    updateKitchensField(
                                      e,
                                      index,
                                      'quantity_oven',
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md={4}
                                className="update__label"
                              >
                                <Form.Label column md={3}>
                                  {t('pages.boat.edit.layout.kitchens.oven')}
                                </Form.Label>
                                <Form.Select
                                  value={
                                    element.id_oven === null
                                      ? 0
                                      : element.id_oven
                                  }
                                  onChange={(e) =>
                                    updateKitchensField(e, index, 'id_oven')
                                  }
                                >
                                  {oven.map((element, index) => {
                                    return (
                                      <option key={index} value={element.id}>
                                        {t(element.tag)}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="update__label">
                                <Form.Check
                                  type="checkbox"
                                  label={t(
                                    'pages.boat.edit.layout.kitchens.microwave',
                                  )}
                                  checked={element.microwave || false}
                                  onChange={(e) =>
                                    updateKitchensField(e, index, 'microwave')
                                  }
                                  className="container__checkbox"
                                />
                              </Form.Group>
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={6}>
                                  {t(
                                    'pages.boat.edit.layout.kitchens.quantityFridge',
                                  )}
                                </Form.Label>
                                <Form.Control
                                  placeholder="Quantity"
                                  defaultValue={element.quantity_fridge}
                                  onChange={(e) =>
                                    updateKitchensField(
                                      e,
                                      index,
                                      'quantity_fridge',
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column md={6}>
                                  {t(
                                    'pages.boat.edit.layout.kitchens.quantityFreezer',
                                  )}
                                </Form.Label>
                                <Form.Control
                                  placeholder="Quantity"
                                  defaultValue={element.quantity_freezer}
                                  onChange={(e) =>
                                    updateKitchensField(
                                      e,
                                      index,
                                      'quantity_freezer',
                                    )
                                  }
                                />
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="update__label">
                                <Form.Control
                                  as="textarea"
                                  rows={1}
                                  defaultValue={element.description}
                                  onChange={(e) =>
                                    updateKitchensField(e, index, 'description')
                                  }
                                  className="container__description"
                                />
                              </Form.Group>
                            </Row>
                          </Row>
                        );
                      })}
                    <Row>
                      {addKitchens.map((element, index) => {
                        return (
                          <AddKitchen
                            key={index}
                            hotplates={hotplates}
                            oven={oven}
                            kitchen={element}
                            deleteItem={deleteObjectKitchen}
                          />
                        );
                      })}
                      <Button
                        onClick={addNewKitchen}
                        className="update__addButton"
                      >
                        <IoMdAddCircleOutline />
                        <span>
                          {t('pages.boat.edit.layout.kitchens.addNew')}
                        </span>
                      </Button>
                    </Row>
                    {bathroomUpdate &&
                      bathroomUpdate.map((element, index) => {
                        return (
                          <Row key={index}>
                            <Col className="update__canDelete">
                              <span className="accordion__subtitle">
                                {t('pages.boat.edit.layout.bathrooms.title')}
                                {index + 1}
                              </span>
                              <Button
                                variant="danger"
                                onClick={() => deleteBathroom(element.id)}
                                className="canDelete__button"
                              >
                                <FaRegTrashAlt />
                              </Button>
                            </Col>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                md={4}
                                className="update__label"
                              >
                                <Form.Label column md={3}>
                                  {t(
                                    'pages.boat.edit.layout.bathrooms.position',
                                  )}
                                </Form.Label>
                                <Form.Select
                                  defaultValue={element.id_position}
                                  onChange={(e) =>
                                    updateBathroomField(e, index, 'id_position')
                                  }
                                >
                                  {position.map((element, index) => {
                                    return (
                                      <option key={index} value={element.id}>
                                        {t(element.tag)}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column>
                                  {t(
                                    'pages.boat.edit.layout.bathrooms.description',
                                  )}
                                </Form.Label>
                                <Form.Control
                                  placeholder={t(
                                    'pages.boat.edit.layout.bathrooms.description',
                                  )}
                                  defaultValue={element.description}
                                  onChange={(e) =>
                                    updateBathroomField(e, index, 'description')
                                  }
                                />
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column>
                                  {t(
                                    'pages.boat.edit.layout.bathrooms.toilets',
                                  )}
                                </Form.Label>
                                <Form.Control
                                  placeholder={t(
                                    'pages.boat.edit.layout.bathrooms.toilets',
                                  )}
                                  defaultValue={element.toilets}
                                  onChange={(e) =>
                                    updateBathroomField(e, index, 'toilets')
                                  }
                                />
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column>
                                  {t('pages.boat.edit.layout.bathrooms.shower')}
                                </Form.Label>
                                <Form.Control
                                  placeholder={t(
                                    'pages.boat.edit.layout.bathrooms.shower',
                                  )}
                                  defaultValue={element.shower}
                                  onChange={(e) =>
                                    updateBathroomField(e, index, 'shower')
                                  }
                                />
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <Form.Group as={Col} className="update__label">
                                <Form.Label column>
                                  {t(
                                    'pages.boat.edit.layout.bathrooms.bathtub',
                                  )}
                                </Form.Label>
                                <Form.Control
                                  placeholder={t(
                                    'pages.boat.edit.layout.bathrooms.bathtub',
                                  )}
                                  defaultValue={element.bathtub}
                                  onChange={(e) =>
                                    updateBathroomField(e, index, 'bathtub')
                                  }
                                />
                              </Form.Group>
                            </Row>
                          </Row>
                        );
                      })}
                    <Row>
                      {addBathrooms.map((element, index) => {
                        return (
                          <AddBathroom
                            key={index}
                            position={position}
                            bathroom={element}
                            deleteItem={deleteObjectBathroom}
                          />
                        );
                      })}
                      <Button
                        onClick={addNewBathroom}
                        className="update__addButton"
                      >
                        <IoMdAddCircleOutline />
                        <span>
                          {t('pages.boat.edit.layout.bathrooms.addNew')}
                        </span>
                      </Button>
                    </Row>
                  </Row>
                </Accordion.Body>
              )}
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header onClick={() => handleHeader(5)} as="div">
                <section className="accordion__header">
                  <span className="accordion__title">
                    {t('pages.boat.display.cover.title')}
                  </span>
                  <hr />
                  {isDisplay['5'] && (
                    <React.Fragment>
                      {cover && (
                        <React.Fragment>
                          <Row>
                            <span className="col-4">
                              {t('pages.boat.display.cover.winterCover')}
                              {cover.winter_cover ? 'Yes' : 'No'}
                            </span>
                            <span className="col-4">
                              {t('pages.boat.display.cover.sunCover')}
                              {cover.sun_cover ? 'Yes' : 'No'}
                            </span>
                            <span className="col-4">
                              {t('pages.boat.display.cover.windscreenCover')}
                              {cover.windscreen_cover ? 'Yes' : 'No'}
                            </span>
                          </Row>
                          <Row>
                            <span className="col-4">
                              {t('pages.boat.display.cover.sprayhood')}
                              {cover.sprayhood ? 'Yes' : 'No'}
                            </span>
                            <span className="col-4">
                              {t('pages.boat.display.cover.boomCover')}
                              {cover.boom_cover ? 'Yes' : 'No'}
                            </span>
                            <span className="col-4">
                              {t('pages.boat.display.cover.cockpitUpholstery')}
                              {cover.cockpit_upholstery ? 'Yes' : 'No'}
                            </span>
                          </Row>
                          <Row>
                            <span className="col-4">
                              {t('pages.boat.display.cover.campingKit')}
                              {cover.camping_kit ? 'Yes' : 'No'}
                            </span>
                            <span className="col-4">
                              {t('pages.boat.display.cover.biminiTop')}
                              {cover.bimini_top ? 'Yes' : 'No'}
                            </span>
                            <span className="col-4">
                              {t('pages.boat.display.cover.sunLounger')}
                              {cover.sun_lounger ? 'Yes' : 'No'}
                            </span>
                          </Row>
                          <Row>
                            {cover.description && (
                              <span className="col-12">
                                {t('pages.boat.display.cover.description')}
                                {cover.description}
                              </span>
                            )}
                          </Row>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </section>
              </Accordion.Header>
              {user && (
                <Accordion.Body className="accordion__update">
                  <Row className="mb-3">
                    <Form.Group className="category__container mb-3">
                      <Form.Check
                        type="checkbox"
                        label={t('pages.boat.edit.cover.winterCover')}
                        checked={coverUpdate?.winter_cover || false}
                        onChange={(e) =>
                          setCoverUpdate({
                            ...coverUpdate,
                            winter_cover: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                      <Form.Check
                        type="checkbox"
                        label={t('pages.boat.edit.cover.sunCover')}
                        checked={coverUpdate?.sun_cover || false}
                        onChange={(e) =>
                          setCoverUpdate({
                            ...coverUpdate,
                            sun_cover: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                      <Form.Check
                        type="checkbox"
                        label={t('pages.boat.edit.cover.windscreenCover')}
                        checked={coverUpdate?.windscreen_cover || false}
                        onChange={(e) =>
                          setCoverUpdate({
                            ...coverUpdate,
                            windscreen_cover: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                    </Form.Group>
                    <Form.Group className="category__container mb-3">
                      <Form.Check
                        type="checkbox"
                        label={t('pages.boat.edit.cover.sprayhood')}
                        checked={coverUpdate?.sprayhood || false}
                        onChange={(e) =>
                          setCoverUpdate({
                            ...coverUpdate,
                            sprayhood: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                      <Form.Check
                        type="checkbox"
                        label={t('pages.boat.edit.cover.boomCover')}
                        checked={coverUpdate?.boom_cover || false}
                        onChange={(e) =>
                          setCoverUpdate({
                            ...coverUpdate,
                            boom_cover: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                      <Form.Check
                        type="checkbox"
                        label={t('pages.boat.edit.cover.cockpitUpholstery')}
                        checked={coverUpdate?.cockpit_upholstery || false}
                        onChange={(e) =>
                          setCoverUpdate({
                            ...coverUpdate,
                            cockpit_upholstery: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                    </Form.Group>
                    <Form.Group className="category__container mb-3">
                      <Form.Check
                        type="checkbox"
                        label={t('pages.boat.edit.cover.campingKit')}
                        checked={coverUpdate?.camping_kit || false}
                        onChange={(e) =>
                          setCoverUpdate({
                            ...coverUpdate,
                            camping_kit: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                      <Form.Check
                        type="checkbox"
                        label={t('pages.boat.edit.cover.biminiTop')}
                        checked={coverUpdate?.bimini_top || false}
                        onChange={(e) =>
                          setCoverUpdate({
                            ...coverUpdate,
                            bimini_top: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                      <Form.Check
                        type="checkbox"
                        label={t('pages.boat.edit.cover.sunLounger')}
                        checked={coverUpdate?.sun_lounger || false}
                        onChange={(e) =>
                          setCoverUpdate({
                            ...coverUpdate,
                            sun_lounger: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                    </Form.Group>
                    <Form.Group className="category__container mb-3">
                      <Form.Control
                        as="textarea"
                        rows={1}
                        defaultValue={coverUpdate?.description}
                        onChange={(e) =>
                          setCoverUpdate({
                            ...coverUpdate,
                            description: e.target.value,
                          })
                        }
                        className="container__description"
                      />
                    </Form.Group>
                  </Row>
                </Accordion.Body>
              )}
            </Accordion.Item>
            {details && details.typology.includes('sail') && (
              <Accordion.Item eventKey="6">
                <Accordion.Header onClick={() => handleHeader(6)} as="div">
                  <section className="accordion__header">
                    <span className="accordion__title">
                      {t('pages.boat.display.sailsRiggingFittings.title')}
                    </span>
                    <hr />
                    {isDisplay['6'] && (
                      <Row>
                        {sails && sails.length > 0 && (
                          <React.Fragment>
                            <span className="accordion__subtitle">
                              {t(
                                'pages.boat.display.sailsRiggingFittings.sails.title',
                              )}
                            </span>
                            <div className="header__container sails__container">
                              {sails.map((element, index) => {
                                return (
                                  <Row key={index} className="container__item">
                                    <span className="item__type">
                                      {t(element.type)}
                                    </span>
                                    {element.brand && (
                                      <span>
                                        {t(
                                          'pages.boat.display.sailsRiggingFittings.sails.brand',
                                        )}
                                        {element.brand}
                                      </span>
                                    )}
                                    {element.area && (
                                      <span>
                                        {t(
                                          'pages.boat.display.sailsRiggingFittings.sails.area',
                                        )}
                                        {element.area}
                                      </span>
                                    )}
                                    {element.year && (
                                      <span>
                                        {t(
                                          'pages.boat.display.sailsRiggingFittings.sails.year',
                                        )}
                                        {element.year}
                                      </span>
                                    )}
                                    {element.description && (
                                      <span>
                                        {t(
                                          'pages.boat.display.sailsRiggingFittings.sails.description',
                                        )}
                                        {element.description}
                                      </span>
                                    )}
                                  </Row>
                                );
                              })}
                            </div>
                          </React.Fragment>
                        )}
                        {rigging && (
                          <React.Fragment>
                            <span className="accordion__subtitle">
                              {t(
                                'pages.boat.display.sailsRiggingFittings.rigging.title',
                              )}
                            </span>
                            <Row>
                              <Row>
                                <span className="col-4">
                                  {t(
                                    'pages.boat.display.sailsRiggingFittings.rigging.genoaWinder',
                                  )}
                                  {rigging.genoa_winder ? 'Yes' : 'No'}
                                </span>
                                {rigging.genoa_winder && (
                                  <span className="col-8">
                                    {rigging.genoa_winder_description}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                <span className="col-4">
                                  {t(
                                    'pages.boat.display.sailsRiggingFittings.rigging.furlingMainsail',
                                  )}
                                  {rigging.furling_mainsail ? 'Yes' : 'No'}
                                </span>
                                {rigging.furling_mainsail && (
                                  <span className="col-8">
                                    {rigging.furling_mainsail_description}
                                  </span>
                                )}
                              </Row>
                              <Row>
                                <span className="col-4">
                                  {t(
                                    'pages.boat.display.sailsRiggingFittings.rigging.lazyBag',
                                  )}
                                  {rigging.lazy_bag ? 'Yes' : 'No'}
                                </span>
                                <span className="col-4">
                                  {t(
                                    'pages.boat.display.sailsRiggingFittings.rigging.lazyJack',
                                  )}
                                  {rigging.lazy_jacks ? 'Yes' : 'No'}
                                </span>
                              </Row>
                            </Row>
                          </React.Fragment>
                        )}
                        {fittings && (
                          <React.Fragment>
                            <span className="accordion__subtitle">
                              {t(
                                'pages.boat.display.sailsRiggingFittings.fittings.title',
                              )}
                            </span>
                            <Row>
                              <span className="col-3">
                                {t(
                                  'pages.boat.display.sailsRiggingFittings.fittings.spinnaker',
                                )}
                                {fittings.spinnaker ? 'Yes' : 'No'}
                              </span>
                              <span className="col-3">
                                {t(
                                  'pages.boat.display.sailsRiggingFittings.fittings.releasableStay',
                                )}
                                {fittings.releasable_stay ? 'Yes' : 'No'}
                              </span>
                              <span className="col-3">
                                {t(
                                  'pages.boat.display.sailsRiggingFittings.fittings.hydraulicPowerStation',
                                )}
                                {fittings.hydraulic_power_station
                                  ? 'Yes'
                                  : 'No'}
                              </span>
                            </Row>
                            <Row>
                              <span className="col-3">
                                {t(
                                  'pages.boat.display.sailsRiggingFittings.fittings.rodKicker',
                                )}
                                {fittings.rod_kicker ? 'Yes' : 'No'}
                              </span>
                              <span className="col-3">
                                {t(
                                  'pages.boat.display.sailsRiggingFittings.fittings.spinnakerBoom',
                                )}
                                {fittings.spinnaker_boom ? 'Yes' : 'No'}
                              </span>
                              <span className="col-3">
                                {t(
                                  'pages.boat.display.sailsRiggingFittings.fittings.jibboom',
                                )}
                                {fittings.jibboom ? 'Yes' : 'No'}
                              </span>
                              <span className="col-3">
                                {t(
                                  'pages.boat.display.sailsRiggingFittings.fittings.electricWinch',
                                )}
                                {fittings.electric_winch ? 'Yes' : 'No'}
                              </span>
                            </Row>
                            <Row>
                              {fittings.description && (
                                <span className="col-12">
                                  {t(
                                    'pages.boat.display.sailsRiggingFittings.fittings.description',
                                  )}
                                  {fittings.description}
                                </span>
                              )}
                            </Row>
                          </React.Fragment>
                        )}
                      </Row>
                    )}
                  </section>
                </Accordion.Header>
                {user && (
                  <Accordion.Body className="accordion__update">
                    <Row>
                      {sailsUpdate &&
                        sailsUpdate.map((element, index) => {
                          return (
                            <Row key={index}>
                              <Col className="update__canDelete">
                                <span className="accordion__subtitle">
                                  {t(
                                    'pages.boat.edit.sailsRiggingFittings.sails.title',
                                  )}
                                  {index + 1}
                                </span>
                                <Button
                                  variant="danger"
                                  onClick={() => deleteSail(element.id)}
                                  className="canDelete__button"
                                >
                                  <FaRegTrashAlt />
                                </Button>
                              </Col>
                              <Row className="mb-3">
                                <Form.Group as={Col} className="update__label">
                                  <Form.Label column md={2}>
                                    {t(
                                      'pages.boat.edit.sailsRiggingFittings.sails.type',
                                    )}
                                  </Form.Label>
                                  <Form.Select
                                    value={element.id_sail_type}
                                    onChange={(e) =>
                                      updateSailsField(e, index, 'id_sail_type')
                                    }
                                  >
                                    {sailsTypes.map((element, index) => {
                                      return (
                                        <option key={index} value={element.id}>
                                          {t(element.tag)}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} className="update__label">
                                  <Form.Label column md={2}>
                                    {t(
                                      'pages.boat.edit.sailsRiggingFittings.sails.brand',
                                    )}
                                  </Form.Label>
                                  <Form.Control
                                    placeholder={t(
                                      'pages.boat.edit.sailsRiggingFittings.sails.brand',
                                    )}
                                    defaultValue={element.brand}
                                    onChange={(e) =>
                                      updateSailsField(e, index, 'brand')
                                    }
                                  />
                                </Form.Group>
                                <Form.Group as={Col} className="update__label">
                                  <Form.Label column md={2}>
                                    {t(
                                      'pages.boat.edit.sailsRiggingFittings.sails.area',
                                    )}
                                  </Form.Label>
                                  <InputGroup>
                                    <Form.Control
                                      placeholder={t(
                                        'pages.boat.edit.sailsRiggingFittings.sails.area',
                                      )}
                                      defaultValue={element.area}
                                      onChange={(e) =>
                                        updateSailsField(e, index, 'area')
                                      }
                                    />
                                    <InputGroup.Text>m²</InputGroup.Text>
                                  </InputGroup>
                                </Form.Group>
                              </Row>
                              <Row className="mb-3">
                                <Form.Group
                                  as={Col}
                                  md={2}
                                  className="update__label"
                                >
                                  <Form.Label column md={4}>
                                    {t(
                                      'pages.boat.edit.sailsRiggingFittings.sails.year',
                                    )}
                                  </Form.Label>
                                  <Form.Control
                                    placeholder={t(
                                      'pages.boat.edit.sailsRiggingFittings.sails.year',
                                    )}
                                    defaultValue={element.year}
                                    onChange={(e) =>
                                      updateSailsField(e, index, 'year')
                                    }
                                  />
                                </Form.Group>
                                <Form.Group as={Col} className="update__label">
                                  <Form.Label column>
                                    {t(
                                      'pages.boat.edit.sailsRiggingFittings.sails.description',
                                    )}
                                  </Form.Label>
                                  <Form.Control
                                    placeholder={t(
                                      'pages.boat.edit.sailsRiggingFittings.sails.description',
                                    )}
                                    defaultValue={element.description}
                                    onChange={(e) =>
                                      updateSailsField(e, index, 'description')
                                    }
                                  />
                                </Form.Group>
                              </Row>
                            </Row>
                          );
                        })}
                      <Row>
                        {addSails.map((element, index) => {
                          return (
                            <AddSail
                              key={index}
                              sailsTypes={sailsTypes}
                              sail={element}
                              deleteItem={deleteObjectSail}
                            />
                          );
                        })}
                        <Button
                          onClick={addNewSail}
                          className="update__addButton"
                        >
                          <IoMdAddCircleOutline />
                          <span>
                            {t(
                              'pages.boat.edit.sailsRiggingFittings.sails.addNew',
                            )}
                          </span>
                        </Button>
                      </Row>
                      <React.Fragment>
                        <span className="row accordion__subtitle">
                          {t(
                            'pages.boat.edit.sailsRiggingFittings.rigging.title',
                          )}
                        </span>
                        <Row className="mb-3">
                          <Form.Group as={Col} md={2} className="update__label">
                            <Form.Check
                              type="checkbox"
                              label={t(
                                'pages.boat.edit.sailsRiggingFittings.rigging.genoaWinder',
                              )}
                              checked={riggingUpdate?.genoa_winder || false}
                              onChange={(e) =>
                                setRiggingUpdate({
                                  ...riggingUpdate,
                                  genoa_winder: e.target.checked,
                                })
                              }
                              className="container__checkbox"
                            />
                          </Form.Group>
                          <Form.Group as={Col} className="update__label">
                            <Form.Control
                              placeholder={t(
                                'pages.boat.edit.sailsRiggingFittings.rigging.description',
                              )}
                              disabled={!riggingUpdate?.genoa_winder}
                              defaultValue={
                                riggingUpdate?.genoa_winder_description
                              }
                              onChange={(e) =>
                                setRiggingUpdate({
                                  ...riggingUpdate,
                                  genoa_winder_description: e.target.value,
                                })
                              }
                            />
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} md={2} className="update__label">
                            <Form.Check
                              type="checkbox"
                              label={t(
                                'pages.boat.edit.sailsRiggingFittings.rigging.furlingMainsail',
                              )}
                              checked={riggingUpdate?.furling_mainsail || false}
                              onChange={(e) =>
                                setRiggingUpdate({
                                  ...riggingUpdate,
                                  furling_mainsail: e.target.checked,
                                })
                              }
                              className="container__checkbox"
                            />
                          </Form.Group>
                          <Form.Group as={Col} className="update__label">
                            <Form.Control
                              placeholder={t(
                                'pages.boat.edit.sailsRiggingFittings.rigging.description',
                              )}
                              disabled={!riggingUpdate?.furling_mainsail}
                              defaultValue={
                                riggingUpdate?.furling_mainsail_description
                              }
                              onChange={(e) =>
                                setRiggingUpdate({
                                  ...riggingUpdate,
                                  furling_mainsail_description: e.target.value,
                                })
                              }
                            />
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group className="category__container mb-3">
                            <Form.Check
                              type="checkbox"
                              label={t(
                                'pages.boat.edit.sailsRiggingFittings.rigging.lazyBag',
                              )}
                              checked={riggingUpdate?.lazy_bag || false}
                              onChange={(e) =>
                                setRiggingUpdate({
                                  ...riggingUpdate,
                                  lazy_bag: e.target.checked,
                                })
                              }
                              className="container__checkbox"
                            />
                            <Form.Check
                              type="checkbox"
                              label={t(
                                'pages.boat.edit.sailsRiggingFittings.rigging.lazyJack',
                              )}
                              checked={riggingUpdate?.lazy_jacks || false}
                              onChange={(e) =>
                                setRiggingUpdate({
                                  ...riggingUpdate,
                                  lazy_jacks: e.target.checked,
                                })
                              }
                              className="container__checkbox"
                            />
                          </Form.Group>
                        </Row>
                      </React.Fragment>
                      <React.Fragment>
                        <span className="row accordion__subtitle">
                          {t(
                            'pages.boat.edit.sailsRiggingFittings.fittings.title',
                          )}
                        </span>
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            className="category__container mb-3"
                          >
                            <Form.Check
                              type="checkbox"
                              label={t(
                                'pages.boat.edit.sailsRiggingFittings.fittings.spinnaker',
                              )}
                              checked={fittingsUpdate?.spinnaker || false}
                              onChange={(e) =>
                                setFittingsUpdate({
                                  ...fittingsUpdate,
                                  spinnaker: e.target.checked,
                                })
                              }
                              className="container__checkbox"
                            />
                            <Form.Check
                              type="checkbox"
                              label={t(
                                'pages.boat.edit.sailsRiggingFittings.fittings.releasableStay',
                              )}
                              checked={fittingsUpdate?.releasable_stay || false}
                              onChange={(e) =>
                                setFittingsUpdate({
                                  ...fittingsUpdate,
                                  releasable_stay: e.target.checked,
                                })
                              }
                              className="container__checkbox"
                            />
                            <Form.Check
                              type="checkbox"
                              label={t(
                                'pages.boat.edit.sailsRiggingFittings.fittings.hydraulicPowerStation',
                              )}
                              checked={
                                fittingsUpdate?.hydraulic_power_station || false
                              }
                              onChange={(e) =>
                                setFittingsUpdate({
                                  ...fittingsUpdate,
                                  hydraulic_power_station: e.target.checked,
                                })
                              }
                              className="container__checkbox"
                            />
                          </Form.Group>
                          <Form.Group className="category__container mb-3">
                            <Form.Check
                              type="checkbox"
                              label={t(
                                'pages.boat.edit.sailsRiggingFittings.fittings.rodKicker',
                              )}
                              checked={fittingsUpdate?.rod_kicker || false}
                              onChange={(e) =>
                                setFittingsUpdate({
                                  ...fittingsUpdate,
                                  rod_kicker: e.target.checked,
                                })
                              }
                              className="container__checkbox"
                            />
                            <Form.Check
                              type="checkbox"
                              label={t(
                                'pages.boat.edit.sailsRiggingFittings.fittings.spinnakerBoom',
                              )}
                              checked={fittingsUpdate?.spinnaker_boom || false}
                              onChange={(e) =>
                                setFittingsUpdate({
                                  ...fittingsUpdate,
                                  spinnaker_boom: e.target.checked,
                                })
                              }
                              className="container__checkbox"
                            />
                            <Form.Check
                              type="checkbox"
                              label={t(
                                'pages.boat.edit.sailsRiggingFittings.fittings.jibboom',
                              )}
                              checked={fittingsUpdate?.jibboom || false}
                              onChange={(e) =>
                                setFittingsUpdate({
                                  ...fittingsUpdate,
                                  jibboom: e.target.checked,
                                })
                              }
                              className="container__checkbox"
                            />
                            <Form.Check
                              type="checkbox"
                              label={t(
                                'pages.boat.edit.sailsRiggingFittings.fittings.electricWinch',
                              )}
                              checked={fittingsUpdate?.electric_winch || false}
                              onChange={(e) =>
                                setFittingsUpdate({
                                  ...fittingsUpdate,
                                  electric_winch: e.target.checked,
                                })
                              }
                              className="container__checkbox"
                            />
                          </Form.Group>
                        </Row>
                      </React.Fragment>
                    </Row>
                  </Accordion.Body>
                )}
              </Accordion.Item>
            )}
            <Accordion.Item eventKey="7">
              <Accordion.Header onClick={() => handleHeader(7)} as="div">
                <section className="accordion__header">
                  <span className="accordion__title">
                    {t('pages.boat.display.equipments.title')}
                  </span>
                  <hr />
                  {isDisplay['7'] && (
                    <Row>
                      <Row>
                        <span className="col-4">
                          {t('pages.boat.display.equipments.tender')}
                          {equipments?.tender ? 'Yes' : 'No'}
                        </span>
                        {equipments?.tender && (
                          <span className="col-8">
                            {equipments.tender_description}
                          </span>
                        )}
                      </Row>
                      <Row>
                        <span className="col-4">
                          {t('pages.boat.display.equipments.tenderEngine')}
                          {equipments?.tender_motor ? 'Yes' : 'No'}
                        </span>
                        {equipments?.tender_motor && (
                          <span className="col-8">
                            {equipments.tender_motor_description}
                          </span>
                        )}
                      </Row>
                      <Row>
                        <span className="col-4">
                          {t('pages.boat.display.equipments.davits')}
                          {equipments?.davits ? 'Yes' : 'No'}
                        </span>
                        {equipments?.davits && (
                          <span className="col-8">
                            {equipments.davits_description}
                          </span>
                        )}
                      </Row>
                      <Row>
                        <span className="col-4">
                          {t('pages.boat.display.equipments.loadMast')}
                          {equipments?.load_mast ? 'Yes' : 'No'}
                        </span>
                        {equipments?.load_mast && (
                          <span className="col-8">
                            {equipments.load_mast_description}
                          </span>
                        )}
                      </Row>
                      <Row>
                        <span className="col-4">
                          {t('pages.boat.display.equipments.swimLadder')}
                          {equipments?.swim_ladder ? 'Yes' : 'No'}
                        </span>
                        {equipments?.swim_ladder && (
                          <span className="col-8">
                            {equipments.swim_ladder_description}
                          </span>
                        )}
                      </Row>
                      <Row>
                        <span className="col-2">
                          {t('pages.boat.display.equipments.gangway')}
                          {equipments?.gangway ? 'Yes' : 'No'}
                        </span>
                        <span className="col-2">
                          {equipments?.hydraulic_gangway &&
                            `${t('pages.boat.display.equipments.hydraulic')}`}
                        </span>
                        {equipments?.gangway && (
                          <span className="col-8">
                            {equipments.gangway_description}
                          </span>
                        )}
                      </Row>
                      <Row>
                        <span className="col-4">
                          {t('pages.boat.display.equipments.trailer')}
                          {equipments?.trailer ? 'Yes' : 'No'}
                        </span>
                        {equipments?.trailer && (
                          <span className="col-8">
                            {equipments.trailer_description}
                          </span>
                        )}
                      </Row>
                    </Row>
                  )}
                </section>
              </Accordion.Header>
              {user && (
                <Accordion.Body className="accordion__update">
                  <Row>
                    <Form.Group className="category__container mb-3">
                      <Form.Check
                        type="checkbox"
                        label={t('pages.boat.edit.equipments.tender')}
                        checked={equipmentsUpdate?.tender || false}
                        onChange={(e) =>
                          setEquipmentsUpdate({
                            ...equipmentsUpdate,
                            tender: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                      <Form.Control
                        as="textarea"
                        rows={1}
                        disabled={!equipmentsUpdate?.tender}
                        value={equipmentsUpdate?.tender_description || ''}
                        onChange={(e) =>
                          setEquipmentsUpdate({
                            ...equipmentsUpdate,
                            tender_description: e.target.value,
                          })
                        }
                        className="container__description"
                      />
                    </Form.Group>
                    <Form.Group className="category__container mb-3">
                      <Form.Check
                        type="checkbox"
                        label={t('pages.boat.edit.equipments.tenderEngine')}
                        checked={equipmentsUpdate?.tender_motor || false}
                        onChange={(e) =>
                          setEquipmentsUpdate({
                            ...equipmentsUpdate,
                            tender_motor: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                      <Form.Control
                        as="textarea"
                        rows={1}
                        disabled={!equipmentsUpdate?.tender_motor}
                        value={equipmentsUpdate?.tender_motor_description || ''}
                        onChange={(e) =>
                          setEquipmentsUpdate({
                            ...equipmentsUpdate,
                            tender_motor_description: e.target.value,
                          })
                        }
                        className="container__description"
                      />
                    </Form.Group>
                    <Form.Group className="category__container mb-3">
                      <Form.Check
                        type="checkbox"
                        label={t('pages.boat.edit.equipments.davits')}
                        checked={equipmentsUpdate?.davits || false}
                        onChange={(e) =>
                          setEquipmentsUpdate({
                            ...equipmentsUpdate,
                            davits: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                      <Form.Control
                        as="textarea"
                        rows={1}
                        disabled={!equipmentsUpdate?.davits}
                        value={equipmentsUpdate?.davits_description || ''}
                        onChange={(e) =>
                          setEquipmentsUpdate({
                            ...equipmentsUpdate,
                            davits_description: e.target.value,
                          })
                        }
                        className="container__description"
                      />
                    </Form.Group>
                    <Form.Group className="category__container mb-3">
                      <Form.Check
                        type="checkbox"
                        label={t('pages.boat.edit.equipments.loadMast')}
                        checked={equipmentsUpdate?.load_mast || false}
                        onChange={(e) =>
                          setEquipmentsUpdate({
                            ...equipmentsUpdate,
                            load_mast: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                      <Form.Control
                        as="textarea"
                        rows={1}
                        disabled={!equipmentsUpdate?.load_mast}
                        value={equipmentsUpdate?.load_mast_description || ''}
                        onChange={(e) =>
                          setEquipmentsUpdate({
                            ...equipmentsUpdate,
                            load_mast_description: e.target.value,
                          })
                        }
                        className="container__description"
                      />
                    </Form.Group>
                    <Form.Group className="category__container mb-3">
                      <Form.Check
                        type="checkbox"
                        label={t('pages.boat.edit.equipments.swimLadder')}
                        checked={equipmentsUpdate?.swim_ladder || false}
                        onChange={(e) =>
                          setEquipmentsUpdate({
                            ...equipmentsUpdate,
                            swim_ladder: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                      <Form.Control
                        as="textarea"
                        rows={1}
                        disabled={!equipmentsUpdate?.swim_ladder}
                        value={equipmentsUpdate?.swim_ladder_description || ''}
                        onChange={(e) =>
                          setEquipmentsUpdate({
                            ...equipmentsUpdate,
                            swim_ladder_description: e.target.value,
                          })
                        }
                        className="container__description"
                      />
                    </Form.Group>
                    <Form.Group className="category__container mb-3">
                      <Form.Check
                        type="checkbox"
                        label={t('pages.boat.edit.equipments.gangway')}
                        checked={equipmentsUpdate?.gangway || false}
                        onChange={(e) =>
                          setEquipmentsUpdate({
                            ...equipmentsUpdate,
                            gangway: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                      <Form.Control
                        as="textarea"
                        rows={1}
                        disabled={!equipmentsUpdate?.gangway}
                        value={equipmentsUpdate?.gangway_description || ''}
                        onChange={(e) =>
                          setEquipmentsUpdate({
                            ...equipmentsUpdate,
                            gangway_description: e.target.value,
                          })
                        }
                        className="container__description"
                      />
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label={t(
                            'pages.boat.edit.equipments.hydraulicGangway',
                          )}
                          checked={equipmentsUpdate?.hydraulic_gangway || false}
                          onChange={(e) =>
                            setEquipmentsUpdate({
                              ...equipmentsUpdate,
                              hydraulic_gangway: e.target.checked,
                            })
                          }
                          className="container__checkbox"
                        />
                      </Form.Group>
                    </Form.Group>
                    <Form.Group className="category__container mb-3">
                      <Form.Check
                        type="checkbox"
                        label={t('pages.boat.edit.equipments.trailer')}
                        checked={equipmentsUpdate?.trailer || false}
                        onChange={(e) =>
                          setEquipmentsUpdate({
                            ...equipmentsUpdate,
                            trailer: e.target.checked,
                          })
                        }
                        className="container__checkbox"
                      />
                      <Form.Control
                        as="textarea"
                        rows={1}
                        disabled={!equipmentsUpdate?.trailer}
                        value={equipmentsUpdate?.trailer_description || ''}
                        onChange={(e) =>
                          setEquipmentsUpdate({
                            ...equipmentsUpdate,
                            trailer_description: e.target.value,
                          })
                        }
                        className="container__description"
                      />
                    </Form.Group>
                  </Row>
                </Accordion.Body>
              )}
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header onClick={() => handleHeader(8)} as="div">
                <section className="accordion__header">
                  <span className="accordion__title">
                    {t('pages.boat.display.security.title')}
                  </span>
                  <hr />
                  {isDisplay['8'] && (
                    <React.Fragment>
                      {security && (
                        <React.Fragment>
                          <Row>
                            <span className="col-4">
                              {t('pages.boat.display.security.equipment')}
                              {security.equipment}
                            </span>
                            <span className="col-4">
                              {t('pages.boat.display.security.survival')}
                              {security.survival ? 'Yes' : 'No'}
                            </span>
                            {security.survival_revision && (
                              <span className="col-4">
                                {t('pages.boat.display.security.revision')}
                                {security.survival_revision}
                              </span>
                            )}
                          </Row>
                          <Row>
                            {security.survival_description && (
                              <span className="col-12">
                                {t('pages.boat.display.security.description')}
                                {security.survival_description}
                              </span>
                            )}
                          </Row>
                        </React.Fragment>
                      )}
                      {securityCategory.length > 0 && (
                        <Row>
                          <span>
                            {t('pages.boat.display.security.category')}
                            {securityCategory.map((element, index) => {
                              return (
                                <React.Fragment key={index}>
                                  {element.category}-{element.quantity_people}{' '}
                                </React.Fragment>
                              );
                            })}
                          </span>
                        </Row>
                      )}
                    </React.Fragment>
                  )}
                </section>
              </Accordion.Header>
              {user && (
                <Accordion.Body className="accordion__update">
                  <Row>
                    <React.Fragment>
                      <Row className="mb-3">
                        <Form.Group as={Col} className="update__label">
                          <Form.Label column md={2}>
                            {t('pages.boat.edit.security.equipments')}
                          </Form.Label>
                          <Form.Select
                            value={securityUpdate?.id_equipment}
                            onChange={(e) =>
                              setSecurityUpdate({
                                ...securityUpdate,
                                id_equipment: e.target.value,
                              })
                            }
                          >
                            {securityEquipment?.map((element, index) => {
                              return (
                                <option key={index} value={element.id}>
                                  {element.value}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md={2} className="update__label">
                          <Form.Check
                            type="checkbox"
                            label={t('pages.boat.edit.security.survival')}
                            checked={securityUpdate?.survival || false}
                            onChange={(e) =>
                              setSecurityUpdate({
                                ...securityUpdate,
                                survival: e.target.checked,
                              })
                            }
                            className="container__checkbox"
                          />
                        </Form.Group>
                        <Form.Group as={Col} md={3} className="update__label">
                          <Form.Label column md={4}>
                            {t('pages.boat.edit.security.revision')}
                          </Form.Label>
                          <Form.Control
                            placeholder={t('pages.boat.edit.security.revision')}
                            disabled={!securityUpdate?.survival}
                            defaultValue={securityUpdate?.survival_revision}
                            onChange={(e) =>
                              setSecurityUpdate({
                                ...securityUpdate,
                                survival_revision: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} className="update__label">
                          <Form.Control
                            placeholder={t(
                              'pages.boat.edit.security.description',
                            )}
                            disabled={!securityUpdate?.survival}
                            defaultValue={securityUpdate?.survival_description}
                            onChange={(e) =>
                              setSecurityUpdate({
                                ...securityUpdate,
                                survival_description: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                      </Row>
                    </React.Fragment>
                    {securityCategoryList && (
                      <Row className="d-flex justify-content-center">
                        <Row className="mb-3">
                          <Form.Label column md={2}>
                            {t('pages.boat.edit.security.category')}
                          </Form.Label>
                          <Table>
                            <thead>
                              <tr>
                                {securityCategoryList
                                  .sort((a, b) => a.id - b.id)
                                  .map((element, index) => {
                                    return <th key={index}>{element.value}</th>;
                                  })}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {securityCategoryList.map((element, index) => {
                                  return (
                                    <td key={index}>
                                      <Form.Control
                                        defaultValue={
                                          securityCertifUpdate
                                            ? securityCertifUpdate.find(
                                                (item) =>
                                                  item.id_category ===
                                                  element.id,
                                              ) &&
                                              securityCertifUpdate.find(
                                                (item) =>
                                                  item.id_category ===
                                                  element.id,
                                              ).quantity_people
                                            : null
                                        }
                                        onChange={(e) =>
                                          updateCategoryField(
                                            element.id,
                                            e.target.value,
                                          )
                                        }
                                      />
                                    </td>
                                  );
                                })}
                              </tr>
                            </tbody>
                          </Table>
                        </Row>
                      </Row>
                    )}
                  </Row>
                </Accordion.Body>
              )}
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header onClick={() => handleHeader(9)} as="div">
                <section className="accordion__header">
                  <span className="accordion__title">
                    {t('pages.boat.display.ads.title')}
                  </span>
                  <hr />
                  {isDisplay['9'] && (
                    <React.Fragment>
                      {ads && (
                        <React.Fragment>
                          <Row>
                            <span>{t('pages.boat.display.ads.frenchAds')}</span>
                            <span>{ads?.fr_text}</span>
                          </Row>
                          <Row>
                            <span>
                              {t('pages.boat.display.ads.englishAds')}
                            </span>
                            <span>{ads?.en_text}</span>
                          </Row>
                          <Row>
                            <span>
                              {ads?.link_youtube &&
                                `${t('pages.boat.display.ads.youtube')}${
                                  ads?.link_youtube
                                }`}
                            </span>
                          </Row>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </section>
              </Accordion.Header>
              {user && (
                <Accordion.Body className="accordion__update">
                  <Row>
                    <Form.Group className="category__container mb-3">
                      <Form.Control
                        as="textarea"
                        placeholder={t('pages.boat.edit.ads.frenchAds')}
                        rows={3}
                        defaultValue={adsUpdate?.fr_text || ''}
                        onChange={(e) =>
                          setAdsUpdate({
                            ...adsUpdate,
                            fr_text: e.target.value,
                          })
                        }
                        className="container__description"
                      />
                    </Form.Group>
                    <Form.Group className="category__container mb-3">
                      <Form.Control
                        as="textarea"
                        placeholder={t('pages.boat.edit.ads.englishAds')}
                        rows={3}
                        defaultValue={adsUpdate?.en_text || ''}
                        onChange={(e) =>
                          setAdsUpdate({
                            ...adsUpdate,
                            en_text: e.target.value,
                          })
                        }
                        className="container__description"
                      />
                    </Form.Group>
                    <Form.Group className="category__container mb-3">
                      <Form.Control
                        placeholder={t('pages.boat.edit.ads.youtube')}
                        defaultValue={adsUpdate?.link_youtube}
                        onChange={(e) =>
                          setAdsUpdate({
                            ...adsUpdate,
                            link_youtube: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>
                </Accordion.Body>
              )}
            </Accordion.Item>
            {(boat || auth.includes(featureBoatInfosSupp)) && (
              <Accordion.Item eventKey="10">
                <Accordion.Header as="div">
                  <section className="accordion__header">
                    <span className="accordion__title">
                      {t('pages.boat.display.observations')}
                    </span>
                    <hr />
                  </section>
                </Accordion.Header>
                <Accordion.Body>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    defaultValue={boat?.observations}
                    onChange={(e) => {
                      setBoatUpdate({
                        ...boatUpdate,
                        observations: e.target.value,
                      });
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
            )}
            {(user || auth.includes(featureBoatInfosSupp)) && (
              <Accordion.Item eventKey="11">
                <Accordion.Header as="div">
                  <section className="accordion__header">
                    <span className="accordion__title">
                      {t('pages.boat.edit.people.title')}
                    </span>
                    <hr />
                  </section>
                </Accordion.Header>
                <Accordion.Body>
                  <Row className="mb-3">
                    <span>{t('pages.boat.edit.people.owner')}</span>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>{t('pages.boat.edit.people.lastname')}</th>
                          <th>{t('pages.boat.edit.people.firstname')}</th>
                          <th>{t('pages.boat.edit.people.company')}</th>
                          <th>{t('pages.boat.edit.people.phone')}</th>
                          <th>{t('pages.boat.edit.people.shares')}</th>
                          <th>{t('pages.boat.edit.people.privileged')}</th>
                          <th>{t('pages.boat.edit.people.commission')}</th>
                          <th>{t('pages.boat.edit.people.delete')}</th>
                        </tr>
                      </thead>
                      {(person.length > 0 || addingOwner.length > 0) && (
                        <tbody>
                          {person &&
                            person
                              .map((pers) => {
                                const boatInfo = people?.find(
                                  (pb) => pb.id_people === pers.id,
                                );
                                return {
                                  ...pers,
                                  status: boatInfo.id_status || null,
                                  shares: boatInfo.shares || null,
                                  contact: boatInfo.contact || 0,
                                  commission: boatInfo.commission / 100 || null,
                                };
                              })
                              .filter((x) => x.status === 1)
                              .map((element, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <Link
                                        to={`/people/${element.id}`}
                                        className="text-decoration-none text-black"
                                      >
                                        {element.lastname}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/people/${element.id}`}
                                        className="text-decoration-none text-black"
                                      >
                                        {element.firstname}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/people/${element.id}`}
                                        className="text-decoration-none text-black"
                                      >
                                        {element.company}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/people/${element.id}`}
                                        className="text-decoration-none text-black"
                                      >
                                        {element.phone}
                                      </Link>
                                    </td>
                                    <td>
                                      <InputGroup size="sm">
                                        <Form.Control
                                          size="sm"
                                          defaultValue={element.shares}
                                          onChange={(e) =>
                                            handleUpdatePeople(
                                              element.id,
                                              'shares',
                                              Number(e.target.value),
                                            )
                                          }
                                        />
                                        <InputGroup.Text>%</InputGroup.Text>
                                      </InputGroup>
                                    </td>
                                    <td>
                                      <Button
                                        size="sm"
                                        defaultValue={element.contact}
                                        variant={
                                          element.contact ? 'success' : 'danger'
                                        }
                                        onClick={() =>
                                          handleUpdatePeople(
                                            element.id,
                                            'contact',
                                            element.contact ? 0 : 1,
                                          )
                                        }
                                      >
                                        {element.contact ? 'Yes' : 'No'}
                                      </Button>
                                    </td>
                                    <td>
                                      <InputGroup size="sm">
                                        <Form.Control
                                          size="sm"
                                          defaultValue={element.commission}
                                          onChange={(e) =>
                                            handleUpdatePeople(
                                              element.id,
                                              'commission',
                                              Number(e.target.value),
                                            )
                                          }
                                        />
                                        <InputGroup.Text>%</InputGroup.Text>
                                      </InputGroup>
                                    </td>
                                    <td>
                                      <Button
                                        size="sm"
                                        variant="danger"
                                        onClick={() =>
                                          handleDeletePeople(element.id)
                                        }
                                      >
                                        <FaRegTrashAlt />
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              })}
                          {addingOwner?.map((element, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <Link
                                    to={`/people/${element.id}`}
                                    className="text-decoration-none text-black"
                                  >
                                    {element.lastname}
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={`/people/${element.id}`}
                                    className="text-decoration-none text-black"
                                  >
                                    {element.firstname}
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={`/people/${element.id}`}
                                    className="text-decoration-none text-black"
                                  >
                                    {element.company}
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={`/people/${element.id}`}
                                    className="text-decoration-none text-black"
                                  >
                                    {element.phone}
                                  </Link>
                                </td>
                                <td>
                                  <Form.Control
                                    size="sm"
                                    defaultValue={element.shares}
                                    onChange={(e) =>
                                      handleUpdatePeople(
                                        element.id,
                                        'shares',
                                        Number(e.target.value),
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <Button
                                    size="sm"
                                    defaultValue={element.contact}
                                    variant={
                                      element.contact ? 'success' : 'danger'
                                    }
                                    onClick={() =>
                                      handleUpdatePeople(
                                        element.id,
                                        'contact',
                                        element.contact ? 0 : 1,
                                      )
                                    }
                                  >
                                    {element.contact ? 'Yes' : 'No'}
                                  </Button>
                                </td>
                                <td>
                                  <InputGroup size="sm">
                                    <Form.Control
                                      size="sm"
                                      defaultValue={element.commission / 100}
                                      onChange={(e) =>
                                        handleUpdatePeople(
                                          element.id,
                                          'commission',
                                          Number(e.target.value),
                                        )
                                      }
                                    />
                                    <InputGroup.Text>%</InputGroup.Text>
                                  </InputGroup>
                                </td>
                                <td>
                                  <Button
                                    size="sm"
                                    variant="danger"
                                    onClick={() =>
                                      handleDeletePeople(element.id)
                                    }
                                  >
                                    <FaRegTrashAlt />
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </Table>
                    <hr />
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>{t('pages.boat.edit.people.lastname')}</th>
                          <th>{t('pages.boat.edit.people.firstname')}</th>
                          <th>{t('pages.boat.edit.people.company')}</th>
                          <th>
                            {t('pages.boat.edit.people.publicPriceShares')}
                          </th>
                          <th>{t('pages.boat.edit.people.netPriceShares')}</th>
                          <th>{t('pages.boat.edit.people.commission')}</th>
                        </tr>
                      </thead>
                      {details && person.length > 0 && (
                        <tbody>
                          {person &&
                            person
                              .map((pers) => {
                                const boatInfo = people?.find(
                                  (pb) => pb.id_people === pers.id,
                                );
                                return {
                                  ...pers,
                                  status: boatInfo?.id_status || null,
                                  shares: boatInfo?.shares || null,
                                  contact: boatInfo?.contact || 0,
                                  commission:
                                    boatInfo?.commission / 10000 || null,
                                };
                              })
                              .filter((x) => x.status === 1)
                              .map((element, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <Link
                                        to={`/people/${element.id}`}
                                        className="text-decoration-none text-black"
                                      >
                                        {element.lastname}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/people/${element.id}`}
                                        className="text-decoration-none text-black"
                                      >
                                        {element.firstname}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/people/${element.id}`}
                                        className="text-decoration-none text-black"
                                      >
                                        {element.company}
                                      </Link>
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('fr-FR', {
                                        style: 'currency',
                                        currency: 'EUR',
                                        maximumFractionDigits: 0,
                                      }).format(
                                        (details.price * element.shares) / 100,
                                      )}
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('fr-FR', {
                                        style: 'currency',
                                        currency: 'EUR',
                                        maximumFractionDigits: 0,
                                      }).format(
                                        ((details.price -
                                          details.price * element.commission) *
                                          element.shares) /
                                          100,
                                      )}
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('fr-FR', {
                                        style: 'currency',
                                        currency: 'EUR',
                                        maximumFractionDigits: 0,
                                      }).format(
                                        (details.price *
                                          element.commission *
                                          element.shares) /
                                          100,
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                        </tbody>
                      )}
                    </Table>
                    <hr />
                    <span>{t('pages.boat.edit.people.leads')}</span>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>{t('pages.boat.edit.people.lastname')}</th>
                          <th>{t('pages.boat.edit.people.firstname')}</th>
                          <th>{t('pages.boat.edit.people.company')}</th>
                          <th>{t('pages.boat.edit.people.email')}</th>
                          <th>{t('pages.boat.edit.people.phone')}</th>
                          <th>{t('pages.boat.edit.people.delete')}</th>
                        </tr>
                      </thead>
                      {(person.length > 0 || addingLead.length > 0) && (
                        <tbody>
                          {person &&
                            person
                              .map((pers) => {
                                const boatInfo = people?.find(
                                  (pb) => pb.id_people === pers.id,
                                );
                                return {
                                  ...pers,
                                  status: boatInfo?.id_status || null,
                                  shares: boatInfo?.shares || null,
                                };
                              })
                              .filter((x) => x.status === 2)
                              .map((element, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <Link
                                        to={`/people/${element.id}`}
                                        className="text-decoration-none text-black"
                                      >
                                        {element.lastname}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/people/${element.id}`}
                                        className="text-decoration-none text-black"
                                      >
                                        {element.firstname}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/people/${element.id}`}
                                        className="text-decoration-none text-black"
                                      >
                                        {element.company}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/people/${element.id}`}
                                        className="text-decoration-none text-black"
                                      >
                                        {element.email}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/people/${element.id}`}
                                        className="text-decoration-none text-black"
                                      >
                                        {element.phone}
                                      </Link>
                                    </td>
                                    <td>
                                      <Button
                                        size="sm"
                                        variant="danger"
                                        onClick={() =>
                                          handleDeletePeople(element.id)
                                        }
                                      >
                                        <FaRegTrashAlt />
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              })}
                          {addingLead?.map((element, index) => (
                            <tr key={index}>
                              <td>
                                <Link
                                  to={`/people/${element.id}`}
                                  className="text-decoration-none text-black"
                                >
                                  {element.lastname}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  to={`/people/${element.id}`}
                                  className="text-decoration-none text-black"
                                >
                                  {element.firstname}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  to={`/people/${element.id}`}
                                  className="text-decoration-none text-black"
                                >
                                  {element.company}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  to={`/people/${element.id}`}
                                  className="text-decoration-none text-black"
                                >
                                  {element.email}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  to={`/people/${element.id}`}
                                  className="text-decoration-none text-black"
                                >
                                  {element.phone}
                                </Link>
                              </td>
                              <td>
                                <Button
                                  size="sm"
                                  variant="danger"
                                  onClick={() => handleDeletePeople(element.id)}
                                >
                                  <FaRegTrashAlt />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </Table>
                    <Row>
                      <Form.Group as={Col} md={7}>
                        <Dropdown className="customDropdown">
                          <Dropdown.Toggle
                            id="dropdown-custom-components"
                            className="customDropdown__button"
                          >
                            {selectedItem
                              ? `${selectedItem.firstname} ${selectedItem.lastname}, ${selectedItem.company} - ${selectedItem.email} - ${selectedItem.phone}`
                              : `${t('pages.boat.edit.people.searchPerson')}`}
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            as={CustomMenu}
                            onSelectItem={handleSelectItem}
                            className="customDropdown__menu"
                          />
                        </Dropdown>
                      </Form.Group>
                      <Form.Group as={Col} md={2}>
                        <Form.Select
                          name="status"
                          value={selectedStatus}
                          onChange={(e) => setSelectedStatus(e.target.value)}
                        >
                          {statusPeople &&
                            statusPeople.map((element, index) => {
                              return (
                                <option key={index} value={element.id}>
                                  {t(element.tag)}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group as={Col} md={2}>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            min={0}
                            max={100}
                            placeholder={t('pages.boat.edit.people.shares')}
                            value={shares}
                            onChange={(e) => setShares(Number(e.target.value))}
                            disabled={selectedStatus !== '1'}
                          />
                          <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} md={1}>
                        <Button
                          variant="success"
                          onClick={handleAddPeople}
                          disabled={isButtonDisabled()}
                        >
                          <MdAddBox />
                        </Button>
                      </Form.Group>
                    </Row>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            )}
            {(user || auth.includes(featureBoatInfosSupp)) && (
              <Accordion.Item eventKey="12">
                <Accordion.Header onClick={() => handleHeader(12)} as="div">
                  <section className="accordion__header">
                    <span className="accordion__title">
                      {t('pages.boat.display.transaction.title')}
                    </span>
                    <hr />
                    {isDisplay['10'] && transactionUpdate && (
                      <div>
                        <div className="transaction__people">
                          <div className="people__person">
                            <span className="person__category">
                              {t('pages.boat.display.transaction.seller')}
                            </span>
                            <hr />
                            {person &&
                              person
                                .map((pers) => {
                                  const boatInfo = people?.find(
                                    (pb) => pb.id_people === pers.id,
                                  );
                                  return {
                                    ...pers,
                                    status: boatInfo.id_status || null,
                                    shares: boatInfo.shares || null,
                                    contact: boatInfo.contact || 0,
                                    commission:
                                      boatInfo.commission / 100 || null,
                                  };
                                })
                                .filter((x) => x.status === 1)
                                .map((element, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="transaction__people"
                                    >
                                      <span>{element.lastname}</span>
                                      <span>{element.firstname}</span>
                                      {element.company && (
                                        <span>{element.company}</span>
                                      )}
                                      <span>{element.phone}</span>
                                      <span>{element.shares} %</span>
                                    </div>
                                  );
                                })}
                          </div>
                          <div className="people__person">
                            <span className="person__category">
                              {t('pages.boat.display.transaction.buyer')}
                            </span>
                            <hr />
                            {person &&
                              person
                                .filter((person) =>
                                  buyerList.some(
                                    (share) => share.id === person.id,
                                  ),
                                )
                                .map((person) => {
                                  const shareInfo = buyerList.find(
                                    (share) => share.id === person.id,
                                  );
                                  return {
                                    ...person,
                                    shares: shareInfo.shares,
                                  };
                                })
                                .map((element, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="transaction__people"
                                    >
                                      <span>{element.lastname}</span>
                                      <span>{element.firstname}</span>
                                      {element.company && (
                                        <span>{element.company}</span>
                                      )}
                                      <span>{element.phone}</span>
                                      <span>{element.shares} %</span>
                                    </div>
                                  );
                                })}
                          </div>
                        </div>
                        <hr />
                        <div className="transactionInfo__container">
                          <div className="container__item">
                            <span className="item__label">
                              {t('pages.boat.display.transaction.priceOffer')}
                            </span>
                            <span className="item__value">
                              {new Intl.NumberFormat('fr-FR', {
                                style: 'currency',
                                currency: 'EUR',
                                maximumFractionDigits: 0,
                              }).format(transactionUpdate.price_offer)}
                            </span>
                          </div>
                          <div className="container__item d-flex">
                            <span className="item__label">
                              {t('pages.boat.display.transaction.commission')}
                            </span>
                            <div className="w-50">
                              <span className="item__value">
                                {/* {(
                                  (transactionUpdate.commission /
                                    transactionUpdate.price_offer) *
                                  100
                                ).toFixed(2)}{' '}
                                % */}
                                10 %
                              </span>
                              <span className="item__value">
                                {new Intl.NumberFormat('fr-FR', {
                                  style: 'currency',
                                  currency: 'EUR',
                                  maximumFractionDigits: 0,
                                }).format(transactionUpdate.price_offer * 0.1)}
                                {/* To update */}
                              </span>
                            </div>
                          </div>
                          <div className="container__item">
                            <span className="item__label">
                              {t('pages.boat.display.transaction.newName')}
                            </span>
                            <span>{transactionUpdate?.new_boat_name}</span>
                          </div>
                          <div className="container__item">
                            <span className="item__label">
                              {t('pages.boat.display.transaction.prepayment')}
                            </span>
                            <span className="item__value">
                              {new Intl.NumberFormat('fr-FR', {
                                style: 'currency',
                                currency: 'EUR',
                                maximumFractionDigits: 0,
                              }).format(transactionUpdate.prepayment)}
                            </span>
                          </div>
                          <div className="container__item">
                            <span className="item__label">
                              {t(
                                'pages.boat.display.transaction.compromiseDate',
                              )}
                            </span>
                            <span className="item__value">
                              {transactionUpdate.start_date_compromise_creation}
                            </span>
                          </div>
                          <div className="container__item">
                            <span className="item__label">
                              {t(
                                'pages.boat.display.transaction.expirationDate',
                              )}
                            </span>
                            <span className="item__value">
                              {transactionUpdate.compromise_expiry_date}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </section>
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="transaction__people">
                      <div className="people__person">
                        <span className="person__category">
                          {t('pages.boat.edit.transaction.seller')}
                        </span>
                        <hr />
                        {person &&
                          person
                            .map((pers) => {
                              const boatInfo = people?.find(
                                (pb) => pb.id_people === pers.id,
                              );
                              return {
                                ...pers,
                                status: boatInfo.id_status || null,
                                shares: boatInfo.shares || null,
                                contact: boatInfo.contact || 0,
                                commission: boatInfo.commission / 100 || null,
                              };
                            })
                            .filter((x) => x.status === 1)
                            .map((element, index) => {
                              return (
                                <div
                                  key={index}
                                  className="transaction__people"
                                >
                                  <span>{element.lastname}</span>
                                  <span>{element.firstname}</span>
                                  {element.company && (
                                    <span>{element.company}</span>
                                  )}
                                  <span>{element.phone}</span>
                                  <span>{element.shares} %</span>
                                </div>
                              );
                            })}
                      </div>
                      <div className="people__person">
                        <span className="person__category">
                          {t('pages.boat.edit.transaction.buyer')}
                        </span>
                        <hr />
                        {transaction ? (
                          <div>
                            {person &&
                              person
                                .filter((person) =>
                                  buyerList.some(
                                    (share) => share.id === person.id,
                                  ),
                                )
                                .map((person) => {
                                  const shareInfo = buyerList.find(
                                    (share) => share.id === person.id,
                                  );
                                  return {
                                    ...person,
                                    shares: shareInfo.shares,
                                  };
                                })
                                .map((element, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="transaction__people"
                                    >
                                      <span>{element.lastname}</span>
                                      <span>{element.firstname}</span>
                                      {element.company && (
                                        <span>{element.company}</span>
                                      )}
                                      <span>{element.phone}</span>
                                      <span>{element.shares} %</span>
                                    </div>
                                  );
                                })}
                          </div>
                        ) : (
                          <React.Fragment>
                            <div className="person__container">
                              {buyerList &&
                                buyerList.map((element, index) => {
                                  return (
                                    <div
                                      className="d-flex justify-content-between"
                                      key={index}
                                    >
                                      <Dropdown
                                        onSelect={(eventKey, e) =>
                                          handleSelectBuyer(eventKey, e, index)
                                        }
                                        className="w-50"
                                      >
                                        <Dropdown.Toggle
                                          variant="outline-primary"
                                          className="w-100"
                                        >
                                          {buyerList[index].id
                                            ? `${buyerList[index].name}`
                                            : `${t(
                                                'pages.boat.edit.transaction.selectBuyer',
                                              )}`}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          {person &&
                                            person
                                              .map((pers) => {
                                                const boatInfo = people?.find(
                                                  (pb) =>
                                                    pb.id_people === pers.id,
                                                );
                                                return {
                                                  ...pers,
                                                  status:
                                                    boatInfo?.id_status || null,
                                                  shares:
                                                    boatInfo?.shares || null,
                                                };
                                              })
                                              .filter((x) => x.status === 2)
                                              .map((element, index) => {
                                                return (
                                                  <Dropdown.Item
                                                    key={index}
                                                    eventKey={element.id}
                                                  >
                                                    {element.lastname}{' '}
                                                    {element.firstname}
                                                  </Dropdown.Item>
                                                );
                                              })}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                      <Form.Group className="w-25">
                                        <InputGroup>
                                          <Form.Control
                                            type="number"
                                            min={0}
                                            max={100}
                                            placeholder={t(
                                              'pages.boat.edit.transaction.shares',
                                            )}
                                            value={buyerList[index].shares}
                                            onChange={(e) =>
                                              handleChangeBuyer(
                                                Number(e.target.value),
                                                index,
                                              )
                                            }
                                          />
                                          <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                      </Form.Group>
                                      <Button
                                        size="sm"
                                        variant="danger"
                                        onClick={() => handleDeleteBuyer(index)}
                                      >
                                        <FaRegTrashAlt />
                                      </Button>
                                    </div>
                                  );
                                })}
                            </div>
                            <Button
                              onClick={addNewBuyer}
                              className="person__addButton"
                            >
                              <IoMdAddCircleOutline />
                              <span>
                                {t('pages.boat.edit.transaction.addNewLead')}
                              </span>
                            </Button>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="transactionInfo__container">
                      <Form.Group className="container__item">
                        <Form.Label className="item__label">
                          {t('pages.boat.edit.transaction.priceOffer')}
                        </Form.Label>
                        <InputGroup className="w-50">
                          <Form.Control
                            size="sm"
                            type="text"
                            name="price_offer"
                            value={transactionUpdate?.price_offer}
                            onChange={handleChangeTransaction}
                          />
                          <InputGroup.Text>€</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <div className="container__item d-flex">
                        <span className="item__label">
                          {t('pages.boat.edit.transaction.commission')}
                        </span>
                        <div className="w-50 d-flex gap-2">
                          <InputGroup>
                            <Form.Control
                              size="sm"
                              type="text"
                              value={10}
                              // To update
                              // onChange={handlePercentageChange}
                              readOnly
                              className="item__value"
                            />
                            <InputGroup.Text>%</InputGroup.Text>
                          </InputGroup>
                          <InputGroup>
                            <Form.Control
                              size="sm"
                              type="text"
                              value={
                                transactionUpdate?.price_offer
                                  ? transactionUpdate.price_offer * 0.1
                                  : boat?.price * 0.1
                              }
                              // onChange={handleMoneyChange}
                              readOnly
                              className="item__value"
                            />
                            <InputGroup.Text>€</InputGroup.Text>
                          </InputGroup>
                        </div>
                      </div>
                      <Form.Group className="container__item">
                        <Form.Label className="item__label">
                          {t('pages.boat.edit.transaction.newName')}
                        </Form.Label>
                        <Form.Control
                          size="sm"
                          type="text"
                          name="new_boat_name"
                          defaultValue={transactionUpdate?.new_boat_name}
                          onChange={handleChangeTransaction}
                          className="item__value"
                        />
                      </Form.Group>
                      <Form.Group className="container__item">
                        <Form.Label className="item__label">
                          {t('pages.boat.edit.transaction.prepayment')}
                        </Form.Label>
                        <InputGroup className="w-50">
                          <Form.Control
                            size="sm"
                            type="text"
                            name="prepayment"
                            value={transactionUpdate?.prepayment}
                            onChange={handleChangeTransaction}
                          />
                          <InputGroup.Text>€</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        controlId="dateExpirationCompromis"
                        className="container__item"
                      >
                        <Form.Label className="item__label">
                          {t('pages.boat.edit.transaction.compromiseDate')}
                        </Form.Label>
                        <Form.Control
                          type="date"
                          size="sm"
                          name="start_date_compromise_creation"
                          value={
                            transactionUpdate?.start_date_compromise_creation
                          }
                          onChange={handleChangeTransaction}
                          className="item__value"
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="dateExpirationCompromis"
                        className="container__item"
                      >
                        <Form.Label className="item__label">
                          {t('pages.boat.edit.transaction.expirationDate')}
                        </Form.Label>
                        <Form.Control
                          type="date"
                          size="sm"
                          name="compromise_expiry_date"
                          value={transactionUpdate?.compromise_expiry_date}
                          onChange={handleChangeTransaction}
                          className="item__value"
                        />
                      </Form.Group>
                    </div>
                    <hr />
                    {transactionUpdate?.id && (
                      <Button
                        size="sm"
                        variant="info"
                        as={Link}
                        to={`/transactions/${transactionUpdate.id}`}
                      >
                        <FiExternalLink />{' '}
                        {t('pages.boat.edit.transaction.link')}
                      </Button>
                    )}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )}
            {(tracking.length > 0 || auth.includes(featureBoatInfosSupp)) && (
              <Accordion.Item eventKey="13">
                <Accordion.Header as="div">
                  <section className="accordion__header">
                    <span className="accordion__title">
                      {t('pages.boat.edit.history.title')}
                    </span>
                    <hr />
                  </section>
                </Accordion.Header>
                <Accordion.Body>
                  <Row className="mb-3">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>{t('pages.boat.edit.history.date')}</th>
                          <th>{t('pages.boat.edit.history.eventType')}</th>
                          <th>{t('pages.boat.edit.history.description')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tracking.map((element, index) => {
                          const date = new Date(element.date);
                          return (
                            <tr key={index}>
                              <td>
                                {new Intl.DateTimeFormat('fr-FR', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: false,
                                }).format(date)}
                              </td>
                              <td>{element.id_events}</td>
                              <td>{element.description}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
          {user && boat && boatUpdate && (
            <div className="buttons__container">
              <ToastContainer
                position="bottom-end"
                className="container__toast"
              >
                <Toast
                  onClose={() => setShow(false)}
                  show={show}
                  delay={3000}
                  bg={bgToast}
                  autohide
                >
                  <Toast.Body>
                    <span className="me-auto">{textToast}</span>
                  </Toast.Body>
                </Toast>
              </ToastContainer>
              <Button
                variant="success"
                type="submit"
                form="form"
                disabled={
                  (JSON.stringify(boat) === JSON.stringify(boatUpdate) &&
                    compareArrays(enginesDefault, enginesUpdate) &&
                    addEngine.length === 0 &&
                    deleteEngines.length === 0 &&
                    JSON.stringify(maneuverOptions) ===
                      JSON.stringify(maneuverOptionsUpdate) &&
                    compareArrays(batteries, batteriesUpdate) &&
                    addBattery.length === 0 &&
                    deleteBatteries.length === 0 &&
                    compareArrays(generators, generatorsUpdate) &&
                    addGenerator.length === 0 &&
                    deleteGenerators.length === 0 &&
                    JSON.stringify(solarPanel) ===
                      JSON.stringify(solarPanelUpdate) &&
                    JSON.stringify(windturbine) ===
                      JSON.stringify(windturbineUpdate) &&
                    JSON.stringify(electronic) ===
                      JSON.stringify(electronicUpdate) &&
                    compareArrays(tanksDefault, tanksUpdate) &&
                    addTanks.length === 0 &&
                    deleteTanks.length === 0 &&
                    JSON.stringify(comfort) === JSON.stringify(comfortUpdate) &&
                    compareArrays(cabinsDefault, cabinsUpdate) &&
                    addCabins.length === 0 &&
                    deleteCabins.length === 0 &&
                    compareArrays(kitchensDefault, kitchensUpdate) &&
                    addKitchens.length === 0 &&
                    deleteKitchens.length === 0 &&
                    compareArrays(bathroomDefault, bathroomUpdate) &&
                    addBathrooms.length === 0 &&
                    deleteBathrooms.length === 0 &&
                    JSON.stringify(cover) === JSON.stringify(coverUpdate) &&
                    compareArrays(sailsDefault, sailsUpdate) &&
                    addSails.length === 0 &&
                    deleteSails.length === 0 &&
                    JSON.stringify(rigging) === JSON.stringify(riggingUpdate) &&
                    JSON.stringify(fittings) ===
                      JSON.stringify(fittingsUpdate) &&
                    JSON.stringify(equipments) ===
                      JSON.stringify(equipmentsUpdate) &&
                    JSON.stringify(securityDefault) ===
                      JSON.stringify(securityUpdate) &&
                    compareArrays(
                      securityCertifDefault,
                      securityCertifUpdate,
                    ) &&
                    JSON.stringify(ads) === JSON.stringify(adsUpdate) &&
                    addCertif.length === 0 &&
                    delCertif.length === 0 &&
                    addingOwner.length === 0 &&
                    addingLead.length === 0 &&
                    deletedPeople.length === 0 &&
                    compareArrays(peopleDefault, people) &&
                    JSON.stringify(transaction) ===
                      JSON.stringify(transactionUpdate) &&
                    (!transaction ? buyerList.length === 0 : true)) ||
                  saving
                }
                className="container__button"
              >
                {saving ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <IoMdCheckmarkCircleOutline />
                )}
              </Button>
              <Button
                variant="danger"
                type="reset"
                form="form"
                disabled={
                  (JSON.stringify(boat) === JSON.stringify(boatUpdate) &&
                    compareArrays(enginesDefault, enginesUpdate) &&
                    addEngine.length === 0 &&
                    deleteEngines.length === 0 &&
                    JSON.stringify(maneuverOptions) ===
                      JSON.stringify(maneuverOptionsUpdate) &&
                    compareArrays(batteries, batteriesUpdate) &&
                    addBattery.length === 0 &&
                    deleteBatteries.length === 0 &&
                    compareArrays(generators, generatorsUpdate) &&
                    addGenerator.length === 0 &&
                    deleteGenerators.length === 0 &&
                    JSON.stringify(solarPanel) ===
                      JSON.stringify(solarPanelUpdate) &&
                    JSON.stringify(windturbine) ===
                      JSON.stringify(windturbineUpdate) &&
                    JSON.stringify(electronic) ===
                      JSON.stringify(electronicUpdate) &&
                    compareArrays(tanksDefault, tanksUpdate) &&
                    addTanks.length === 0 &&
                    deleteTanks.length === 0 &&
                    JSON.stringify(comfort) === JSON.stringify(comfortUpdate) &&
                    compareArrays(cabinsDefault, cabinsUpdate) &&
                    addCabins.length === 0 &&
                    deleteCabins.length === 0 &&
                    compareArrays(kitchensDefault, kitchensUpdate) &&
                    addKitchens.length === 0 &&
                    deleteKitchens.length === 0 &&
                    compareArrays(bathroomDefault, bathroomUpdate) &&
                    addBathrooms.length === 0 &&
                    deleteBathrooms.length === 0 &&
                    JSON.stringify(cover) === JSON.stringify(coverUpdate) &&
                    compareArrays(sailsDefault, sailsUpdate) &&
                    addSails.length === 0 &&
                    deleteSails.length === 0 &&
                    JSON.stringify(rigging) === JSON.stringify(riggingUpdate) &&
                    JSON.stringify(fittings) ===
                      JSON.stringify(fittingsUpdate) &&
                    JSON.stringify(equipments) ===
                      JSON.stringify(equipmentsUpdate) &&
                    JSON.stringify(securityDefault) ===
                      JSON.stringify(securityUpdate) &&
                    compareArrays(
                      securityCertifDefault,
                      securityCertifUpdate,
                    ) &&
                    JSON.stringify(ads) === JSON.stringify(adsUpdate) &&
                    addCertif.length === 0 &&
                    delCertif.length === 0 &&
                    addingOwner.length === 0 &&
                    addingLead.length === 0 &&
                    deletedPeople.length === 0 &&
                    compareArrays(peopleDefault, people) &&
                    JSON.stringify(transaction) ===
                      JSON.stringify(transactionUpdate) &&
                    (!transaction ? buyerList.length === 0 : true)) ||
                  saving
                }
                className="container__button"
              >
                {saving ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <IoMdCloseCircleOutline />
                )}
              </Button>
            </div>
          )}
        </React.Fragment>
      ) : (
        <span>Loading...</span>
      )}
    </div>
  );
}
