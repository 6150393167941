// import libraries
import React, { useEffect, useState } from 'react';
import { Badge, Image, Table } from 'react-bootstrap';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { FaUserEdit } from 'react-icons/fa';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import { LuNetwork } from 'react-icons/lu';
import { useTranslation } from 'react-i18next';
// import components
import SearchBar from '../../components/searchBar';
import ButtonUserModalAdmin from '../../components/buttonUserModalAdmin';
import ButtonModalAdmin from '../../components/buttonModalAdmin';
// import utils
import { getData } from '../../utils/api';
import { API_ROUTES, LOCAL_URL } from '../../utils/constants';
// import assets
import defaultUser from '../../assets/images/defaultUser.png';
// import css
import '../../assets/styles/admin.scss';

/*
Administration page
*/

export default function Administration() {
  const [searchedUser, setSearchedUser] = useState('');
  const [searchedNetwork, setSearchedNetwork] = useState('');
  // features
  const featureAdminDashboard = 'admin_dashboard';
  const featureAdminCreateUser = 'admin_create_user';
  const featureAdminCreateAgency = 'admin_create_agency';
  const featureAdminCreateNetwork = 'admin_create_network';
  const featureAdminUpdateUser = 'admin_update_user';
  const featureAdminUpdateAgency = 'admin_update_agency';
  const featureAdminUpdateNetwork = 'admin_update_network';
  const [auth, setAuth] = useState([]);
  const [users, setUsers] = useState([]);
  const [updateData, setUpdateData] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [features, setFeatures] = useState([]);
  const [title, setTitle] = useState([]);
  const [roles, setRoles] = useState([]);
  const [rolesByFeatures, setRolesByFeatures] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchAuthoriz = async () => {
      try {
        const [
          responseAuth,
          responseUsers,
          responseNetworks,
          responseAgencies,
          responseFeatures,
          responseRoles,
          responseRolesFeatures,
          responseTitle,
        ] = await Promise.all([
          getData(API_ROUTES.USERS.GET.AUTHORIZATION),
          getData(API_ROUTES.USERS.GET.ADMIN),
          getData(API_ROUTES.USERS.GET.NETWORKS),
          getData(API_ROUTES.USERS.GET.AGENCIES),
          getData(API_ROUTES.USERS.GET.FEATURES),
          getData(API_ROUTES.USERS.GET.ROLES),
          getData(API_ROUTES.USERS.GET.ROLES_FEATURES),
          getData(API_ROUTES.PEOPLE.GET.TITLE),
        ]);

        setAuth(responseAuth.data);
        setUsers(
          responseUsers.data.sort((a, b) =>
            a.username.localeCompare(b.username),
          ),
        );
        setNetworks(responseNetworks.data);
        setAgencies(responseAgencies.data);
        setFeatures(responseFeatures.data);
        setRoles(responseRoles.data);
        setRolesByFeatures(responseRolesFeatures.data);
        setTitle(responseTitle.data);
      } catch (error) {
        console.log('error on fetch auth ', error);
      }
    };
    fetchAuthoriz();
  }, [updateData]);

  return (
    <div className="admin">
      <div className="admin__utils">
        <SearchBar
          placeholder={t('pages.admin.searchUser')}
          value={searchedUser}
          setValue={setSearchedUser}
        />
        <SearchBar
          placeholder={t('pages.admin.searchNetwork')}
          value={searchedNetwork}
          setValue={setSearchedNetwork}
        />
        {auth.includes(featureAdminCreateUser) && (
          <ButtonUserModalAdmin
            iconButton={<IoMdAddCircleOutline />}
            nameButton={t('pages.admin.addUser')}
            className="admin__utils__buttons"
            updateData={updateData}
            setUpdateData={setUpdateData}
            agencies={agencies}
            networks={networks}
            roles={roles}
            features={features}
            rolesByFeatures={rolesByFeatures}
            title={title}
            auth={auth}
          />
        )}
        {auth.includes(featureAdminCreateAgency) && (
          <ButtonModalAdmin
            iconButton={<IoMdAddCircleOutline />}
            nameButton={t('pages.admin.addAgency')}
            type="agency"
            className="admin__utils__buttons"
            networks={networks}
          />
        )}
        {auth.includes(featureAdminCreateNetwork) && (
          <ButtonModalAdmin
            iconButton={<IoMdAddCircleOutline />}
            nameButton={t('pages.admin.addNetwork')}
            type="network"
            className="admin__utils__buttons"
            networks={networks}
          />
        )}
      </div>
      {auth.includes(featureAdminDashboard) && (
        <Table striped hover className="admin__table">
          <thead>
            <tr>
              <th>{t('pages.admin.table.name')}</th>
              <th>{t('pages.admin.table.network')}</th>
              <th>{t('pages.admin.table.agency')}</th>
              <th>{t('pages.admin.table.role')}</th>
              <th>{t('pages.admin.table.actions')}</th>
            </tr>
          </thead>
          <tbody>
            {users
              .filter((item) => {
                return searchedNetwork.toLocaleLowerCase() === ''
                  ? item
                  : item.network.toLocaleLowerCase().includes(searchedNetwork);
              })
              .filter((item) => {
                return searchedUser.toLocaleLowerCase() === ''
                  ? item
                  : item.username.toLocaleLowerCase().includes(searchedUser);
              })
              .map((item) => {
                return (
                  <tr key={item.id}>
                    <td className="admin__table__cell admin__table__cell__name">
                      <Image
                        src={
                          item.imageURL
                            ? `${LOCAL_URL}/files/profile/${item.imageURL}`
                            : defaultUser
                        }
                        roundedCircle
                        className="admin__table__cell__image"
                      />
                      <div className="admin__table__cell__nameContainer">
                        <span className="admin__table__cell__name__username">
                          {item.username}
                        </span>
                        <span className="admin__table__cell__name__email">
                          {item.email}
                        </span>
                      </div>
                    </td>
                    <td className="admin__table__cell">{item.network}</td>
                    <td className="admin__table__cell">{item.agency}</td>
                    <td className="admin__table__cell">
                      <Badge
                        bg={
                          item.role === 'Commercial' ? 'secondary' : 'primary'
                        }
                      >
                        {t(roles.find((role) => role.value === item.role)?.tag)}
                      </Badge>
                    </td>
                    <td className="admin__table__cell admin__table__cell__actions">
                      {auth.includes(featureAdminUpdateUser) && (
                        <ButtonUserModalAdmin
                          iconButton={<FaUserEdit />}
                          data={item}
                          size="sm"
                          updateData={updateData}
                          setUpdateData={setUpdateData}
                          agencies={agencies}
                          networks={networks}
                          roles={roles}
                          features={features}
                          rolesByFeatures={rolesByFeatures}
                          title={title}
                          tooltip={t('label.editUser')}
                          auth={auth}
                        />
                      )}
                      {auth.includes(featureAdminUpdateAgency) && (
                        <ButtonModalAdmin
                          iconButton={<HiOutlineOfficeBuilding />}
                          type="agency"
                          data={item}
                          size="sm"
                          networks={networks}
                          tooltip={t('label.editAgency')}
                        />
                      )}
                      {auth.includes(featureAdminUpdateNetwork) && (
                        <ButtonModalAdmin
                          iconButton={<LuNetwork />}
                          type="network"
                          data={item}
                          size="sm"
                          networks={networks}
                          tooltip={t('label.editNetwork')}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      )}
    </div>
  );
}
