// import libraries
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';
import { FaRegTrashAlt } from 'react-icons/fa';

export default function BoatTable({
  boats,
  addingBoats,
  onDeleteBoat,
  status,
}) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>ID</th>
          <th>Brand</th>
          <th>Model</th>
          <th>Status</th>
          <th>Year</th>
          <th>Typology</th>
          <th>Price</th>
          {status === 1 && <th>Shares</th>}
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {boats.length > 0 &&
          boats.map((boat, index) => (
            <tr key={index}>
              <td>
                <Link
                  to={`/boats/${boat.id}`}
                  className="text-decoration-none text-black"
                >
                  {boat.id}
                </Link>
              </td>
              <td>
                <Link
                  to={`/boats/${boat.id}`}
                  className="text-decoration-none text-black"
                >
                  {boat.brand}
                </Link>
              </td>
              <td>
                <Link
                  to={`/boats/${boat.id}`}
                  className="text-decoration-none text-black"
                >
                  {boat.model}
                </Link>
              </td>
              <td>
                <Link
                  to={`/boats/${boat.id}`}
                  className="text-decoration-none text-black"
                >
                  {boat.status}
                </Link>
              </td>
              <td>
                <Link
                  to={`/boats/${boat.id}`}
                  className="text-decoration-none text-black"
                >
                  {boat.year}
                </Link>
              </td>
              <td>
                <Link
                  to={`/boats/${boat.id}`}
                  className="text-decoration-none text-black"
                >
                  {boat.typology}
                </Link>
              </td>
              <td>
                {new Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR',
                  maximumFractionDigits: 0,
                }).format(boat.price)}
              </td>
              {status === 1 && <td>{boat.shares} %</td>}
              <td>
                <Button
                  size="sm"
                  variant="danger"
                  onClick={() => onDeleteBoat(boat.id)}
                >
                  <FaRegTrashAlt />
                </Button>
              </td>
            </tr>
          ))}
        {addingBoats.map((element, index) => (
          <tr key={index}>
            <td>
              <Link
                to={`/boats/${element.id}`}
                className="text-decoration-none text-black"
              >
                {element.id}
              </Link>
            </td>
            <td>
              <Link
                to={`/boats/${element.id}`}
                className="text-decoration-none text-black"
              >
                {element.brand}
              </Link>
            </td>
            <td>
              <Link
                to={`/boats/${element.id}`}
                className="text-decoration-none text-black"
              >
                {element.model}
              </Link>
            </td>
            <td>
              <Link
                to={`/boats/${element.id}`}
                className="text-decoration-none text-black"
              >
                {element.status}
              </Link>
            </td>
            <td>
              <Link
                to={`/boats/${element.id}`}
                className="text-decoration-none text-black"
              >
                {element.year}
              </Link>
            </td>
            <td>
              <Link
                to={`/boats/${element.id}`}
                className="text-decoration-none text-black"
              >
                {element.typology}
              </Link>
            </td>
            <td>
              {new Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                maximumFractionDigits: 0,
              }).format(element.price)}
            </td>
            {status === 1 && <td>{element.shares} %</td>}
            <td>
              <Button
                size="sm"
                variant="danger"
                onClick={() => onDeleteBoat(element.id)}
              >
                <FaRegTrashAlt />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
